const tables = [
    "bookvenues",
    "email_jobs",
    "email_restrictions",
    "form_questions",
    "managers",
    "tasks",
    "users",
];

module.exports = Object.fromEntries(tables.map(table => {
    return [table, require(`./${table}`)]
}));
