import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import States from "../../Dropdown/states"
import Countries from "../../Dropdown/countries"
import {CFormInput, CFormSelect, CFormTextarea} from "@coreui/react";
import React from 'react';
import {
    TYPE_MULTIPLE_LINE_TEXT,
    TYPE_NUMBER,
    TYPE_SELECTABLE_OPTIONS,
    TYPE_SINGLE_LINE_TEXT
} from "constants/form_questions";
import MultiSelectCheckbox from "../../Business/components/MultiSelectCheckbox";

const CheckoutFormBase = ({ checkoutData, setCheckoutData, paymentSection, handleSubmit, submitDisabled }) => {
    const singleVenueDetails = useSelector(state => state.Apis.singleVenueDetail);
    const userDetail = useSelector(state => state.Apis.userDetail);

    const [separatedName, setSeparatedName] = useState([]);
    const [formQuestions, setFormQuestions] = useState([{
        "id": 0,
        "type": TYPE_MULTIPLE_LINE_TEXT,
        "prompt": "<p>Tell us about your event, and what you need!</p>",
        "optional": 0,
        "order": 1,
        "deleted": 0,
    }]);

    useEffect(() => {
        if (Object.keys(userDetail).length) {
            const separateName = userDetail.name.split(", ").length > 1 ? userDetail.name.split(", ").reverse() : userDetail.name.split(" ");
            setCheckoutData({
                ...checkoutData,
                email: userDetail.email,
                firstName: separateName[0],
                lastName: separateName.slice(-1)[0],
                phoneNo: userDetail.phone,
            });
            setSeparatedName(separateName);
        }
    }, [userDetail]);

    const onChange = (e) => {
        setCheckoutData({ ...checkoutData, [e.target.name]: e.target.value })
    }

    const onChangeForm = (e) => {
        const question = formQuestions.find(q => q.id === parseInt(e.target.name));
        setCheckoutData({
            ...checkoutData,
            formResponses: {
                ...checkoutData.formResponses,
                [question.id]: {
                    prompt: question.prompt,
                    type: question.type,
                    order: question.order,
                    response: e.target.value.toString(),
                },
            },
        });
    }

    const onChangeFormMultiselect = (selectedOptions, actionMeta) => {
        const question = formQuestions.find(q => q.id === parseInt(actionMeta.name));
        setCheckoutData({
            ...checkoutData,
            formResponses: {
                ...checkoutData.formResponses,
                [question.id]: {
                    prompt: question.prompt,
                    type: question.type,
                    order: question.order,
                    response: selectedOptions.map(option => option.value).join('\n'),
                },
            },
        });
    }

    useEffect(() => {
        var message_prompt = document.getElementById('message_prompt');
        if (message_prompt){
            message_prompt.innerHTML = singleVenueDetails.message_prompt ?
                singleVenueDetails.message_prompt : "<p>Tell us about your event, and what you need!</p>";
        }
        const questions = singleVenueDetails?.form?.form_questions;
        if (questions) {
            setFormQuestions(questions);
        }
    }, [singleVenueDetails]);

    return (
        <form className="checkoutForm" onSubmit={handleSubmit}>
            <div className="checkout-page-layout card border-0 box_shadow rounded-4 mb-4 mt-0">
                <div className="card_body p-4">
                    <div className="basic_info">
                        <h4 className="mb-3">Contact Info</h4>
                        <div className="flex-row">
                            <div className="w47">
                                <p>
                                    <label className="pb-2">First Name </label><br />
                                    <CFormInput required name="firstName" defaultValue={separatedName[0] != undefined ? separatedName[0] : ""}onChange={(e) => onChange(e)} className="w100"></CFormInput>
                                </p>
                            </div>
                            <div className="w6"></div>
                            <div className="w47">
                                <p>
                                    <label className="pb-2">Last Name </label><br />
                                    <CFormInput required name="lastName" defaultValue={separatedName.slice(-1)[0] != undefined ? separatedName.slice(-1)[0] : ""} onChange={(e) => onChange(e)} className="w100"></CFormInput>
                                </p>
                            </div>
                        </div>
                        <div className="flex-row">
                            <div className="w47">
                                <p className="mb-0">
                                    <label className="pb-2">Email </label><br />
                                    <CFormInput required name="email" defaultValue={userDetail.email != undefined ? userDetail.email : ""} disabled readOnly type="email" className="w100"></CFormInput>

                                </p>
                            </div>
                            <div className="w6"></div>
                            <div className="w47">
                                <p className="mb-0">
                                    <label className="pb-2">Phone Number </label><br />
                                    <CFormInput required name="phoneNo" defaultValue={userDetail.phone != undefined ? userDetail.phone : ""}  onChange={(e) => onChange(e)} className="w100"></CFormInput>
                                </p>
                            </div>
                        </div>
                    </div>
                    {singleVenueDetails.collect_address ?
                        <div className="address pt-3">
                            <p>
                                <label className="pb-2">Address </label><br />
                                <CFormInput required name="streetAddress" onChange={(e) => onChange(e)} placeholder=" Street address" className="w100 mb-2"></CFormInput>
                                <CFormInput name="unitNo" onChange={(e) => onChange(e)} placeholder=" Apt, suite, unit, floor, etc." className="w100"></CFormInput>
                            </p>
                            <div className="flex-row">
                                <div>
                                    <p>
                                        <label className="pb-2">City </label><br />
                                        <CFormInput required name="city" onChange={(e) => onChange(e)} className="w100"></CFormInput>
                                    </p>
                                </div>
                                <div className="w6"></div>
                                <div className="w47">
                                    <p>
                                        <label className="pb-2">State </label><br />
                                        <CFormSelect required name="state" onChange={(e) => onChange(e)}>
                                            <States/>
                                        </CFormSelect>
                                    </p>
                                </div>
                                <div className="w6"></div>
                                <div>
                                    <p>
                                        <label className="pb-2">Country / Region </label><br />
                                        <CFormSelect required name="country" onChange={(e) => onChange(e)}>
                                            <Countries/>
                                        </CFormSelect>

                                    </p>
                                </div>
                                <div className="w6"></div>
                                <div>
                                    <p>
                                        <label className="pb-2">Zip Code </label><br />
                                        <CFormInput required name="zipcode" onChange={(e) => onChange(e)} className="w100"></CFormInput>
                                    </p>
                                </div>
                            </div>
                        </div> :
                        null
                    }
                </div>
                {paymentSection}
                <div className="event_detail p-4 border-top">
                    {singleVenueDetails.collect_eventtitle ?
                        <div>
                            <h4 className="mb-3">Event Detail</h4>
                            <label className='pb-2'>Event Title </label><br />
                            <CFormInput required name="eventTitle" placeholder="Urban Book Club Networking Event" onChange={(e) => onChange(e)}/>
                        </div>
                        :
                        <h4>Message for Owner</h4>
                    }
                    {
                        formQuestions.map(question => (
                            <>
                                <div className="message_prompt pt-3 pb-2">
                                    <div className="d-inline-block" dangerouslySetInnerHTML={{ __html: question.prompt }}/>
                                    {
                                        question.optional ? <span>&nbsp;(optional)</span> : null
                                    }
                                </div>
                                {
                                    question.type === TYPE_SINGLE_LINE_TEXT ?
                                        <CFormInput
                                            required={!question.optional}
                                            name={question.id}
                                            onChange={onChangeForm}
                                        /> :
                                    question.type === TYPE_MULTIPLE_LINE_TEXT ?
                                        <CFormTextarea
                                            required={!question.optional}
                                            name={question.id}
                                            onChange={onChangeForm}
                                            rows={5}
                                        /> :
                                    question.type === TYPE_NUMBER ?
                                        <CFormInput
                                            type="number"
                                            min={question.number_question.min}
                                            max={question.number_question.max}
                                            required={!question.optional}
                                            name={question.id}
                                            onChange={onChangeForm}
                                            className="w-50"
                                        /> :
                                    question.type === TYPE_SELECTABLE_OPTIONS ? (
                                        question.select_question.multi_select ?
                                            <MultiSelectCheckbox
                                                name={question.id}
                                                onChange={onChangeFormMultiselect}
                                                options={question.select_question.options
                                                    .split('\n')
                                                    .map(option => ({
                                                        label: option,
                                                        value: option,
                                                    }))}
                                            /> :
                                            <CFormSelect
                                                name={question.id}
                                                onChange={onChangeForm}
                                            >
                                                {
                                                    question.optional ? <option value="">No Response</option> : null
                                                }
                                                {
                                                    question.select_question.options
                                                        .split('\n')
                                                        .map(option => <option value={option}>{option}</option>)
                                                }
                                            </CFormSelect>
                                    ) : null
                                }
                            </>
                        ))
                    }
                </div>
                <div className="tow_line p-4 border-top">
                    <label className="form-check-label checkbox-label" for="agree">
                        <input className="form-check-input" type="checkbox" id="agree" required />
                        &nbsp;By checking this box, I acknowledge that I have read and agree to DoubleSpot's <a href="/terms"className="text-black" target="_blank"><u>Terms</u></a> and <a href="/privacy" className="text-black" target="_blank"><u>Privacy Policy</u></a>.
                    </label>
                </div>
            </div>
            <div className="form-group text-end">
                <button className="designBtn next_btn py-2 ms-auto" disabled={submitDisabled}>Submit</button>
            </div>
        </form>
    )
};

export default CheckoutFormBase;
