import {
    CButton,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea, CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import TimePicker from "../../../components/TimePicker";
import {setAlert, updateEventDetail} from "../../../../redux/action/action";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import {hasConflict} from "../../../../Utils/DateTimeUtils";

const EditEventsModal = ({ visible, onClose, editEventsFormData, setEditEventsFormData, dispatch, bookings, eventTypes, eventTags, setEventTags, venues, selectedBookings }) => {
    const handleUpdateEventDetail = (event) => {
        event.preventDefault();
        let conflictCount = 0;
        for (const booking of selectedBookings) {
            const updatedEventTime = {
                id: booking?.id,
                venueId: editEventsFormData?.venueId || booking?.venueId,
                date: editEventsFormData?.date || booking?.date,
                end_date: editEventsFormData?.end_date || booking?.end_date,
                start_time: editEventsFormData?.start_time || booking?.start_time,
                end_time: editEventsFormData?.end_time || booking?.end_time,
            };
            if (hasConflict(updatedEventTime, bookings)) ++conflictCount;
        }
        if (selectedBookings.length > 1 && prompt(`You are about to update details of ${selectedBookings.length} events. If you are sure to continue, please type the number of events to confirm.`) !== selectedBookings.length.toString()) {
            dispatch(setAlert("Please acknowledge the number of events before updating them.", "error"));
            return;
        }
        if (conflictCount) {
            if (prompt(`${conflictCount} event${conflictCount > 1 ? "s" : ""} will have conflicts with existing ones. If you are sure to continue, please type "DOUBLEBOOK" to confirm.`) !== "DOUBLEBOOK") {
                dispatch(setAlert("Please acknowledge the time conflict before updating the events.", "error"));
                return;
            }
        } else if (selectedBookings.length > 1 && editEventsFormData.venueId && editEventsFormData.date && editEventsFormData.start_time && editEventsFormData.end_date && editEventsFormData.end_time) {
            if (prompt("All of the events will conflict with each other after the change. If you are sure to continue, please type \"DOUBLEBOOK\" to confirm.") !== "DOUBLEBOOK") {
                dispatch(setAlert("Please acknowledge the time conflict before updating the events.", "error"));
                return;
            }
        }
        dispatch(updateEventDetail({
            ...editEventsFormData,
            ids: selectedBookings.map(booking => booking.id),
            sendEmail: false,
            tags: editEventsFormData.tags ? editEventsFormData.tags.map(tag => ({
                id: tag.value,
                name: tag.label,
            })) : undefined,
        }));
    }

    const updateEditEventForm = (event) => {
        setEditEventsFormData({...editEventsFormData, [event.target.name]: event.target.value && event.target.value !== "0" ? event.target.value : undefined});
    }

    const updateEditEventFormMultiselect = (selectedOptions, actionMeta) => {
        setEditEventsFormData({...editEventsFormData, [actionMeta.name]: selectedOptions.length ? selectedOptions : undefined});
    }

    const updateEditEventFormTimePicker = (selectedOption, actionMeta) => {
        setEditEventsFormData({...editEventsFormData, [actionMeta.name]: selectedOption?.value});
    }

    const onCreateOption = (name) => {
        setEventTags([...eventTags, {
            id: name,
            name,
        }]);

        const newTag = {
            label: name,
            value: name,
        }
        setEditEventsFormData({
            ...editEventsFormData,
            tags: editEventsFormData.tags ? [...editEventsFormData.tags, newTag] : [newTag]
        });
    }

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <div>
                    <CModalTitle>Edit Events ({selectedBookings.length} selected)</CModalTitle>
                    <p className="modal-subtitle mt-1 mb-0">Fields left blank will be left unchanged.</p>
                </div>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <CForm onSubmit={handleUpdateEventDetail}>
                    <CFormLabel htmlFor="title">Title</CFormLabel>
                    <div className="pb-3">
                        <CFormInput type="text" name="title" onChange={updateEditEventForm}
                                    value={editEventsFormData.title}/>
                    </div>
                    <div className="pb-3">
                        <CFormLabel htmlFor="eventtype_id">Event Type</CFormLabel>
                        <CFormSelect name="eventtype_id" onChange={updateEditEventForm}
                                     value={editEventsFormData.eventtype_id}>
                            {
                                eventTypes.map(eventType => <option value={eventType.id}>{eventType.name}</option>)
                            }
                        </CFormSelect>
                    </div>
                    <div class="pb-3">
                        <CFormLabel htmlFor="tags">Tags</CFormLabel>
                        <MultiSelectCheckbox
                            name="tags"
                            onChange={updateEditEventFormMultiselect}
                            options={eventTags.map(
                                tag => ({
                                    label: tag.name,
                                    value: tag.id,
                                })
                            )}
                            value={editEventsFormData?.tags}
                            creatable
                            onCreateOption={onCreateOption}
                        />
                    </div>
                    <div className="pb-3">
                        <CFormLabel htmlFor="venueId">Venue</CFormLabel>
                        <CFormSelect name="venueId" onChange={updateEditEventForm} value={editEventsFormData.venueId}>
                            {
                                venues.map(listing => <option
                                    value={listing.id}>{listing.name}</option>)
                            }
                        </CFormSelect>
                    </div>
                    <div class="flex-row pb-3">
                        <div class="col-6 pe-2">
                            <CFormLabel htmlFor="date">Start Date</CFormLabel>
                            <CFormInput type="date" name="date"
                                        onChange={updateEditEventForm}
                                        value={editEventsFormData.date}/>
                        </div>
                        <div class="col-6 ps-2">
                            <CFormLabel htmlFor="start_time">Start Time</CFormLabel>
                            <TimePicker name="start_time" onChange={updateEditEventFormTimePicker}
                                        value={editEventsFormData.start_time} placeholder="No Change" isClearable/>
                        </div>
                    </div>
                    <div class="flex-row pb-3">
                        <div class="col-6 pe-2">
                            <CFormLabel htmlFor="end_date">End Date</CFormLabel>
                            <CFormInput type="date" name="end_date"
                                        onChange={updateEditEventForm}
                                        value={editEventsFormData.end_date}/>
                        </div>
                        <div class="col-6 ps-2">
                            <CFormLabel htmlFor="end_time">End Time</CFormLabel>
                            <TimePicker name="end_time" onChange={updateEditEventFormTimePicker}
                                        value={editEventsFormData.end_time} placeholder="No Change" isClearable/>
                        </div>
                    </div>
                    <CFormLabel htmlFor="attendees">Attendees</CFormLabel>
                    <div className="flex-row align-items-center pb-3">
                        <div className="pe-2">
                            <CFormInput type="number" min="0" name="attendees"
                                        onChange={updateEditEventForm}
                                        value={editEventsFormData.attendees} style={{width: "100px"}}/>
                        </div>
                        <span>people</span>
                    </div>
                    <CFormLabel>Customer Name</CFormLabel>
                    <div class="flex-row pb-3">
                        <div class="col-6 pe-2">
                            <CFormLabel htmlFor="customer_first_name">First Name</CFormLabel>
                            <CFormInput type="text" name="customer_first_name"
                                        onChange={updateEditEventForm}
                                        value={editEventsFormData.customer_first_name}/>
                        </div>
                        <div class="col-6 ps-2">
                            <CFormLabel htmlFor="customer_last_name">Last Name</CFormLabel>
                            <CFormInput type="text" name="customer_last_name"
                                        onChange={updateEditEventForm}
                                        value={editEventsFormData.customer_last_name}/>
                        </div>
                    </div>
                    <CFormLabel htmlFor="customer_number">Customer Phone</CFormLabel>
                    <div className="pb-3">
                        <CFormInput type="text" name="customer_number" onChange={updateEditEventForm}
                                    value={editEventsFormData.customer_number}/>
                    </div>
                    <CFormLabel htmlFor="customer_email">Customer Email</CFormLabel>
                    <div className="pb-3">
                        <CFormInput type="text" name="customer_email" onChange={updateEditEventForm}
                                    value={editEventsFormData.customer_email}/>
                    </div>
                    <div className="pb-3">
                        <CFormLabel htmlFor="finalPrice">Revenue</CFormLabel>
                        <div className="flex-row align-items-center">
                            <span>$</span>
                            <div className="px-2">
                                <CFormInput type="number" min="0" name="booked_price"
                                            onChange={updateEditEventForm}
                                            defaultValue={editEventsFormData.booked_price}
                                            style={{width: "100px"}}/>
                            </div>
                        </div>
                    </div>
                    <div>
                        <CFormLabel htmlFor="description">Description</CFormLabel>
                        <div className="pb-4">
                            <CFormTextarea name="description" onChange={updateEditEventForm}
                                           value={editEventsFormData.description}/>
                        </div>
                    </div>
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    );
};

export default EditEventsModal;
