import { useEffect } from 'react';
import {useStripe} from '@stripe/react-stripe-js';
import { useDispatch } from "react-redux";
import {bookingPaymentProcessed, cancelBooking, setAlert} from "../../redux/action/action";
import { useNavigate ,useSearchParams } from "react-router-dom";

function PPRouting() {
    const stripe = useStripe();
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const dispatch = useDispatch();

    useEffect(() => {
        const route = async () => {
            const clientSecret = searchParams.get("payment_intent_client_secret")
            const id = localStorage.getItem('venueId');
              
            stripe
            .retrievePaymentIntent(clientSecret)
            .then(({paymentIntent}) => {
                // Inspect the PaymentIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                switch (paymentIntent.status) {
                    case 'requires_capture':
                    case 'succeeded':
                    case 'processing':
                        dispatch(bookingPaymentProcessed({id: searchParams.get("booking_id")}));
                        localStorage.removeItem("bookingData");
                        navigate('/booking');
                        break;

                    // The current known use case for cancellation is only for 3D authorization failures, but it covers all cases nonetheless.
                    case 'requires_payment_method':
                    default:
                        dispatch(setAlert("The transaction did not go through and was cancelled.", 'error'));
                        dispatch(cancelBooking({id: searchParams.get("booking_id")}));
                        navigate(`/checkout/${id}?payment_intent=${paymentIntent.id}&client_secret=${clientSecret}`);
                        break;
                }
            })
        }

        if (!stripe) {
            return;
        } 

        route();

    }, [stripe]);   
}

export default PPRouting





