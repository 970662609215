import {USE_LIVE} from "endpoint"

export const DEFAULT_URL = USE_LIVE ? "https://doublespot.com/web/" : "http://localhost:3030/web/"
export const SITE_URL = USE_LIVE ? "https://doublespot.com/" : "http://localhost:3030/"
export const REDIRECT_URI = USE_LIVE ? "https://doublespot.com/account/login" : "http://localhost:3000/account/login"

export const GOOGLE_PLACES_API_KEY = 'AIzaSyCYDFyBePkwdOkXW-mXzNLnR_okUyO4rXg'
export const GET_USER_FETCH = 'GET_USER_FETCH'
export const GET_USERS_SUCCESS = 'GET_USER_SUCCESS'
export const SHOW_ALERT_SUCCESS = 'SHOW_ALERT_SUCCESS'
export const SHOW_ALERT = 'SHOW_ALERT'
export const USER_SIGNUP_SUCCESS = 'USER_SIGNUP_SUCCESS'
export const USER_SIGNUP_API_SUCCESS = 'USER_SIGNUP_API_SUCCESS'
export const USER_SIGNUP_FAILURE = 'USER_SIGNUP_FAILURE'
export const USER_LOGIN  = 'USER_LOGIN'
export const USER_LOGIN_API_SUCCESS = 'USER_LOGIN_API_SUCCESS' 
export const USER_LOGIN_API_FAIL = 'USER_LOGIN_API_FAIL'
export const LIST_VENUES = 'LIST_VENUES'
export const GET_VENUES_LIST_SUCCESS = 'GET_VENUES_LIST_SUCCESS'
export const GET_VENUES_LIST_ERROR = 'GET_VENUES_LIST_ERROR'

export const SEND_VERIFICATION_EMAIL = 'SEND_VERIFICATION_EMAIL'
export const ACCOUNT_VERIFY = 'ACCOUNT_VERIFY'
export const VERIFY_SUCCESS = 'VERIFY_SUCCESS'
export const VERIFY_FAIL = 'VERIFY_FAIL'

export const SINGLE_VENUE_DETAIL = 'SINGLE_VENUE_DETAIL'
export const SINGLE_VENUE_SUCCESS = 'SINGLE_VENUE_SUCCESS'
export const SINGLE_VENUE_ERROR = 'SINGLE_VENUE_ERROR'

export const GET_PACKAGES_SUCCESS = 'GET_PACKAGES_SUCCESS'
export const API_PACKAGES_SUCCESS = 'API_PACKAGES_SUCCESS'
export const API_PACKAGES_FAIL = 'API_PACKAGES_FAIL'

export const LOGOUT_SUCCESS="LOGOUT_SUCCESS"
export const SUCCESS_LOGOUT="SUCCESS_LOGOUT"

export const PROVIDER_PROFILE = "PROVIDER_PROFILE"
export const GET_PROVIDER_PROFILE_SUCCESS = "GET_PROVIDER_PROFILE_SUCCESS"
export const GET_PROVIDER_PROFILE_FAIL = "GET_PROVIDER_PROFILE_FAIL"

export const PUBLIC_PROVIDER_PROFILE = "PROVIDER_PROFILE"

export const USER_PROFILE = "USER_PROFILE"
export const GET_USER_PROFILE_SUCCESS = "GET_USER_PROFILE_SUCCESS"
export const GET_USER_PROFILE_FAIL = "GET_USER_PROFILE_FAIL"

export const UPDATE_USER_DETAIL = "UPDATE_USER_DETAIL"
export const UPDATE_DETAIL_API_SUCCESS = "UPDATE_DETAIL_API_SUCCESS"
export const UPDATE_DETAIL_API_FAIL = "UPDATE_DETAIL_API_FAIL"

export const UPDATE_BUSINESS_PROFILE = "UPDATE_BUSINESS_PROFILE"
export const UPDATE_BUSINESS_PROFILE_SUCCESS = "UPDATE_BUSINESS_PROFILE_SUCCESS"
export const UPDATE_BUSINESS_PROFILE_FAIL = "UPDATE_BUSINESS_PROFILE_FAIL"

export const BUSINESS_PLAN = "BUSINESS_PLAN"
export const GET_BUSINESS_PLAN_SUCCESS = "GET_BUSINESS_PLAN_SUCCESS"
export const GET_BUSINESS_PLAN_FAIL = "GET_BUSINESS_PLAN_FAIL"

export const MANAGERS = "MANAGERS"
export const GET_MANAGERS_SUCCESS = "GET_MANAGERS_SUCCESS"
export const GET_MANAGERS_FAIL = "GET_MANAGERS_FAIL"

export const ADD_MANAGER = "ADD_MANAGER"
export const ADD_MANAGER_SUCCESS = "ADD_MANAGER_SUCCESS"
export const ADD_MANAGER_FAIL = "ADD_MANAGER_FAIL"

export const UPDATE_MANAGER_ACCESS = "UPDATE_MANAGER_ACCESS"
export const UPDATE_MANAGER_ACCESS_SUCCESS = "UPDATE_MANAGER_ACCESS_SUCCESS"
export const UPDATE_MANAGER_ACCESS_FAIL = "UPDATE_MANAGER_ACCESS_FAIL"

export const UPDATE_MANAGER_SETTINGS = "UPDATE_MANAGER_SETTINGS"
export const UPDATE_MANAGER_SETTINGS_SUCCESS = "UPDATE_MANAGER_SETTINGS_SUCCESS"
export const UPDATE_MANAGER_SETTINGS_FAIL = "UPDATE_MANAGER_SETTINGS_FAIL"

export const UPDATE_VENUE_DETAIL = "UPDATE_VENUE_DETAIL"
export const UPDATE_VENUE_SUCCESS = "UPDATE_VENUE_SUCCESS"
export const UPDATE_VENUE_FAIL = "UPDATE_VENUE_FAIL"

export const ALL_AMENITIES = "ALL_AMENITIES"
export const ALL_AMENITIES_SUCCESS = "ALL_AMENITIES_SUCCESS"
export const ALL_AMENITIES_FAIL = "ALL_AMENITIES_FAIL"

export const BOOK_VENUE = "BOOK_VENUE"
export const BOOK_VENUE_SUCCESS = "BOOK_VENUE_SUCCESS"
export const BOOK_VENUE_FAIL = "BOOK_VENUE_FAIL"

export const EVENT_DETAIL = "EVENT_DETAIL";
export const EVENT_DETAIL_SUCCESS = "EVENT_DETAIL_SUCCESS";
export const EVENT_DETAIL_FAIL = "EVENT_DETAIL_FAIL";

export const UPDATE_EVENT_DETAIL = "UPDATE_BOOKING_DETAIL"
export const UPDATE_EVENT_SUCCESS = "UPDATE_BOOKING_SUCCESS"
export const UPDATE_EVENT_FAIL = "UPDATE_BOOKING_FAIL"

export const CANCEL_BOOKING = "CANCEL_BOOKING"
export const CANCEL_BOOKING_SUCCESS = "CANCEL_BOOKING_SUCCESS"
export const CANCEL_BOOKING_FAIL = "CANCEL_BOOKING_FAIL"

export const BOOKING_PAYMENT_PROCESSED = "BOOKING_PAYMENT_PROCESSED"
export const BOOKING_PAYMENT_PROCESSED_SUCCESS = "BOOKING_PAYMENT_PROCESSED_SUCCESS"
export const BOOKING_PAYMENT_PROCESSED_FAIL = "BOOKING_PAYMENT_PROCESSED_FAIL"

export const BIZ_DELETE_EVENT = "BIZ_DELETE_EVENT"
export const BIZ_DELETE_SUCCESS = "BIZ_DELETE_SUCCESS"
export const BIZ_DELETE_FAIL = "BIZ_DELETE_FAIL"

export const CONFIRM_EVENT = "CONFIRM_EVENT"
export const CONFIRM_EVENT_SUCCESS = "CONFIRM_EVENT_SUCCESS"
export const CONFIRM_EVENT_FAIL = "CONFIRM_EVENT_FAIL"

export const DECLINE_EVENT = "DECLINE_EVENT"
export const DECLINE_EVENT_SUCCESS = "DECLINE_EVENT_SUCCESS"
export const DECLINE_EVENT_FAIL = "DECLINE_EVENT_FAIL"

export const SEND_CONFIRMATION_EMAIL = "SEND_CONFIRMATION_EMAIL"
export const SEND_CONFIRMATION_EMAIL_SUCCESS = "SEND_CONFIRMATION_EMAIL_SUCCESS"
export const SEND_CONFIRMATION_EMAIL_FAIL = "SEND_CONFIRMATION_EMAIL_FAIL"

export const ADD_TASK = "ADD_TASK"
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS"
export const ADD_TASK_FAIL = "ADD_TASK_FAIL"

export const UPDATE_TASK = "UPDATE_TASK"
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS"
export const UPDATE_TASK_FAIL = "UPDATE_TASK_FAIL"

export const DELETE_TASK = "DELETE_TASK"
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS"
export const DELETE_TASK_FAIL = "DELETE_TASK_FAIL"

export const CALENDAR_RESET = "CALENDAR_RESET"
export const CALENDAR_RESET_SUCCESS = "CALENDAR_RESET_SUCCESS"

export const FORGOT_PWD = "FORGOT_PWD"
export const FORGOT_PWD_SUCCESS = "FORGOT_PWD_SUCCESS"
export const FORGOT_PWD_FAIL = "FORGOT_PWD_FAIL"

export const AUTH_RESET_PWD = "AUTH_RESET_PWD"
export const AUTH_RESET_SUCCESS = "AUTH_RESET_SUCCESS"
export const AUTH_RESET_FAIL = "AUTH_RESET_FAIL"

export const RESET_PWD = "RESET_PWD"
export const RESET_PWD_SUCCESS = "RESET_PWD_SUCCESS"
export const RESET_PWD_FAIL = "RESET_PWD_FAIL"

export const SET_RESET_PWD_FALSE = "SET_RESET_PWD_FALSE"
export const SET_RESET_PWD_FALSE_SUCCESS = "SET_RESET_PWD_SUCCESS"
export const SET_RESET_PWD_FALSE_FAIL = "SET_RESET_PWD_FAIL"


export const CURRENT_BOOKINGS = "CURRENT_BOOKINGS"
export const CURRENT_BOOKINGS_SUCCESS = "CURRENT_BOOKINGS_SUCCESS"
export const CURRENT_BOOKINGS_FAIL = "CURRENT_BOOKINGS_FAIL"

export const CUSTOMER_LIST = "CUSTOMER_LIST"
export const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS"
export const CUSTOMER_LIST_FAIL = "CUSTOMER_LIST_FAIL"

export const CALENDAR_DATA = "CALENDAR_DATA"
export const PUBLIC_CALENDAR_DATA = "PUBLIC_CALENDAR_DATA"
export const CALENDAR_DATA_SUCCESS = "CALENDAR_DATA_SUCCESS"
export const CALENDAR_DATA_FAIL = "CALENDAR_DATA_FAIL"

export const RESERVATION_REPORTS = "RESERVATION_REPORTS"
export const RESERVATION_REPORTS_SUCCESS = "RESERVATION_REPORTS_SUCCESS"
export const RESERVATION_REPORTS_FAIL = "RESERVATION_REPORTS_FAIL"

export const ADD_RESERVATION_REPORT = "ADD_RESERVATION_REPORT"
export const ADD_RESERVATION_REPORT_SUCCESS = "ADD_RESERVATION_REPORT_SUCCESS"
export const ADD_RESERVATION_REPORT_FAIL = "ADD_RESERVATION_REPORT_FAIL"

export const UPDATE_RESERVATION_REPORT = "UPDATE_RESERVATION_REPORT"
export const UPDATE_RESERVATION_REPORT_SUCCESS = "UPDATE_RESERVATION_REPORT_SUCCESS"
export const UPDATE_RESERVATION_REPORT_FAIL = "UPDATE_RESERVATION_REPORT_FAIL"

export const DELETE_RESERVATION_REPORT = "DELETE_RESERVATION_REPORT"
export const DELETE_RESERVATION_REPORT_SUCCESS = "DELETE_RESERVATION_REPORT_SUCCESS"
export const DELETE_RESERVATION_REPORT_FAIL = "DELETE_RESERVATION_REPORT_FAIL"

export const MEMBERSHIP_TYPES = "MEMBERSHIP_TYPES"
export const MEMBERSHIP_TYPES_SUCCESS = "MEMBERSHIP_TYPES_SUCCESS"
export const MEMBERSHIP_TYPES_FAIL = "MEMBERSHIP_TYPES_FAIL"

export const MEMBERSHIP_TYPE = "MEMBERSHIP_TYPE"
export const MEMBERSHIP_TYPE_SUCCESS = "MEMBERSHIP_TYPE_SUCCESS"
export const MEMBERSHIP_TYPE_FAIL = "MEMBERSHIP_TYPE_FAIL"

export const ADD_MEMBERSHIP_TYPE = "ADD_MEMBERSHIP_TYPE"
export const ADD_MEMBERSHIP_TYPE_SUCCESS = "ADD_MEMBERSHIP_TYPE_SUCCESS"
export const ADD_MEMBERSHIP_TYPE_FAIL = "ADD_MEMBERSHIP_TYPE_FAIL"

export const UPDATE_MEMBERSHIP_TYPE = "UPDATE_MEMBERSHIP_TYPE"
export const UPDATE_MEMBERSHIP_TYPE_SUCCESS = "UPDATE_MEMBERSHIP_TYPE_SUCCESS"
export const UPDATE_MEMBERSHIP_TYPE_FAIL = "UPDATE_MEMBERSHIP_TYPE_FAIL"

export const DELETE_MEMBERSHIP_TYPE = "DELETE_MEMBERSHIP_TYPE"
export const DELETE_MEMBERSHIP_TYPE_SUCCESS = "DELETE_MEMBERSHIP_TYPE_SUCCESS"
export const DELETE_MEMBERSHIP_TYPE_FAIL = "DELETE_MEMBERSHIP_TYPE_FAIL"

export const MEMBERSHIPS = "MEMBERSHIPS"
export const MEMBERSHIPS_SUCCESS = "MEMBERSHIPS_SUCCESS"
export const MEMBERSHIPS_FAIL = "MEMBERSHIPS_FAIL"

export const ADD_MEMBERSHIPS = "ADD_MEMBERSHIPS"
export const ADD_MEMBERSHIPS_SUCCESS = "ADD_MEMBERSHIPS_SUCCESS"
export const ADD_MEMBERSHIPS_FAIL = "ADD_MEMBERSHIPS_FAIL"

export const IMPORT_MEMBERSHIPS = "IMPORT_MEMBERSHIPS"
export const IMPORT_MEMBERSHIPS_SUCCESS = "IMPORT_MEMBERSHIPS_SUCCESS"
export const IMPORT_MEMBERSHIPS_FAIL = "IMPORT_MEMBERSHIPS_FAIL"

export const UPDATE_MEMBERSHIP = "UPDATE_MEMBERSHIP"
export const UPDATE_MEMBERSHIP_SUCCESS = "UPDATE_MEMBERSHIP_SUCCESS"
export const UPDATE_MEMBERSHIP_FAIL = "UPDATE_MEMBERSHIP_FAIL"

export const DELETE_MEMBERSHIP = "DELETE_MEMBERSHIP"
export const DELETE_MEMBERSHIP_SUCCESS = "DELETE_MEMBERSHIP_SUCCESS"
export const DELETE_MEMBERSHIP_FAIL = "DELETE MEMBERSHIP_FAIL"

export const ADD_MEMBER_PRICES = "ADD_MEMBER_PRICES"
export const ADD_MEMBER_PRICES_SUCCESS = "ADD_MEMBER_PRICES_SUCCESS"
export const ADD_MEMBER_PRICES_FAIL = "ADD_MEMBER_PRICES_FAIL"

export const UPDATE_MEMBER_PRICE = "UPDATE_MEMBER_PRICE"
export const UPDATE_MEMBER_PRICE_SUCCESS = "UPDATE_MEMBER_PRICE_SUCCESS"
export const UPDATE_MEMBER_PRICE_FAIL = "UPDATE_MEMBER_PRICE_FAIL"

export const EMAIL_RESTRICTION = "EMAIL_RESTRICTION"
export const WHITELIST_SUCCESS = "EMAIL_RESTRICTION_SUCCESS"
export const WHITELIST_FAIL = "EMAIL_RESTRICTION_FAIL"
export const DOMAIN_SUCCESS = "DOMAIN_SUCCESS"
export const DOMAIN_FAIL = "DOMAIN_FAIL"

export const ADD_WHITELIST_ENTRY = "ADD_WHITELIST_ENTRY"
export const ADD_WHITELIST_ENTRY_SUCCESS = "ADD_WHITELIST_ENTRY_SUCCESS"
export const ADD_WHITELIST_ENTRY_FAIL = "ADD_WHITELIST_ENTRY_FAIL"

export const DELETE_WHITELIST_ENTRY = "DELETE_WHITELIST_ENTRY"
export const DELETE_WHITELIST_ENTRY_SUCCESS = "DELETE_WHITELIST_ENTRY_SUCCESS"
export const DELETE_WHITELIST_ENTRY_FAIL = "DELETE_WHITELIST_ENTRY_FAIL"

export const EVENT_TYPES = "EVENT_TYPES"
export const EVENT_TYPES_SUCCESS = "EVENT_TYPES_SUCCESS"
export const EVENT_TYPES_FAIL = "EVENT_TYPES_FAIL"

export const UPDATE_EVENT_TYPE = "UPDATE_EVENT_TYPE"
export const UPDATE_EVENT_TYPE_SUCCESS = "UPDATE_EVENT_TYPE_SUCCESS"
export const UPDATE_EVENT_TYPE_FAIL = "UPDATE_EVENT_TYPE_FAIL"

export const ADD_EVENT_TYPE = "ADD_EVENT_TYPE"
export const ADD_EVENT_TYPE_SUCCESS = "ADD_EVENT_TYPE_SUCCESS"
export const ADD_EVENT_TYPE_FAIL = "ADD_EVENT_TYPE_FAIL"

export const DELETE_EVENT_TYPE = "DELETE_EVENT_TYPE"
export const DELETE_EVENT_TYPE_SUCCESS = "DELETE_EVENT_TYPE_SUCCESS"
export const DELETE_EVENT_TYPE_FAIL = "DELETE_EVENT_TYPE_FAIL"

export const EVENT_TAGS = "EVENT_TAGS"
export const EVENT_TAGS_SUCCESS = "EVENT_TAGS_SUCCESS"
export const EVENT_TAGS_FAIL = "EVENT_TAGS_FAIL"

export const UPDATE_EVENT_TAG = "UPDATE_EVENT_TAG"
export const UPDATE_EVENT_TAG_SUCCESS = "UPDATE_EVENT_TAG_SUCCESS"
export const UPDATE_EVENT_TAG_FAIL = "UPDATE_EVENT_TAG_FAIL"

export const ADD_EVENT_TAG = "ADD_EVENT_TAG"
export const ADD_EVENT_TAG_SUCCESS = "ADD_EVENT_TAG_SUCCESS"
export const ADD_EVENT_TAG_FAIL = "ADD_EVENT_TAG_FAIL"

export const DELETE_EVENT_TAG = "DELETE_EVENT_TAG"
export const DELETE_EVENT_TAG_SUCCESS = "DELETE_EVENT_TAG_SUCCESS"
export const DELETE_EVENT_TAG_FAIL = "DELETE_EVENT_TAG_FAIL"

export const FORMS = "FORMS"
export const FORMS_SUCCESS = "FORMS_SUCCESS"
export const FORMS_FAIL = "FORMS_FAIL"

export const FORM = "FORM"
export const FORM_SUCCESS = "FORM_SUCCESS"
export const FORM_FAIL = "FORM_FAIL"

export const ADD_FORM = "ADD_FORM"
export const ADD_FORM_SUCCESS = "ADD_FORM_SUCCESS"
export const ADD_FORM_FAIL = "ADD_FORM_FAIL"

export const UPDATE_FORM = "UPDATE_FORM"
export const UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS"
export const UPDATE_FORM_FAIL = "UPDATE_FORM_FAIL"

export const DELETE_FORM = "DELETE_FORM"
export const DELETE_FORM_SUCCESS = "DELETE_FORM_SUCCESS"
export const DELETE_FORM_FAIL = "DELETE_FORM_FAIL"

export const GET_SEARCH_DATA = "GET_SEARCH_DATA"
export const SEARCH_DATA_SUCCESS = "SEARCH_DATA_SUCCESS"
export const SEARCH_DATA_FAIL = "SEARCH_DATA_FAIL"

export const  BOOKING_SUCCESS_FALSE = "BOOKING_SUCCESS_FALSE"
export const  MANAGE_STATUS_BOOKING = "MANAGE_STATUS_BOOKING"
export const  MANAGE_STATUS_BOOKING_FAIL = "MANAGE_STATUS_BOOKING_FAIL"

export const  SAVE_RATING = "SAVE_RATING"
export const  SAVE_RATING_SUCCESS = "SAVE_RATING_SUCCESS"
export const  SAVE_RATING_ERROR = "SAVE_RATING_ERROR"

export const  CHAT_NOTES = "CHAT_NOTES"
export const  CHAT_NOTES_SUCCESS = "CHAT_NOTES_SUCCESS"
export const  CHAT_NOTES_ERROR = "CHAT_NOTES_ERROR"

export const  ADD_WISHLIST = "ADD_WISHLIST"
export const  ADD_WISHLIST_SUCCESS = "ADD_WISHLIST_SUCCESS"
export const  ADD_WISHLIST_ERROR = "ADD_WISHLIST_ERROR"

export const  GET_WISHLIST = "GET_WISHLIST"
export const  GET_WISHLIST_SUCCESS = "GET_WISHLIST_SUCCESS"
export const  GET_WISHLIST_ERROR = "GET_WISHLIST_ERROR"

export const  GET_LOCATION = "GET_LOCATION"
export const  GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS"
export const  GET_LOCATION_ERROR = "GET_LOCATION_ERROR"

export const  REMOVE_FROM_WISHLIST = "REMOVE_FROM_WISHLIST"
export const  REMOVE_FROM_WISHLIST_SUCCESS = "REMOVE_FROM_WISHLIST_SUCCESS"
export const  REMOVE_FROM_WISHLIST_FAIL = "REMOVE_FROM_WISHLIST_FAIL"

export const  PROVIDER_VENUE_LISTING = "PROVIDER_VENUE_LISTING"
export const  PUBLIC_PROVIDER_VENUE_LISTING = "PUBLIC_PROVIDER_VENUE_LISTING"
export const  PROVIDER_VENUE_LISTING_SUCCESS = "PROVIDER_VENUE_LISTING_SUCCESS"
export const  PROVIDER_VENUE_LISTING_FAIL = "PROVIDER_VENUE_LISTING_FAIL"


export const  OVERALLSTATUS = "OVERALLSTATUS"
export const  OVERALLSTATUS_SUCCESS = "OVERALLSTATUS_SUCCESS"
export const  OVERALLSTATUS_FAIL = "OVERALLSTATUS_FAIL"


export const  SLOT_ALREADY_EXIST = "SLOT_ALREADY_EXIST"
export const  SLOT_ALREADY_EXIST_SUCCESS = "SLOT_ALREADY_EXIST_SUCCESS"
export const  SLOT_ALREADY_EXIST_FAIL = "SLOT_ALREADY_EXIST_FAIL"


export const  SINGLE_VENUE_REVIEW = "SINGLE_VENUE_REVIEW"
export const  SINGLE_VENUE_REVIEW_SUCCESS = "SINGLE_VENUE_REVIEW_SUCCESS"
export const  SINGLE_VENUE_REVIEW_FAIL = "SINGLE_VENUE_REVIEW_FAIL"


export const  GET_NOTIFICATION = "GET_NOTIFICATION"
export const  GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
export const  GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL"


export const  EACH_VENUE_DETAIL = "EACH_VENUE_DETAIL"
export const  EACH_VENUE_DETAIL_SUCCESS = "EACH_VENUE_DETAIL_SUCCESS"
export const  EACH_VENUE_DETAIL_FAIL = "EACH_VENUE_DETAIL_FAIL"

export const  DRAFT_EVENT = "DRAFT_EVENT"
export const  DRAFT_EVENT_SUCCESS = "DRAFT_EVENT_SUCCESS"
export const  DRAFT_EVENT_ERROR = "DRAFT_EVENT_ERROR"

export const  GET_DRAFT_EVENT = "GET_DRAFT_EVENT"
export const  GET_DRAFT_EVENT_SUCCESS = "GET_DRAFT_EVENT_SUCCESS"
export const  GET_DRAFT_EVENT_ERROR = "GET_DRAFT_EVENT_ERROR"

export const  APPLYCOUPON_STATUS = "APPLYCOUPON_STATUS"
export const  APPLYCOUPON_SUCCESS = "APPLYCOUPON_SUCCESS"
export const  APPLYCOUPON_FAIL = "APPLYCOUPON_FAIL"
export const  APPLYCOUPON_CLEAR = "APPLYCOUPON_CLEAR"

export const  WALLET_STATUS = "WALLET_STATUS"
export const  WALLET_SUCCESS = "WALLET_SUCCESS"
export const  WALLET_FAIL = "WALLET_FAIL"

export const  PAYMENTSUCCEED_STATUS = "PAYMENTSUCCEED_STATUS"
export const  PAYMENTSUCCEED_SUCCESS = "PAYMENTSUCCEED_SUCCESS"
export const  PAYMENTSUCCEED_FAIL = "PAYMENTSUCCEED_FAIL"

export const  SAVE_NOTIFICATION = "SAVE_NOTIFICATION"
export const  SAVE_NOTIFICATION_SUCCESS = "SAVE_NOTIFICATION_SUCCESS"
export const  SAVE_NOTIFICATION_FAIL = "SAVE_NOTIFICATION_FAIL"

export const  GET_ALL_DRAFT_VENUES = "GET_ALL_DRAFT_VENUES"
export const  GET_ALL_DRAFT_VENUES_SUCCESS = "GET_ALL_DRAFT_VENUES_SUCCESS"
export const  GET_ALL_DRAFT_VENUES_FAIL = "GET_ALL_DRAFT_VENUES_FAIL"


export const  WALLET_HISTORY_STATUS = "WALLET_HISTORY_STATUS"
export const  WALLET_HISTORY_SUCCESS = "WALLET_HISTORY_SUCCESS"
export const  WALLET_HISTORY_FAIL = "WALLET_HISTORY_FAIL"

export const  BANK_STATUS = "BANK_STATUS"
export const  BANK_SUCCESS = "BANK_SUCCESS"
export const  BANK_FAIL = "BANK_FAIL"

export const  GET_NOTIFICAITON_LISTING = "GET_NOTIFICAITON_LISTING"
export const  GET_NOTIFICAITON_FAIL = "GET_NOTIFICAITON_FAIL"
export const  GET_NOTIFICAITON_SUCCESS = "GET_NOTIFICAITON_SUCCESSx"

export const getUserFetch = () => ({
    type: GET_USER_FETCH
})

export const setAlert = (msg, alertType) => (
    {
    type: SHOW_ALERT,
    payload: { msg, alertType }
})

export const signUpUser = (userDetail) => (
    {
        type : GET_USERS_SUCCESS,
        payload : userDetail
    }
)

export const logInUser = (userDetail) => (
    {
        type : USER_LOGIN,
        payload : userDetail
    }
)
export const recomendedVenues = (data) => (
    {
        type : LIST_VENUES,
        payload : data
    }
)

export const sendVerificationEmail = (data) => (
    {
        type : SEND_VERIFICATION_EMAIL,
        payload : data
    }
)

export const verifyEmail = (data) => (
    {
        type : ACCOUNT_VERIFY,
        payload :data
    }
)

export const singleVenueDetail = (data) => (
    {
        type : SINGLE_VENUE_DETAIL,
        payload : data
    }
)

export const singleVenuePackages = (data) => (
    {
        type : GET_PACKAGES_SUCCESS,
        payload : data
    }
)

export const getProviderProfile = (data) => (
    {
        type: PROVIDER_PROFILE,
        payload: data
    }
)

export const getPublicProviderProfile = (data) => (
    {
        type: PUBLIC_PROVIDER_PROFILE,
        payload: data
    }
)

export const getUserProfile = (data) => (
    {
        type : USER_PROFILE,
    }
)

export const updateProfile = data => ({
    type : UPDATE_USER_DETAIL,  
    payload :data
})

export const updateBusinessProfile = data => ({
    type : UPDATE_BUSINESS_PROFILE,  
    payload :data
})

export const getBusinessPlan = data => ({
    type: BUSINESS_PLAN,
    payload: data,
})

export const getManagers = data => ({
    type: MANAGERS,
    payload: data,
})

export const addManager = data => ({
    type: ADD_MANAGER,
    payload: data,
})

export const updateManagerAccess = data => ({
    type: UPDATE_MANAGER_ACCESS,
    payload: data,
})

export const updateManagerSettings = data => ({
    type: UPDATE_MANAGER_SETTINGS,
    payload: data,
})

export const getAllAmenities = data => ({
    type : ALL_AMENITIES,
    payload :data
})

export const bookVenue = data => ({
    type : BOOK_VENUE,  
    payload : data
})

export const getEventDetail = data => ({
    type: EVENT_DETAIL,
    payload: data,
})

export const updateEventDetail = data => ({
    type : UPDATE_EVENT_DETAIL,
    payload : data
})

export const cancelBooking = data => ({
    type : CANCEL_BOOKING,  
    payload : data
})

export const bookingPaymentProcessed = data => ({
    type : BOOKING_PAYMENT_PROCESSED,  
    payload : data
})

export const bizDeleteEvent = data => ({
    type : BIZ_DELETE_EVENT,  
    payload : data
})

export const confirmEvent = data => ({
    type : CONFIRM_EVENT,  
    payload : data
})

export const declineEvent = data => ({
    type : DECLINE_EVENT,  
    payload : data
})

export const sendConfirmationEmail = data => ({
    type: SEND_CONFIRMATION_EMAIL,
    payload: data
})

export const addTask = data => ({
    type: ADD_TASK,
    payload: data
})

export const updateTask = data => ({
    type: UPDATE_TASK,
    payload: data
})

export const deleteTask = data => ({
    type: DELETE_TASK,
    payload: data
})

export const calendarReset = data => ({
    type : CALENDAR_RESET,  
    payload : data
})

export const forgotPwd = data => ({
    type : FORGOT_PWD,  
    payload : data
})

export const authResetPwd = data => ({
    type : AUTH_RESET_PWD,  
    payload : data
})

export const resetPwd = data => ({
    type : RESET_PWD,  
    payload : data
})

export const userCurrentBookings = data => ({
    type : CURRENT_BOOKINGS,  
    payload :data
})

export const customerList = data => ({
    type : CUSTOMER_LIST,  
    payload :data
})

export const calenderData = data => ({
    type : CALENDAR_DATA,  
    payload :data
})

export const publicCalendarData = data => ({
    type : PUBLIC_CALENDAR_DATA,
    payload :data
})

export const getReservationReports = data => ({
    type: RESERVATION_REPORTS
})

export const addReservationReport = data => ({
    type: ADD_RESERVATION_REPORT,
    payload: data,
})

export const updateReservationReport = data => ({
    type: UPDATE_RESERVATION_REPORT,
    payload: data,
})

export const deleteReservationReport = data => ({
    type: DELETE_RESERVATION_REPORT,
    payload: data,
})

export const getMembershipTypes = data => ({
    type: MEMBERSHIP_TYPES,
    payload: data,
})

export const getMembershipType = data => ({
    type: MEMBERSHIP_TYPE,
    payload: data,
})

export const addMembershipType = data => ({
    type: ADD_MEMBERSHIP_TYPE,
    payload: data,
})

export const updateMembershipType = data => ({
    type: UPDATE_MEMBERSHIP_TYPE,
    payload: data,
})

export const deleteMembershipType = data => ({
    type: DELETE_MEMBERSHIP_TYPE,
    payload: data,
})

export const getMemberships = data => ({
    type: MEMBERSHIPS,
    payload: data,
})

export const addMemberships = data => ({
    type: ADD_MEMBERSHIPS,
    payload: data,
})

export const importMemberships = data => ({
    type: IMPORT_MEMBERSHIPS,
    payload: data,
})

export const updateMembership = data => ({
    type: UPDATE_MEMBERSHIP,
    payload: data,
})

export const deleteMembership = data => ({
    type: DELETE_MEMBERSHIP,
    payload: data,
})

export const addMemberPrices = data => ({
    type: ADD_MEMBER_PRICES,
    payload: data,
})

export const updateMemberPrice = data => ({
    type: UPDATE_MEMBER_PRICE,
    payload: data,
})

export const getEmailRestriction = data => ({
    type: EMAIL_RESTRICTION,
    payload: data
})

export const addEmailRestrictionEntry = data => ({
    type: ADD_WHITELIST_ENTRY,
    payload: data
})

export const deleteEmailRestrictionEntry = data => ({
    type: DELETE_WHITELIST_ENTRY,
    payload: data
})

export const getEventTypes = data => ({
    type : EVENT_TYPES,
    payload :data
})

export const updateEventTypeDetail = data => ({
    type : UPDATE_EVENT_TYPE,
    payload :data
})

export const addEventType = data => ({
    type : ADD_EVENT_TYPE,
    payload :data
})

export const deleteEventType = data => ({
    type : DELETE_EVENT_TYPE,
    payload :data
})

export const getEventTags = data => ({
    type : EVENT_TAGS,
})

export const updateEventTagDetail = data => ({
    type : UPDATE_EVENT_TAG,
    payload :data
})

export const addEventTag = data => ({
    type : ADD_EVENT_TAG,
    payload :data
})

export const deleteEventTag = data => ({
    type : DELETE_EVENT_TAG,
    payload :data
})

export const getForms = data => ({
    type : FORMS,
})

export const getForm = data => ({
    type : FORM,
    payload: data,
})

export const addForm = data => ({
    type : ADD_FORM,
    payload: data,
})

export const updateForm = data => ({
    type: UPDATE_FORM,
    payload: data,
})

export const deleteForm = data => ({
    type: DELETE_FORM,
    payload: data,
})

export const  getSearchData = data => ({
    type : GET_SEARCH_DATA,  
    payload :data
})

export const bookingSuccessFalse = () => ({
    type : BOOKING_SUCCESS_FALSE,
    payload : {}
})

export const saveRating = (data) => ({
    type : SAVE_RATING,
    payload : data
})

export const addToWishList = (data) => ({
    type : ADD_WISHLIST,
    payload : data
})

export const getWishList = (data) => ({
    type : GET_WISHLIST,
    payload : data
})

export const getLocationDetail = (data) =>  ({
    type : GET_LOCATION,
    payload : data
})

export const removeFromWishList = (data) => ({
    type : REMOVE_FROM_WISHLIST,
    payload : data
})

export const getProviderVenueListing = (data) => ({
    type : PROVIDER_VENUE_LISTING,
    payload : data
})

export const getPublicProviderVenueListing = (data) => ({
    type: PUBLIC_PROVIDER_VENUE_LISTING,
    payload: data
})

export const chatNotes = (data) => (
    {
        type : CHAT_NOTES,
        payload : data
    }
)

export const overAllStatus = (data) => (
    {
        type : OVERALLSTATUS,
        payload : data
    }
)

export const checkAvailability = (data) => (
    {
        type : SLOT_ALREADY_EXIST,
        payload : data
    }
)

export const singleVenueReivews = (data) => (
    {
        type : SINGLE_VENUE_REVIEW,
        payload : data
    }
)


export const eachVenueDetail = (data) => (
    {
        type : EACH_VENUE_DETAIL,
        payload : data
    }
)

export const updateVenueDetail = (data) => (
    {
        type : UPDATE_VENUE_DETAIL,
        payload : data
    }
)

export const draftEvent = (data) => (
    {
        type : DRAFT_EVENT,
        payload : data
    }
)

export const getDraft = (data) => (
    {
        type : GET_DRAFT_EVENT,
        payload : data
    }
)

export const logout=(data)=>(
    {
        type : LOGOUT_SUCCESS,
    }
)

export const applyCouponStatus = (data) => (
    {
        type : APPLYCOUPON_STATUS,
        payload : data
    }
)

export const getNotification = (data) => (
    {
        type : GET_NOTIFICATION,
        payload : data
    }
)


export const ClearCouponStatus = (data) => (
    {
        type : APPLYCOUPON_CLEAR,
        payload : data
    }
)

export const saveNotification = (data) => (
    {
        type : SAVE_NOTIFICATION,
        payload : data
    }
)
export const getAllDraftVenues = (data) => (
    {
        type : GET_ALL_DRAFT_VENUES,
        payload : data
    }
)
export const paymentSucceedStatus = (data) => (
{
    type : PAYMENTSUCCEED_STATUS,
    payload : data
})

export const walletHistoryStatus = (data) => (
{
    type : WALLET_HISTORY_STATUS,
    payload : data
})

export const getWalletStatus = (data) => (
{
    type : WALLET_STATUS,
    payload : data
})

export const bankStatus = (data) => (
{
    type : BANK_STATUS,
    payload : data
})

export const getNotificaitonCount = (data) => (
{
    type : GET_NOTIFICAITON_LISTING,
    payload : data
})

export const setResetPwdFalse=(data)=>(
    {
        type : SET_RESET_PWD_FALSE,
    }
)


