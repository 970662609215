import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {TEST_STRIPE} from "endpoint";
import {
    singleVenueDetail,
    SITE_URL,
    getUserProfile
} from "../../redux/action/action";
import { useSearchParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import CheckoutFormNoPayment from "./CheckoutFormNoPayment";
import "../../App.css";
import ErrorPage from "../../ErrorPage"
import {formatDate, getTimeString} from "../../Utils/DateTimeUtils";
import {resetNavbarBrand, updateNavbarBrand} from "../../redux/action/accountModals";

let stripePromise;
if (TEST_STRIPE) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_TEST);
} else {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY_LIVE);
}

function Checkout() {
    document.title = "Checkout | DoubleSpot";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Book your desired time slot for event venues on DoubleSpot. Experience seamless transactions for your reservations."
    );

    const dispatch = useDispatch()

    const [searchParams] = useSearchParams();
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const singleVenueDetails = useSelector(state => state.Apis.singleVenueDetail);
    const userDetail = useSelector(state => state.Login.userDetail);
    // const coupon = useSelector(state => state.Apis.CouponValue);

    const [venueDetail, setVenueDetail] = useState({})
    const [checkoutData, setCheckOutData] = useState(JSON.parse(localStorage.getItem("bookingData")))
    const [images, setImages] = useState(["noImageFound.png"])

    const venueId = localStorage.getItem('venueId');

    useEffect(() => {
        const sendData = {
            venueId,
            userId: userDetail.id || 0,
        }
        dispatch(singleVenueDetail(sendData));
        dispatch(getUserProfile());
        const checkOutDatadd = localStorage.getItem("bookingData");
        setCheckOutData(JSON.parse(checkOutDatadd));
    }, []);

    useEffect(() => {
        setVenueDetail(singleVenueDetails)
        var getImages = singleVenueDetails?.images
        if (getImages && getImages?.split(",").length > 0) {
            for (let i = 0; i < getImages?.split(",").length; i++) {
                images.unshift(getImages?.split(",")[i])
            }
            setImages(images)
        }
        const brandLogo = singleVenueDetails.business_plan?.brand_logo;
        dispatch(updateNavbarBrand({
            navbarLogo: brandLogo ? `/images/${brandLogo}` : "",
            navbarColor: singleVenueDetails.business_plan?.brand_color,
            navbarButtonColor: singleVenueDetails.business_plan?.brand_button_color,
            navbarButtonWhiteText: singleVenueDetails.business_plan?.brand_button_white_text,
        }));

        return () => {
            dispatch(resetNavbarBrand());
        }
    }, [singleVenueDetails]);

    const options = {
        // passing the client secret obtained in step 3
        clientSecret: searchParams.get("client_secret"),
        // Fully customizable with appearance API.
        appearance: {
            theme: 'stripe',
            variables: {
                colorPrimary: '#52e105',
            },
        },
    };


    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: "instant"});
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function handleScroll() {
        console.warn("check the scrol area")
    }

    function altimage() {
        if (document.getElementById('checkoutImage')) {
            document.getElementById('checkoutImage').src = '../../../assets/images/fav_icon.png';
            document.getElementById('checkoutImage').style = 'width:auto';
        }

    }

    if (venueId != parseInt(document.location.pathname.substring(10))){
        return (
            <><ErrorPage /></>
        )
    }

    const CheckoutDetails = ({className}) => (
        <div className={className}>
            <div className="card rounded-4 box_shadow">
                <div className="card_top p-4">
                    <h3>{venueDetail?.name}</h3>
                    <img id="checkoutImage" src={SITE_URL + 'images/' + images[0]} alt="DoubleSpot.com"
                         onError={altimage}/>
                </div>

                <div className="card_body p-4">
                    <small><i
                        className="fa-regular fa-calendar-days"></i>{formatDate(checkoutData?.date)}</small>
                    <small className="mt-3">
                        <i className="fa-regular fa-clock"/>
                        {getTimeString(new Date(`${checkoutData?.date} ${checkoutData?.start_time}`), true)}
                        {" - "}
                        {getTimeString(new Date(`${checkoutData?.date} ${checkoutData?.end_time}`), true)}
                    </small>
                    <small className="mt-3"><i
                        className="fa-solid fa-user-group"></i>{checkoutData?.attendees} guests</small>
                </div>
                {venueDetail.process_payment ?
                    <div className="checkout_price">
                        <div className="card_body px-4 pb-2 pt-4 border-top"><h4>Price Detail</h4></div>
                        <div className="card_body pb-3 px-4">
                            <small>Subtotal <small
                                className="ms-auto">${(checkoutData?.finalPrice - checkoutData?.cleaningFee).toFixed(2)}</small></small>
                            <small>Processing & Cleaning <small
                                className="ms-auto">${(checkoutData?.processingFee + checkoutData?.cleaningFee).toFixed(2)}</small></small>
                        </div>
                        <div className="tow_line px-4 py-3 border-top">
                            <strong className="d-flex">Total<strong
                                className="ms-auto">${((checkoutData?.finalPrice + checkoutData?.processingFee) || 0).toFixed(2)}</strong></strong>
                        </div>
                    </div> :
                    null
                }
            </div>
        </div>
    )

    return (
        <>
            <section className="checkout pb-4 pt-0">
                <div className="container">
                    <div className="text-center pb-4 pt-4">
                        <h2>Complete Request</h2>
                    </div>

                    <div className="row pb-4">
                        {windowSize[0] >= 992 &&
                            <div className="col-md-1"></div>
                        }
                        {windowSize[0] < 992 && <CheckoutDetails className="mb-4"/>}
                        {venueDetail.process_payment ?
                            <div className="col-lg-6 pb-12">

                                <Elements stripe={stripePromise} options={options}>
                                    <CheckoutForm 
                                        payment_intent={searchParams.get("payment_intent")}
                                        client_secret={searchParams.get("client_secret")}
                                    />
                                </Elements>

                            </div> :
                            <div className="col-lg-6 pb-12">
                                <CheckoutFormNoPayment />
                            </div>
                        }
                        {windowSize[0] >= 992 && <CheckoutDetails className="col-md-4"/>}
                        <div className="col-md-1"></div>
                    </div>
                </div>
            </section>
        </>)
}


export default Checkout