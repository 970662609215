import TimePicker from "../../../components/TimePicker";
import {
    CButton,
    CCard,
    CCardBody,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormSwitch
} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {
    getAllAmenities,
    getForms,
    setAlert,
    singleVenueDetail,
    updateVenueDetail
} from "../../../../redux/action/action";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import 'react-quill/dist/quill.snow.css';
import ReactQuill from "react-quill";
import {unavailableTime, weekDays} from "../../../../Utils/DateTimeUtils";
import {SortableItems} from "./components/SortableItems";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import {users} from "constants";

const EditVenue = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(state => state.Login.userDetail);
    const singleVenueDetails = useSelector(state => state.Apis.singleVenueDetail);
    const updateVenueSuccess = useSelector(state => state.Apis.updateVenueSuccess);
    const allAmenities = useSelector(state => state.Apis.allAmenities);
    const forms = useSelector(state => state.Apis.forms);

    const [venueDetail, setVenueDetail] = useState({});
    const [formData, setFormData] = useState({});
    const [description, setDescription] = useState('');
    const [hostrules, setHostrules] = useState('');
    const [message_prompt, setMessagePrompt] = useState('');
    const [images, setImages] = useState([]);
    const [imageOrder, setImageOrder] = useState([]);
    const [amenitiesList, setAmenitiesList] = useState([]);
    const [formList, setFormList] = useState([]);

    const updateForm = (event) => {
        setFormData({...formData, 
                    [event.target.name]: event.target.name === "use_absolute_date" ? 
                        parseInt(event.target.value) :
                        event.target.name === "hidden" ? 
                            event.target.checked ? 1 : 0 : 
                            event.target.value
                    });
    }

    const updateFormMultiselect = (selectedOptions, actionMeta) => {
        setFormData({...formData, [actionMeta.name]: selectedOptions});
    }

    const updateFormTimePicker = (selectedOption, actionMeta) => {
        setFormData({...formData, [actionMeta.name]: selectedOption.value});
    }

    const submitForm = () => {
        const venueData = {
            ...formData,
            description: description,
            hostrules: hostrules,
            message_prompt: message_prompt,
            amenities: formData.amenities.map(amenity => amenity.value).join(','),
            id: venueDetail.id,
            images: imageOrder.map(i => images[i - 1]),
            form_id: parseInt(formData.form_id),
        };
        weekDays.map(weekday => {
            const abbreviation = weekday.substring(0, 3).toLowerCase();
            if (venueData[`start_time_${abbreviation}`] === null) {
                venueData[`start_time_${abbreviation}`] = unavailableTime;
            }
            if (venueData[`end_time_${abbreviation}`] === null) {
                venueData[`end_time_${abbreviation}`] = unavailableTime;
            }
        });
        dispatch(updateVenueDetail(venueData));
    }

    useEffect(() => {
        const venueId = parseInt(document.location.pathname.split('/')[3]);
        const sendData = {
            venueId: venueId,
            userId: 0
        }
        dispatch(singleVenueDetail(sendData));
        dispatch(getAllAmenities());
        dispatch(getForms());
    }, []);

    useEffect(() => {
        setFormList(forms);
    }, [forms]);

    useEffect(() => {
        setAmenitiesList(allAmenities.map(amenity => ({
            label: amenity.name,
            value: amenity.name,
        })));
    }, [allAmenities]);

    useEffect(() => {
        if (singleVenueDetails.id) {
            if (userDetail.id !== singleVenueDetails.business_id && !(singleVenueDetails.managers.map(manager => manager.id).includes(userDetail.id)) ) {
                dispatch(setAlert('You do not have the permission to edit the venue\'s information.','error'));
                navigate("/business/venues");
            }
            setVenueDetail(singleVenueDetails);
            const defaultFormData = {};
            weekDays.map(weekday => {
                const abbreviation = weekday.substring(0, 3).toLowerCase();
                defaultFormData[`start_time_${abbreviation}`] = singleVenueDetails[`start_time_${abbreviation}`];
                defaultFormData[`end_time_${abbreviation}`] = singleVenueDetails[`end_time_${abbreviation}`];
            });
            defaultFormData.amenities = singleVenueDetails.amenities?.map(({name}) => ({
                label: name,
                value: name,
            }));
            defaultFormData.use_absolute_date = singleVenueDetails.use_absolute_date;
            defaultFormData.hidden = singleVenueDetails.hidden;
            defaultFormData.form_id = singleVenueDetails.form_id;
            setFormData(defaultFormData);
    
            document.title = `Edit Venue: ${singleVenueDetails.name} | DoubleSpot Business`;
            document.querySelector('meta[name="description"]').setAttribute(
                "content",
                `Edit event venue ${singleVenueDetails.name} with DoubleSpot's business dashboard.`
            );

            if (singleVenueDetails.images) {
                setImages(singleVenueDetails.images.split(',').map(src => ({ src })));
            }
        }
    }, [singleVenueDetails]);

    useEffect(() => {
        setDescription(venueDetail.description);
        setHostrules(venueDetail.hostrules);
        setMessagePrompt(venueDetail.message_prompt);
    }, [venueDetail]);

    useEffect(() => {
        if (updateVenueSuccess) {
            navigate("/business/venues");
        }
    }, [updateVenueSuccess]);

    useEffect(() => {
        if (userDetail.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.editVenueAccess) {
                dispatch(setAlert('You do not have the permissions to edit venue details.','error'));
                navigate("/business");
            }
        }
    }, [userDetail]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>Edit Venue: {venueDetail?.name}</h2>
                    <hr/>
                    <div className="w-50">
                        <div className="pt-3"><h4>Basic Information</h4></div>
                        <div className="py-2">
                            <CFormLabel htmlFor="name">
                                <div className="edit-venue-form-label">Name</div>
                            </CFormLabel>
                            <CFormInput type="text" name="name" placeholder="DoubleSpot Center"
                                        defaultValue={venueDetail?.name} onChange={updateForm}/>
                        </div>
                        <div className="py-2">
                            <CFormLabel>
                                <div className="edit-venue-form-label">Photos</div>
                                <p className="modal-subtitle mb-0">Please include between 3-7 photos.</p>
                            </CFormLabel>
                            <SortableItems dispatch={dispatch} images={images} setImages={setImages}
                                           imageOrder={imageOrder} setImageOrder={setImageOrder}/>
                        </div>
                        <div className="py-2">
                            <CFormLabel htmlFor="description">
                                <div className="edit-venue-form-label">Description</div>
                            </CFormLabel>
                            <ReactQuill id="description-editor" theme="snow" name="description" value={description}
                                        onChange={(value) => setDescription(value)}/>
                        </div>
                        <div className="py-2">
                            <CFormLabel htmlFor="hostrules">
                                <div className="edit-venue-form-label">Host Rules</div>
                            </CFormLabel>
                            <ReactQuill id="hostrules-editor" theme="snow" name="hostrules" value={hostrules}
                                        onChange={(value) => setHostrules(value)}/>
                        </div>
                        <div className="py-2">
                            <CFormLabel htmlFor="form_id">
                                <div className="edit-venue-form-label">Booking Request Form</div>
                            </CFormLabel>
                            <div className="flex-row align-items-center">
                                <CFormSelect
                                    className="w-75 me-3"
                                    name="form_id"
                                    value={formData?.form_id}
                                    onChange={updateForm}
                                >
                                    <option value="0">None</option>
                                    {
                                        formList.map(form => <option value={form.id}>{form.name}</option>)
                                    }
                                </CFormSelect>
                                <a href={`/business/forms/${formData?.form_id}`} target="_blank">
                                    <u>Edit Form</u>
                                </a>
                            </div>
                        </div>
                        <div className="py-2">
                            <CFormLabel htmlFor="amenities">
                                <div className="edit-venue-form-label">Amenities</div>
                            </CFormLabel>
                            <MultiSelectCheckbox
                                value={formData.amenities}
                                name="amenities"
                                onChange={updateFormMultiselect}
                                options={amenitiesList}
                            />
                        </div>

                        {venueDetail.process_payment ?
                            <div className="rates">
                                <div className="pt-3"><h4>Rates</h4></div>
                                <div className="py-2">
                                    <CFormLabel htmlFor="free_attendees">
                                        <div className="edit-venue-form-label">Attendees Allowed Without Additional
                                            Cost
                                        </div>
                                    </CFormLabel>
                                    <div className="flex-row align-items-center">
                                        <CFormInput style={{width: "70px"}} type="number" name="free_attendees" min="0"
                                                    defaultValue={venueDetail?.free_attendees} onChange={updateForm}/>
                                        &nbsp; attendees
                                    </div>
                                </div>
                                <div className="py-2">
                                    <CFormLabel htmlFor="price_per_attendee">
                                        <div className="edit-venue-form-label">Price Per Additional Attendee</div>
                                    </CFormLabel>
                                    <div className="flex-row align-items-center">
                                        $ &nbsp; <CFormInput style={{width: "80px"}} type="number"
                                                             name="price_per_attendee"
                                                             min="0"
                                                             defaultValue={venueDetail?.price_per_attendee}
                                                             onChange={updateForm}/>
                                    </div>
                                </div>
                                <div className="py-2">
                                    <CFormLabel htmlFor="hourly_price">
                                        <div className="edit-venue-form-label">Hourly Price</div>
                                    </CFormLabel>
                                    <div className="flex-row align-items-center">
                                        $ &nbsp; <CFormInput style={{width: "80px"}} type="number" name="hourly_price"
                                                             min="1"
                                                             defaultValue={venueDetail?.hourly_price}
                                                             onChange={updateForm}/>
                                    </div>
                                </div>
                                <div className="py-2">
                                    <CFormLabel htmlFor="cleaning">
                                        <div className="edit-venue-form-label">Cleaning Fee</div>
                                    </CFormLabel>
                                    <div className="flex-row align-items-center">
                                        $ &nbsp; <CFormInput style={{width: "80px"}} type="number" name="cleaning"
                                                             min="1"
                                                             defaultValue={venueDetail?.cleaning}
                                                             onChange={updateForm}/>
                                    </div>
                                </div>
                            </div> :
                            null
                        }

                        <div className="pt-3"><h4>Booking Restrictions</h4></div>
                        <div className="py-2">
                            <CFormLabel htmlFor="max_attendees">
                                <div className="edit-venue-form-label">Maximum Attendees</div>
                            </CFormLabel>
                            <div className="flex-row align-items-center">
                                <CFormInput style={{width: "70px"}} type="number" name="max_attendees" min="1"
                                            defaultValue={venueDetail?.max_attendees} onChange={updateForm}/>
                                &nbsp; attendees
                            </div>
                        </div>
                        <div className="py-2">
                            <CFormLabel>
                                <div className="edit-venue-form-label">Maximum Booking Time</div>
                            </CFormLabel>
                            <div className="flex-row align-items-center">
                                <span>Restricted by</span>
                                <div className="px-2">
                                    <CFormSelect name="use_absolute_date"
                                                 value={formData?.use_absolute_date ? String(formData.use_absolute_date) : "0"}
                                                 onChange={updateForm}>
                                        <option value="0">time span</option>
                                        <option value="1">absolute date</option>
                                    </CFormSelect>
                                </div>
                                {
                                    formData?.use_absolute_date === 1 ?
                                        <>
                                            <span>until &nbsp;</span>
                                            <div>
                                                <CFormInput name="until_date" type="date"
                                                            defaultValue={venueDetail?.until_date}
                                                            onChange={updateForm}/>
                                            </div>
                                        </> :
                                        <>
                                            <CFormInput style={{width: "70px"}} type="number" name="months_ahead"
                                                        min="1"
                                                        defaultValue={venueDetail?.months_ahead} onChange={updateForm}/>
                                            <span>&nbsp; months in advance</span>
                                        </>
                                }
                            </div>
                        </div>
                        <div className="py-2">
                            <CFormLabel htmlFor="days_before">
                                <div style={{fontWeight: 500}}>Minimum Booking Time</div>
                            </CFormLabel>
                            <div className="flex-row align-items-center">
                                <CFormInput style={{width: "70px"}} type="number" name="days_before" min="0"
                                            defaultValue={venueDetail?.days_before} onChange={updateForm}/>
                                &nbsp; days in advance
                            </div>
                        </div>
                        <div className="py-2">
                            <CFormLabel htmlFor="min_hours">
                                <div className="edit-venue-form-label">Minimum Time Per Booking</div>
                            </CFormLabel>
                            <div className="flex-row align-items-center">
                                <CFormInput style={{width: "70px"}} type="number" name="min_hours" min="1"
                                            defaultValue={venueDetail?.min_hours} onChange={updateForm}/>
                                &nbsp; hours
                            </div>
                        </div>
                        <div className="py-2">
                            <CFormLabel htmlFor="max_hours">
                                <div className="edit-venue-form-label">Maximum Time Per Booking</div>
                            </CFormLabel>
                            <div className="flex-row align-items-center">
                                <CFormInput style={{width: "70px"}} type="number" name="max_hours" min="1"
                                            defaultValue={venueDetail?.max_hours} onChange={updateForm}/>
                                &nbsp; hours
                            </div>
                        </div>
                        <div className="py-2">
                            <CFormLabel htmlFor="hours_between_events">
                                <div className="edit-venue-form-label">Minimum Hours Between Bookings</div>
                            </CFormLabel>
                            <div className="flex-row align-items-center">
                                <CFormInput style={{width: "70px"}} type="number" name="hours_between_events" min="0"
                                            step="0.5"
                                            defaultValue={venueDetail?.hours_between_events} onChange={updateForm}/>
                                &nbsp; hours
                            </div>
                        </div>
                        <div className="py-2">
                            <CFormLabel>
                                <div className="edit-venue-form-label">Available Times</div>
                            </CFormLabel>
                            <CCard>
                                <CCardBody className="p-3" style={{backgroundColor: "#f9f9f9"}}>
                                    {weekDays.map((weekday) => {
                                        const abbreviation = weekday.substring(0, 3).toLowerCase();
                                        return (
                                            <>
                                                <h6>{weekday}</h6>
                                                <div className={`flex-row pb-${weekday === "Saturday" ? 1 : 4}`}>
                                                    <div className="col-4 pe-2">
                                                        <CFormLabel htmlFor={`start_time_${abbreviation}`}>Start
                                                            Time</CFormLabel>
                                                        <TimePicker name={`start_time_${abbreviation}`}
                                                                    value={formData && formData[`start_time_${abbreviation}`]}
                                                                    onChange={updateFormTimePicker}/>
                                                    </div>
                                                    <div className="col-4 ps-2">
                                                        <CFormLabel htmlFor={`end_time_${abbreviation}`}>End
                                                            Time</CFormLabel>
                                                        <TimePicker name={`end_time_${abbreviation}`}
                                                                    value={formData && formData[`end_time_${abbreviation}`]}
                                                                    onChange={updateFormTimePicker}/>
                                                    </div>
                                                    <div
                                                        className="col-4 d-flex justify-content-center align-items-end">
                                                        <CButton color="link"
                                                                 style={{fontSize: "14px", height: "40px"}}
                                                                 onClick={() => {
                                                                     setFormData({
                                                                         ...formData,
                                                                         [`start_time_${abbreviation}`]: null,
                                                                         [`end_time_${abbreviation}`]: null,
                                                                     });
                                                                 }}
                                                        >
                                                            Make unavailable
                                                        </CButton>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    })}
                                </CCardBody>
                            </CCard>
                        </div>
                        <div className="pt-3"><h4>Actions</h4></div>
                        <div className="py-2 flex-row">
                            <CFormLabel htmlFor="hidden">
                                <div className="edit-venue-form-label pe-3">Hide Venue</div>
                            </CFormLabel>
                            <CFormSwitch name="hidden" checked={formData?.hidden} onChange={updateForm}/>
                        </div>
                        <div className="flex-row pt-3">
                            <div style={{width: "70px"}}>
                                <CButton type="submit" style={{backgroundColor: "#ff7e67", color: "white"}}
                                         onClick={submitForm}>Save</CButton>
                            </div>
                            <div className="flex-column">
                                <CButton color="secondary" type="button" variant="outline"
                                         role="button" onClick={() => navigate("/business/venues")}>Cancel</CButton>
                            </div>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        </div>
    );
}

export default EditVenue;
