import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {useEffect, useRef, useState} from "react";
import {
    CButton,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CFormInput,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilCalendar, cilCaretLeft, cilCaretRight, cilPlus} from "@coreui/icons";
import {useDispatch, useSelector} from "react-redux";
import {
    calenderData,
    getEventTags,
    getEventTypes,
    getProviderVenueListing,
    getUserProfile,
    updateManagerSettings,
    setAlert,
} from "../../../../redux/action/action";
import {dateToIsoString, dateToTimeString, weekDays} from "../../../../Utils/DateTimeUtils";
import "react-calendar/dist/Calendar.css";
import BookingModal from "./BookingModal";
import BlockTimeModal from "./BlockTimeModal";
import EditEventModal from "./EditEventModal";
import {bookingsToCalendarEvents} from "./FilterUtils";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import {users, managers} from "constants";
import ResourceCell from "./ResourceCell";
import {useNavigate} from "react-router-dom";

const ReservationCalendar = () => {
    document.title = "Event Calendar | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View and modify your reservations and events with DoubleSpot's business dashboard."
    );

    const blockTimeFormDefaultData = {repeat: false};
    const editEventFormDefaultData = {};
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const calendarRef = useRef(null);
    const currentBookings = useSelector(state => state.Apis.currentBooking);
    const venueListings = useSelector(state => state.Apis.providerVenuesListing);

    const userDetail = useSelector(state => state.Apis.userDetail);
    const confirmedEvent = useSelector(state => state.Apis.confirmedEvent);
    const declinedEvent = useSelector(state => state.Apis.declinedEvent);
    const deletedEvent = useSelector(state => state.Apis.deletedEvent);
    const blockedEvent = useSelector(state => state.Apis.bookingDetail);
    const updatedEvent = useSelector(state => state.Apis.updatedEvent);
    const updateEventSuccess = useSelector(state => state.Apis.updateEventSuccess);
    const businessEventTypes = useSelector(state => state.Apis.eventTypes);
    const eventTags = useSelector(state => state.Apis.eventTags);

    const [userData, setUserData] = useState({});
    const [calendarDate, setCalendarDate] = useState('');
    const [calendarTitle, setCalendarTitle] = useState('');
    const [calendarView, setCalendarView] = useState("dayGridMonth");
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [venueResources, setVenueResources] = useState([]);
    const [venues, setVenues] = useState([]);
    const [venueFilter, setVenueFilter] = useState({});
    const [eventTypeFilter, setEventTypeFilter] = useState({});
    const [bookings, setBookings] = useState([]);
    const [modalBooking, setModalBooking] = useState({});
    const [bookingModalVisible, setBookingModalVisible] = useState(false);
    const [blockTimeModalVisible, setBlockTimeModalVisible] = useState(false);
    const [blockTimeFormData, setBlockTimeFormData] = useState(blockTimeFormDefaultData);
    const [editEventModalVisible, setEditEventModalVisible] = useState(false);
    const [editEventFormData, setEditEventFormData] = useState({});
    const [allEventTags, setAllEventTags] = useState([]);
    const [dayView, setDayView] = useState("resourceTimelineDay");
    const [viewKey, setViewKey] = useState(0); // This is for forcing a re-render for calendar component

    const hasAccess = (accessName) => {
        return userData.role === users.ROLE_VENUE_OWNER || (userData.manager && userData.manager[accessName]);
    }

    const handleVenueCheckboxChange = (event, venue) => {
        const checked = event.target.checked;
        setVenueFilter({...venueFilter, [venue.id]: event.target.checked});
        if (checked) {
            setVenueResources([
                ...venueResources,
                {
                    id: venue.id,
                    title: venue.name,
                    order: venueResources.length,
                },
            ]);
        } else {
            const newVenueResources = venueResources;
            const removedVenue = newVenueResources.find(entry => entry.id === venue.id);
            newVenueResources.splice(removedVenue.order, 1);
            setVenueResources(newVenueResources.map((resource, index) => ({
                ...resource,
                order: index,
            })));
        }
    };

    const handleEventTypeCheckboxChange = (event, value) => {
        setEventTypeFilter({...eventTypeFilter, [value]: event.target.checked});
    };

    const handleEventClick = (clickInfo) => {
        const booking = bookings.find(booking => booking.id === clickInfo.event.extendedProps.modalId);
        setModalBooking(booking);
        setEditEventFormData({
            start_time: booking.start_time,
            end_time: booking.end_time,
            tags: booking.tags ? booking.tags.map(tag => ({
                label: tag.name,
                value: tag.id,
            })) : [],
        });
        setBookingModalVisible(true);
    }

    const prevButtonClick = () => {
        calendarRef.current.getApi().prev();
    }

    const nextButtonClick = () => {
        calendarRef.current.getApi().next();
    }

    const dateChangeClick = (event) => {
        const newDate = event.target.value;
        if (newDate) {
            calendarRef.current.getApi().gotoDate(event.target.value);
        }
    }

    const handleCalendarViewChange = (view) => {
        const currentDate = calendarRef.current.getApi().getDate();
        const isOnCurrentMonth = currentDate.getMonth() === (new Date()).getMonth() &&
            currentDate.getFullYear() === (new Date()).getFullYear();
        calendarRef.current.getApi().changeView(view);
        setCalendarView(view);
        if (view.endsWith("Day") && isOnCurrentMonth){
                calendarRef.current.getApi().today();
        }
    }

    const handleToDate = (date) => {
        calendarRef.current.getApi().changeView(dayView);
        setCalendarView(dayView);
        calendarRef.current.getApi().gotoDate(date);
    }

    const handleDateSelect = (selectInfo) => {
        setBlockTimeFormData({
            ...blockTimeFormDefaultData,
            date: selectInfo.startStr.split('T')[0],
            end_date: selectInfo.startStr.split('T')[0],
            start_time: calendarView.endsWith("Day") ? dateToTimeString(selectInfo.start) : "",
            end_time: calendarView.endsWith("Day") ? dateToTimeString(selectInfo.end) : "",
            venues: calendarView.endsWith("Day") ? [{
                label: selectInfo.resource.title,
                value: parseInt(selectInfo.resource.id),
            }] : [],
        });
        setBlockTimeModalVisible(true);
    }

    useEffect(() => {
        dispatch(getEventTags());
        setBlockTimeModalVisible(false);
    }, [blockedEvent]);

    useEffect(() => {
        if (!blockTimeModalVisible) {
            setBlockTimeFormData(blockTimeFormDefaultData);
        }
    }, [blockTimeModalVisible]);

    useEffect(() => {
        if (!editEventModalVisible) {
            setEditEventFormData(editEventFormDefaultData);
        }
    }, [blockTimeModalVisible]);

    useEffect(() => {
        dispatch(getProviderVenueListing());
        dispatch(getEventTags());
        dispatch(getUserProfile());
    }, []);

    useEffect(() => {
        setAllEventTags(eventTags);
    }, [eventTags]);

    useEffect(() => {
        if (userDetail?.role) {
            let businessId;
            if (userDetail?.role === users.ROLE_VENUE_OWNER) {
                businessId = userDetail?.id;
            } else if (userDetail?.role === users.ROLE_VENUE_MANAGER) {
                businessId = userDetail?.bussnessId;
            }
            dispatch(getEventTypes({
                businessId
            }));
        }
    }, [userDetail?.id]);

    useEffect(() => {
        setUserData(userDetail);
        let userDayView = "resourceTimelineDay";
        if (userDetail?.manager) {
            if (userDetail.manager.dayViewPreference === managers.VERTICAL) {
                userDayView = "resourceTimeGridDay";
            }
            setDayView(userDayView);
            if (userDetail.manager.defaultView === managers.DAY_VIEW) {
                setCalendarView(userDayView);
                calendarRef.current.getApi().changeView(userDayView);
                calendarRef.current.getApi().today();
            } else {
                setCalendarView("dayGridMonth");
                calendarRef.current.getApi().changeView("dayGridMonth");
            }
        }
    }, [userDetail]);

    useEffect(() => {
        setEventTypes([
            ...businessEventTypes,
            {
                id: 0,
                name: "Other",
                color: 0,
            }
        ]);
        const defaultEventTypeFilter = {};
        businessEventTypes.map(eventType => {
            defaultEventTypeFilter[eventType.id] = true;
        });
        // 0 is reserved for other
        defaultEventTypeFilter[0] = true;
        setEventTypeFilter(defaultEventTypeFilter);
    }, [businessEventTypes]);

    useEffect(() => {
        dispatch(calenderData({page: 1, venueId: false}));
    }, [confirmedEvent, declinedEvent, deletedEvent, blockedEvent, updatedEvent]);

    useEffect(() => {
        if (updateEventSuccess) {
            setModalBooking({
                ...modalBooking,
                ...updatedEvent,
            });
            dispatch(getEventTags());
            setEditEventModalVisible(false);
            const clearedEventFormData = {};
            if (updatedEvent.start_time) {
                clearedEventFormData.start_time = updatedEvent.start_time;
            }
            if (updatedEvent.end_time) {
                clearedEventFormData.end_time = updatedEvent.end_time;
            }
            setEditEventFormData({
                start_time: editEventFormData.start_time,
                end_time: editEventFormData.end_time,
                ...clearedEventFormData,
            });
        }
    }, [updatedEvent]);

    useEffect(() => {
        if (currentBookings) {
            setBookings(currentBookings);
            setCalendarEvents(bookingsToCalendarEvents(currentBookings, eventTypeFilter, venueFilter, hasAccess("acceptBookingAccess")));
        }
    }, [currentBookings, venues, venueFilter, eventTypeFilter]);

    useEffect(() => {
        setVenues(venueListings);
    }, [venueListings]);

    useEffect(() => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const onDatesSet = () => {
                setCalendarTitle(calendarApi.getCurrentData().viewTitle);
                setCalendarDate(dateToIsoString(calendarApi.getDate()));
            };

            // Initial setting
            onDatesSet();

            // Add listener for when dates change
            calendarApi.on('datesSet', onDatesSet);

            // Clean up the listener on component unmount
            return () => {
                calendarApi.off('datesSet', onDatesSet);
            };
        }
    }, [viewKey]);

    useEffect(() => {
        if (!userDetail?.role) return;
        if (!venueListings.length) return;

        if (userDetail?.role === users.ROLE_VENUE_OWNER) {
            setVenueResources(venueListings.map((venue, index) => ({
                id: venue.id,
                title: venue.name,
                order: index,
            })));
            const defaultFilter = {};
            venueListings.map(listing => {
                defaultFilter[listing.id] = true;
            })
            setVenueFilter(defaultFilter);
        } else {
            const venueIds = userDetail.manager.calendar_venue_ids.split(',').map(venueId => parseInt(venueId));
            setVenueResources(venueIds
                .map(venueId => venueListings.find(venue => venue.id === venueId))
                .filter(venue => venue?.id)
                .map((venue, index) => ({
                    id: venue.id,
                    title: venue.name,
                    order: index,
                }))
            );
            const defaultFilter = {};
            venueIds.map(venueId => {
                defaultFilter[venueId] = true;
            })
            setVenueFilter(defaultFilter);
        }
    }, [userDetail, venueListings]);

    useEffect(() => {
        // This will trigger re-render whenever resources change to ensure the look of resource cells in day timeline view
        setViewKey(prev => prev + 1);
        if (userDetail?.role === users.ROLE_VENUE_MANAGER && venueResources.length) {
            dispatch(updateManagerSettings({
                calendar_venue_ids: venueResources.map((venue) => venue.id).join(','),
            }));
        }
    }, [venueResources]);

    useEffect(() => {
        if (userDetail?.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.calendarAccess) {
                dispatch(setAlert('You do not have the permissions to calendar.','error'));
                navigate("/business");
            }
        }
    }, [userDetail]);

    return (
        <div className="d-flex flex-column w-100 p-3" style={{maxHeight: "100vh", backgroundColor: "white"}}>
            <div className="flex-row align-items-center justify-content-between pt-1 pb-3">
                <div className="flex-row">
                    <div className="pe-1">
                        <CButton color="light" onClick={prevButtonClick}><CIcon icon={cilCaretLeft}/></CButton>
                    </div>
                    <div className="ps-1">
                        <CButton color="light" onClick={nextButtonClick}><CIcon icon={cilCaretRight}/></CButton>
                    </div>
                    <div className="px-3">
                        <h3 className="mb-0">{calendarView.endsWith("Day") ? `${weekDays[calendarRef.current.getApi().getDate().getDay()]}, ` : ""}{calendarTitle}</h3>
                    </div>
                    {/*The following code are derived from https://dev.to/codeclown/styling-a-native-date-input-into-a-custom-no-library-datepicker-2in*/}
                    <div>
                        <CButton color="light" href="#"><CIcon icon={cilCalendar}/></CButton>
                        <CFormInput type="date" name="calendar_date" className="datepicker-input" value={calendarDate} onChange={dateChangeClick}/>
                    </div>
                </div>
                <div className="flex-row">
                    <CDropdown className="px-2">
                        <CDropdownToggle color="light">{calendarView.endsWith("Day") ? "Day" : "Month"}</CDropdownToggle>
                        <CDropdownMenu>
                            <CDropdownItem role="button"
                                           onClick={() => handleCalendarViewChange(dayView)}>Day</CDropdownItem>
                            <CDropdownItem role="button"
                                           onClick={() => handleCalendarViewChange("dayGridMonth")}>Month</CDropdownItem>
                        </CDropdownMenu>
                    </CDropdown>
                    {hasAccess("editEventAccess") ?
                        <div className="pe-2">
                            <CButton color="light" onClick={() => setBlockTimeModalVisible(true)}>
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Event
                            </CButton>
                        </div> :
                        null
                    }
                    <CDropdown autoClose="outside">
                        <CDropdownToggle color="light">Filters</CDropdownToggle>
                        <CDropdownMenu>
                            <div className="px-3 py-2" style={{width: "500px"}}>
                                <h6>Event Types</h6>
                                {
                                    eventTypes.map((eventType) => {
                                        return (
                                            <div className="form-check">
                                                <input type="checkbox" checked={eventTypeFilter[eventType.id]}
                                                       className={`form-check-input calendar-event-color-${eventType.color ? eventType.color : "gray"}`}
                                                       id={eventType.id}
                                                       onChange={(event) => handleEventTypeCheckboxChange(event, eventType.id)}/>
                                                <label className="check-form-label"
                                                       htmlFor={eventType.id}>{eventType.name}</label>
                                            </div>
                                        )
                                    })
                                }
                                <h6 className="pt-2">Venues</h6>
                                {
                                    venues.map((listing) => {
                                        return (
                                            <div className="form-check">
                                                <input type="checkbox" checked={venueFilter[listing.id]}
                                                       className="form-check-input primary-color"
                                                       id={listing.id}
                                                       onChange={(event) => handleVenueCheckboxChange(event, listing)}/>
                                                <label className="check-form-label"
                                                       htmlFor={listing.id}>{listing.name}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </CDropdownMenu>
                    </CDropdown>
                </div>
            </div>
            {calendarView.endsWith("Month") ?
                <div className="flex-row py-1">
                    {
                        weekDays.map(weekday => <div class="col-sm text-center" style={{fontWeight: 500}}>{weekday}</div>)
                    }
                </div> :
                null
            }
            <div className="flex-grow-1">
                <FullCalendar
                    key={viewKey}
                    dayHeaders={calendarView.endsWith("Day")}
                    dayMinWidth= {calendarView === "resourceTimeGridDay" ? 240 : 0}
                    dayMaxEvents={true}
                    editable={true}
                    eventClick={handleEventClick}
                    eventDurationEditable={false}
                    eventResourceEditable={false}
                    events={calendarEvents}
                    eventStartEditable={false}
                    fixedWeekCount={false}
                    headerToolbar={false}
                    height={calendarView === "resourceTimelineDay" ? "auto" : null}
                    initialView={calendarView}
                    initialDate={calendarDate || undefined}
                    navLinkDayClick={handleToDate}
                    navLinks={true}
                    nowIndicator={true}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin, resourceTimelinePlugin, scrollGridPlugin]}
                    ref={calendarRef}
                    resourceAreaHeaderContent="Venues"
                    resources={calendarView.endsWith("Day") ? venueResources : null}
                    resourceLabelContent={arg => <ResourceCell
                        resource={arg.resource}
                        calendarView={calendarView}
                        resources={venueResources}
                        setResources={setVenueResources}
                        venueFilter={venueFilter}
                        setVenueFilter={setVenueFilter}
                    />}
                    resourceOrder="order"
                    schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_LICENSE_KEY}
                    select={handleDateSelect}
                    selectable={hasAccess("editEventAccess")}
                    selectMirror={true}
                    slotMinWidth={calendarView === "resourceTimelineDay" ? 50: null}
                    weekends={true}
                />
            </div>
            <BookingModal
                visible={bookingModalVisible}
                onClose={() => setBookingModalVisible(false)}
                modalBooking={modalBooking}
                dispatch={dispatch}
                setBookingModalVisible={setBookingModalVisible}
                setEditEventModalVisible={setEditEventModalVisible}
                editEventAccess={hasAccess("editEventAccess")}
                acceptBookingAccess={hasAccess("acceptBookingAccess")}
            />
            {hasAccess("editEventAccess")?
                <>
                    <BlockTimeModal
                        visible={blockTimeModalVisible}
                        onClose={() => setBlockTimeModalVisible(false)}
                        blockTimeFormData={blockTimeFormData}
                        setBlockTimeFormData={setBlockTimeFormData}
                        dispatch={dispatch}
                        bookings={bookings}
                        eventTypes={eventTypes}
                        venues={venues}
                        eventTags={allEventTags}
                        setEventTags={setAllEventTags}
                        editEventAccess={hasAccess("editEventAccess")}
                    />
                    <EditEventModal
                        visible={editEventModalVisible}
                        onClose={() => {
                            setEditEventModalVisible(false);
                            setBookingModalVisible(true);
                        }}
                        editEventFormData={editEventFormData}
                        setEditEventFormData={setEditEventFormData}
                        dispatch={dispatch}
                        bookings={bookings}
                        eventTypes={eventTypes}
                        venues={venues}
                        eventTags={allEventTags}
                        setEventTags={setAllEventTags}
                        modalBooking={modalBooking}
                    />
                </> : null
            }
        </div>
    );
}

export default ReservationCalendar;
