import {call , put , takeEvery} from 'redux-saga/effects';
import {
    API_PACKAGES_FAIL,
    API_PACKAGES_SUCCESS,
    AUTH_RESET_PWD,
    AUTH_RESET_FAIL,
    AUTH_RESET_SUCCESS,
    BOOK_VENUE,
    BOOK_VENUE_FAIL,
    BOOK_VENUE_SUCCESS,
    CANCEL_BOOKING,
    CANCEL_BOOKING_FAIL,
    CANCEL_BOOKING_SUCCESS,
    BOOKING_PAYMENT_PROCESSED,
    BOOKING_PAYMENT_PROCESSED_FAIL,
    BOOKING_PAYMENT_PROCESSED_SUCCESS,
    BIZ_DELETE_EVENT,
    BIZ_DELETE_SUCCESS,
    BIZ_DELETE_FAIL,
    CONFIRM_EVENT,
    CONFIRM_EVENT_FAIL,
    CONFIRM_EVENT_SUCCESS,
    CALENDAR_DATA,
    CALENDAR_DATA_FAIL,
    CALENDAR_DATA_SUCCESS,
    BOOKING_SUCCESS_FALSE,
    MANAGE_STATUS_BOOKING,
    MANAGE_STATUS_BOOKING_FAIL,
    CURRENT_BOOKINGS,
    CURRENT_BOOKINGS_FAIL,
    CURRENT_BOOKINGS_SUCCESS,
    CUSTOMER_LIST,
    CUSTOMER_LIST_FAIL,
    CUSTOMER_LIST_SUCCESS,
    DEFAULT_URL,
    FORGOT_PWD,
    FORGOT_PWD_SUCCESS,
    FORGOT_PWD_FAIL,
    ALL_AMENITIES_FAIL,
    ALL_AMENITIES_SUCCESS,
    GET_PACKAGES_SUCCESS,
    GET_USER_PROFILE_FAIL,
    GET_USER_PROFILE_SUCCESS,
    GET_VENUES_LIST_ERROR,
    GET_VENUES_LIST_SUCCESS,
    getUserProfile,
    LIST_VENUES,
    setAlert,
    bookingSuccessFalse,
    RESET_PWD,
    RESET_PWD_FAIL,
    RESET_PWD_SUCCESS,
    SEND_VERIFICATION_EMAIL,
    SINGLE_VENUE_DETAIL,
    SINGLE_VENUE_ERROR,
    SINGLE_VENUE_SUCCESS,
    UPDATE_DETAIL_API_FAIL,
    UPDATE_DETAIL_API_SUCCESS,
    UPDATE_USER_DETAIL,
    UPDATE_BUSINESS_PROFILE,
    UPDATE_BUSINESS_PROFILE_SUCCESS,
    UPDATE_BUSINESS_PROFILE_FAIL,
    UPDATE_VENUE_DETAIL,
    UPDATE_VENUE_SUCCESS,
    UPDATE_VENUE_FAIL,
    USER_PROFILE,
    ALL_AMENITIES,
    GET_SEARCH_DATA,
    SEARCH_DATA_SUCCESS,
    SEARCH_DATA_FAIL,
    SAVE_RATING,
    SAVE_RATING_SUCCESS,
    SAVE_RATING_ERROR,
    ADD_WISHLIST,
    ADD_WISHLIST_SUCCESS,
    ADD_WISHLIST_ERROR,
    GET_WISHLIST,
    GET_WISHLIST_SUCCESS,
    GET_WISHLIST_ERROR,
    GET_LOCATION_ERROR,
    GET_LOCATION,
    REMOVE_FROM_WISHLIST,
    REMOVE_FROM_WISHLIST_FAIL,
    REMOVE_FROM_WISHLIST_SUCCESS,
    PROVIDER_VENUE_LISTING_FAIL,
    PROVIDER_VENUE_LISTING_SUCCESS,
    PROVIDER_VENUE_LISTING,
    CHAT_NOTES,
    CHAT_NOTES_SUCCESS,
    CHAT_NOTES_ERROR,
    OVERALLSTATUS,
    OVERALLSTATUS_FAIL,
    OVERALLSTATUS_SUCCESS,
    SLOT_ALREADY_EXIST_SUCCESS,
    SLOT_ALREADY_EXIST_FAIL,
    SLOT_ALREADY_EXIST,
    SINGLE_VENUE_REVIEW,
    SINGLE_VENUE_REVIEW_SUCCESS,
    SINGLE_VENUE_REVIEW_FAIL,
    GET_LOCATION_SUCCESS,
    EACH_VENUE_DETAIL,
    EACH_VENUE_DETAIL_SUCCESS,
    EACH_VENUE_DETAIL_FAIL,
    DRAFT_EVENT,
    DRAFT_EVENT_SUCCESS,
    DRAFT_EVENT_ERROR,
    GET_DRAFT_EVENT,
    GET_DRAFT_EVENT_SUCCESS,
    GET_DRAFT_EVENT_ERROR,
    APPLYCOUPON_STATUS,
    APPLYCOUPON_SUCCESS,
    APPLYCOUPON_FAIL,
    PAYMENTSUCCEED_STATUS,
    PAYMENTSUCCEED_SUCCESS,
    PAYMENTSUCCEED_FAIL,
    GET_NOTIFICATION,
    GET_NOTIFICATION_FAIL,
    GET_NOTIFICATION_SUCCESS,
    SAVE_NOTIFICATION,
    SAVE_NOTIFICATION_SUCCESS,
    SAVE_NOTIFICATION_FAIL,
    GET_ALL_DRAFT_VENUES,
    GET_ALL_DRAFT_VENUES_SUCCESS,
    GET_ALL_DRAFT_VENUES_FAIL,
    WALLET_SUCCESS,
    WALLET_FAIL,
    WALLET_STATUS,
    WALLET_HISTORY_STATUS,
    WALLET_HISTORY_SUCCESS,
    WALLET_HISTORY_FAIL,
    bankStatus,
    BANK_STATUS,
    BANK_FAIL,
    GET_NOTIFICAITON_LISTING,
    BANK_SUCCESS,
    SET_RESET_PWD_FALSE,
    SET_RESET_PWD_FALSE_SUCCESS,
    SET_RESET_PWD_FALSE_FAIL,
    DECLINE_EVENT,
    DECLINE_EVENT_SUCCESS,
    DECLINE_EVENT_FAIL,
    CALENDAR_RESET,
    CALENDAR_RESET_SUCCESS,
    UPDATE_EVENT_DETAIL,
    UPDATE_EVENT_SUCCESS,
    UPDATE_EVENT_FAIL,
    PUBLIC_CALENDAR_DATA,
    PUBLIC_PROVIDER_VENUE_LISTING,
    EVENT_TYPES_SUCCESS,
    EVENT_TYPES_FAIL,
    EVENT_TYPES,
    UPDATE_EVENT_TYPE,
    UPDATE_EVENT_TYPE_SUCCESS,
    UPDATE_EVENT_TYPE_FAIL,
    ADD_EVENT_TYPE,
    ADD_EVENT_TYPE_SUCCESS,
    ADD_EVENT_TYPE_FAIL,
    DELETE_EVENT_TYPE,
    GET_PROVIDER_PROFILE_SUCCESS,
    GET_PROVIDER_PROFILE_FAIL,
    PROVIDER_PROFILE,
    PUBLIC_PROVIDER_PROFILE,
    GET_MANAGERS_SUCCESS,
    GET_MANAGERS_FAIL,
    MANAGERS,
    UPDATE_MANAGER_ACCESS_SUCCESS,
    UPDATE_MANAGER_ACCESS_FAIL,
    UPDATE_MANAGER_ACCESS,
    UPDATE_MANAGER_SETTINGS_SUCCESS,
    UPDATE_MANAGER_SETTINGS_FAIL,
    UPDATE_MANAGER_SETTINGS,
    EMAIL_RESTRICTION,
    WHITELIST_SUCCESS,
    WHITELIST_FAIL,
    DOMAIN_SUCCESS,
    DOMAIN_FAIL,
    ADD_WHITELIST_ENTRY,
    ADD_WHITELIST_ENTRY_SUCCESS,
    ADD_WHITELIST_ENTRY_FAIL,
    DELETE_WHITELIST_ENTRY,
    DELETE_WHITELIST_ENTRY_SUCCESS,
    DELETE_WHITELIST_ENTRY_FAIL,
    BUSINESS_PLAN,
    GET_BUSINESS_PLAN_SUCCESS,
    GET_BUSINESS_PLAN_FAIL,
    EVENT_TAGS,
    EVENT_TAGS_SUCCESS,
    EVENT_TAGS_FAIL,
    UPDATE_EVENT_TAG,
    UPDATE_EVENT_TAG_SUCCESS,
    UPDATE_EVENT_TAG_FAIL,
    ADD_EVENT_TAG,
    ADD_EVENT_TAG_SUCCESS,
    ADD_EVENT_TAG_FAIL,
    DELETE_EVENT_TAG,
    RESERVATION_REPORTS_SUCCESS,
    RESERVATION_REPORTS_FAIL,
    RESERVATION_REPORTS,
    ADD_RESERVATION_REPORT_SUCCESS,
    ADD_RESERVATION_REPORT_FAIL,
    ADD_RESERVATION_REPORT,
    UPDATE_RESERVATION_REPORT_SUCCESS,
    UPDATE_RESERVATION_REPORT_FAIL,
    UPDATE_RESERVATION_REPORT,
    DELETE_RESERVATION_REPORT_SUCCESS,
    DELETE_RESERVATION_REPORT_FAIL,
    DELETE_RESERVATION_REPORT,
    MEMBERSHIP_TYPES_SUCCESS,
    MEMBERSHIP_TYPES_FAIL,
    MEMBERSHIP_TYPES,
    ADD_MEMBERSHIP_TYPE_SUCCESS,
    ADD_MEMBERSHIP_TYPE_FAIL,
    ADD_MEMBERSHIP_TYPE,
    UPDATE_MEMBERSHIP_TYPE_SUCCESS,
    UPDATE_MEMBERSHIP_TYPE_FAIL,
    UPDATE_MEMBERSHIP_TYPE,
    DELETE_MEMBERSHIP_TYPE_SUCCESS,
    DELETE_MEMBERSHIP_TYPE_FAIL,
    DELETE_MEMBERSHIP_TYPE,
    MEMBERSHIPS,
    MEMBERSHIPS_SUCCESS,
    MEMBERSHIPS_FAIL,
    ADD_MEMBERSHIPS_SUCCESS,
    ADD_MEMBERSHIPS_FAIL,
    UPDATE_MEMBERSHIP_SUCCESS,
    UPDATE_MEMBERSHIP_FAIL,
    DELETE_MEMBERSHIP_SUCCESS,
    DELETE_MEMBERSHIP_FAIL,
    ADD_MEMBERSHIPS,
    UPDATE_MEMBERSHIP,
    DELETE_MEMBERSHIP,
    IMPORT_MEMBERSHIPS_SUCCESS,
    IMPORT_MEMBERSHIPS_FAIL,
    IMPORT_MEMBERSHIPS,
    EVENT_DETAIL_SUCCESS,
    EVENT_DETAIL_FAIL,
    EVENT_DETAIL,
    UPDATE_TASK,
    UPDATE_TASK_FAIL,
    UPDATE_TASK_SUCCESS,
    DELETE_TASK_SUCCESS,
    DELETE_TASK_FAIL,
    ADD_TASK_SUCCESS,
    ADD_TASK_FAIL,
    ADD_TASK,
    DELETE_TASK,
    ADD_MANAGER_SUCCESS,
    ADD_MANAGER_FAIL,
    ADD_MANAGER,
    SEND_CONFIRMATION_EMAIL_SUCCESS,
    SEND_CONFIRMATION_EMAIL_FAIL,
    SEND_CONFIRMATION_EMAIL,
    MEMBERSHIP_TYPE_SUCCESS,
    MEMBERSHIP_TYPE_FAIL,
    MEMBERSHIP_TYPE,
    UPDATE_MEMBER_PRICE_SUCCESS,
    UPDATE_MEMBER_PRICE_FAIL,
    UPDATE_MEMBER_PRICE,
    ADD_MEMBER_PRICES,
    ADD_MEMBER_PRICES_SUCCESS,
    ADD_MEMBER_PRICES_FAIL,
    FORMS_SUCCESS,
    FORMS_FAIL,
    FORMS,
    FORM_SUCCESS,
    FORM_FAIL,
    FORM,
    DELETE_FORM_SUCCESS,
    DELETE_FORM_FAIL,
    DELETE_FORM,
    ADD_FORM,
    ADD_FORM_SUCCESS,
    ADD_FORM_FAIL,
    UPDATE_FORM_SUCCESS,
    UPDATE_FORM_FAIL,
    UPDATE_FORM,
} from '../action/action';
import {bookvenues} from "constants";
import {email_restrictions} from "constants";
import {users} from "constants";


function* RecomendedVenues(action) {
  try {
    const queryParams = new URLSearchParams(action.payload);
    const response = yield call(fetch, `${DEFAULT_URL}recomendedVenues?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = yield response.json();
    if(!data.success){
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: GET_VENUES_LIST_SUCCESS , data});
    
  } catch (error) {
    yield put({ type: GET_VENUES_LIST_ERROR, error });
  }
}

function* SingleVenueDetail(action) {
  try {
    const response = yield call(fetch, `${DEFAULT_URL}singleVenueDetail?venueId=${action.payload.venueId}&userId=${action?.payload?.userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = yield response.json();
    if(!data.success){
      yield console.log(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: SINGLE_VENUE_SUCCESS , data});
    
  } catch (error) {
    yield put({ type: SINGLE_VENUE_ERROR, error });
  }
}

function* SingleVenuePackages(action) {
  try {
    const response = yield call(fetch, `${DEFAULT_URL}singleVenuePackage?venueId=${action.payload.venueId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = yield response.json();
    if(!data.success){
      yield console.log("single packges hits error")
      // yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: API_PACKAGES_SUCCESS , data});
    
  } catch (error) {
    yield put({ type: API_PACKAGES_FAIL, error });
  }
}

function* GetProviderProfile(action){
    try {
      const authtoken = localStorage.getItem("token");  
      const queryParams = new URLSearchParams(action.payload);
        const response = yield call(fetch, `${DEFAULT_URL}providerProfile?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authtoken}`
            },
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: GET_PROVIDER_PROFILE_SUCCESS, data });
        } else {
            yield put({ type: GET_PROVIDER_PROFILE_FAIL, data });
        }
    } catch (error) {
        yield put({ type: GET_PROVIDER_PROFILE_FAIL, error });
    }
}

function* GetPublicProviderProfile(action){
  try {
      const queryParams = new URLSearchParams(action.payload);
      const response = yield call(fetch, `${DEFAULT_URL}publicProviderProfile?${queryParams}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
          },
      });
      const data = yield response.json();

      if (data.success) {
          yield put({ type: GET_PROVIDER_PROFILE_SUCCESS, data });
      } else {
          yield put({ type: GET_PROVIDER_PROFILE_FAIL, data });
      }
  } catch (error) {
      yield put({ type: GET_PROVIDER_PROFILE_FAIL, error });
  }
}

function* GetUserProfiles(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}userProfile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    if(!data.success){
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: GET_USER_PROFILE_SUCCESS , data});
    
  } catch (error) {
    yield put({ type: GET_USER_PROFILE_FAIL, error });
    yield put(setAlert("User profile api hits error form server side", 'error'));
  }
}

function* updateUserProfile(action) {
  try {
    const {
        email,
        name,
        phone,
        current_password,
        new_password,
        confirm_new_password,
    } = action.payload;

    const setSubmitData = new FormData();
    setSubmitData.append('email', email);
    setSubmitData.append('name', name);
    setSubmitData.append('phone', phone);
    if (current_password) {
        setSubmitData.append('current_password', current_password);
    }
      if (new_password) {
          setSubmitData.append('new_password', new_password);
      }
      if (confirm_new_password) {
          setSubmitData.append('confirm_new_password', confirm_new_password);
      }
    if (action.payload.images) {
      setSubmitData.append('images', action.payload.images); // Append 'image' field with the selected file
    }

    // console.log("check the payload ========>>>>>", JSON.stringify(setSubmitData, null, '\t'));

    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}updateUserProfile`, {
      method: 'PUT',
      body: setSubmitData, // Pass the FormData object as the request body
      headers: {
        "Authorization": `Bearer ${authtoken}`
      }
    });

    const data = yield response.json();

    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return;
    }

    yield put({ type: UPDATE_DETAIL_API_SUCCESS, data });
    yield put(setAlert('Profile updated.', 'success'));
    yield put(getUserProfile());
    if (data.body.verify_account == 0) {
      yield put({ type: SEND_VERIFICATION_EMAIL, payload: { email: data.body.email, role: data.body.role } })
    }
  } catch (error) {
    yield put({ type: UPDATE_DETAIL_API_FAIL, error });
    yield put(setAlert('Profile update failed.', 'error'));
  }
}

function* updateBusinessProfile(action) {
    try {
      const setSubmitData = new FormData();
      const fields = ['bussnessId', 'email', 'name', 'phone', 'feedback_form_link'];
      for (const field of fields) {
        setSubmitData.append(field, action.payload[field]);
      }
      const images = ['profile_image', 'images', 'brand_logo'];
      for (const image of images) {
        if (action.payload.profile_image) {
          setSubmitData.append(image, action.payload[image]);
        }
      }

      const authtoken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}updateBusinessProfile`, {
        method: 'POST',
        body: setSubmitData,
        headers: {
            "Authorization": `Bearer ${authtoken}`
        }
    });

    const data = yield response.json();

    if (data.success) {
        yield put({ type: UPDATE_BUSINESS_PROFILE_SUCCESS, data });
        yield put(setAlert('Business profile updated.', 'success'));
    } else {
        yield put({ type: UPDATE_BUSINESS_PROFILE_FAIL, data });
        yield put(setAlert(data.msg, 'error'));
    }
    } catch (error) {
        yield put({ type: UPDATE_BUSINESS_PROFILE_FAIL, error });
        yield put(setAlert('Business profile update failed.', 'error'));
    }
}

function* GetBusinessPlan(action) {
    try {
        const authtoken = localStorage.getItem("token");
        const queryParams = new URLSearchParams(action.payload)
        const response = yield call(fetch, `${DEFAULT_URL}getBusinessPlan?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authtoken}`
            }
        });
        const data = yield response.json();
        if (!data.success) {
            yield put(setAlert(data.msg, 'error'));
            return
        }
        yield put({ type: GET_BUSINESS_PLAN_SUCCESS, data });
    } catch (error) {
        yield put({ type: GET_BUSINESS_PLAN_FAIL, error });
    }
}

function* GetManagers(action) {
    try {
        const authtoken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}getManagers`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authtoken}`
            }
        });
        const data = yield response.json();
        if (!data.success) {
            yield put(setAlert(data.msg, 'error'));
            return
        }
        yield put({ type: GET_MANAGERS_SUCCESS, data });

    } catch (error) {
        yield put({ type: GET_MANAGERS_FAIL, error });
    }
}

function* addManager(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}addManager`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: ADD_MANAGER_SUCCESS, data });
            yield put(setAlert('Team member added successfully. An email containing their temporary credentials has been sent to their inbox.', 'success'));
            yield put({ type: SEND_VERIFICATION_EMAIL, payload: { email: data.body.email, role: users.ROLE_VENUE_MANAGER, toOther: true } });
        } else {
            yield put({ type: ADD_MANAGER_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: ADD_MANAGER_FAIL, error });
        yield put(setAlert('Failed to add team member.', 'error'));
    }
}

function* updateManagerAccess(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}updateManagerAccess`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: UPDATE_MANAGER_ACCESS_SUCCESS, data });
            yield put(setAlert('Team member access updated successfully', 'success'));
        } else {
            yield put({ type: UPDATE_MANAGER_ACCESS_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: UPDATE_MANAGER_ACCESS_FAIL, error });
        yield put(setAlert('Team member access update failed.', 'error'));
    }
}

function* updateManagerSettings(action) {
  try {
      const authToken = localStorage.getItem("token");
      const response = yield call(fetch, `${DEFAULT_URL}updateManagerSettings`, {
          method: 'POST',
          body: JSON.stringify(action.payload),
          headers: {
              'Content-Type': 'application/json',
              "Authorization": `Bearer ${authToken}`
          }
      });
      const data = yield response.json();

      if (data.success) {
          yield put({ type: UPDATE_MANAGER_SETTINGS_SUCCESS, data });
          if (!action.payload.calendar_venue_ids) {
              yield put(setAlert('User settings updated successfully', 'success'));
          }
      } else {
          yield put({ type: UPDATE_MANAGER_SETTINGS_FAIL, data });
          yield put(setAlert(data.msg, 'error'));
      }
  } catch (error) {
      yield put({ type: UPDATE_MANAGER_SETTINGS_FAIL, error });
      yield put(setAlert('User Settings update failed.', 'error'));
  }
}

function* updateVenueDetail(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const setSubmitData = new FormData();
    for (const key in action.payload) {
        if (action.payload[key] === null) continue;
        if (key === "images") {
            setSubmitData.append("imageList", action.payload[key].map(image => image.src));
        } else {
            setSubmitData.append(key, action.payload[key]);
        }
    }
    if (action.payload.images) {
        for (const i in action.payload.images) {
            const image = action.payload.images[i];
            if (image.file) {
                setSubmitData.append(image.src, image.file);
            }
        }
    }
    const response = yield call(fetch, `${DEFAULT_URL}updateVenueDetail`, {
      method: 'POST',
      body: setSubmitData,
      headers: {
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    
    if (!data.success) {
      yield put({ type: UPDATE_VENUE_FAIL, data });
      yield put(setAlert(data.msg, 'error'));
      return;
    }

    yield put({ type: UPDATE_VENUE_SUCCESS, data });
    yield put(setAlert('Venue updated successfully.', 'success'));

  } catch (error) { 
    yield put({ type: UPDATE_VENUE_FAIL, error });
    yield put(setAlert('Venue update failed.', 'error'));
  }
}

function* GetAllAmenities(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}allAmenities`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const data = yield response.json();
    if(!data.success){
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: ALL_AMENITIES_SUCCESS , data});
  } catch (error) {
    yield put({ type: ALL_AMENITIES_FAIL, error });
  }
}

function* BookVenue(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}bookVenue`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();

    if (data.success) {
      const { savebookVenue, autoConfirm } = data.body;
      if (autoConfirm && !savebookVenue.payment_intent_id) {
          yield put({ type: CONFIRM_EVENT, payload: { id: savebookVenue.id, payment_intent_id: savebookVenue.payment_intent_id }});
      } else if (savebookVenue?.status === bookvenues.STATUS_PENDING) {
          yield put(setAlert('Request Submitted', 'success'));
      }
      yield put({ type: BOOK_VENUE_SUCCESS, data });
    } else {
      yield put({ type: BOOK_VENUE_FAIL, data });
      yield put(setAlert(data.msg, 'error'));
    }
  } catch (error) {
    yield put({ type: BOOK_VENUE_FAIL, error });
    yield put(setAlert('Booking failed.', 'error'));
  }
}

function* CancelBooking(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}cancelBooking`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    
    if (data.success) {
      yield put({ type: CANCEL_BOOKING_SUCCESS, data });
    } else {
      yield put({ type: CANCEL_BOOKING_FAIL, data });
      yield put(setAlert(data.msg, 'error'));
    }
  } catch (error) {
    yield put({ type: CANCEL_BOOKING_FAIL, error });
    yield put(setAlert('Cancel Booking failed.', 'error'));
  }
}

function* BookingPaymentProcessed(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}bookingPaymentProcessed`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    
    if (data.success) {
      yield put({ type: BOOKING_PAYMENT_PROCESSED_SUCCESS, data });
      yield put(setAlert('Request Submitted', 'success'));
    } else {
      yield put({ type: BOOKING_PAYMENT_PROCESSED_FAIL, data });
      yield put(setAlert(data.msg, 'error'));
    }
  } catch (error) {
    yield put({ type: BOOKING_PAYMENT_PROCESSED_FAIL, error });
    yield put(setAlert('Update processing booking status failed.', 'error'));
  }
}

function* getEventDetail(action) {
    try {
        const authtoken = localStorage.getItem("token");
        const queryParams = new URLSearchParams(action.payload);
        const response = yield call(fetch, `${DEFAULT_URL}getEventDetail?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authtoken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({type: EVENT_DETAIL_SUCCESS, data});
        } else {
            yield put({ type: EVENT_DETAIL_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: EVENT_DETAIL_FAIL, error });
    }
}

function* updateEventDetail(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}updateEventDetail`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: UPDATE_EVENT_SUCCESS, data });
            // This API also handles bulk deletion of internal events.
            yield put(setAlert(`Event ${action.payload.deleted ? "deleted" : "updated"} successfully`, 'success'));
        } else {
            yield put({ type: UPDATE_EVENT_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: UPDATE_EVENT_FAIL, error });
        yield put(setAlert('Event update failed.', 'error'));
    }
}

function* bizDeleteEvent(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}bizDeleteEvent`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: BIZ_DELETE_SUCCESS, data });
    yield put(setAlert('Event Removed', 'success'));

  } catch (error) { 
    yield put({ type: BIZ_DELETE_FAIL, error });
    yield put(setAlert('An Error Occured', 'error'));
  }
}

function* confirmEvent(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}confirmEvent`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: CONFIRM_EVENT_SUCCESS, data });
    yield put(setAlert('Event Confirmed', 'success'));

  } catch (error) { 
    yield put({ type: CONFIRM_EVENT_FAIL, error });
    yield put(setAlert('An error occurred. Please try again.', 'error'));
  }
}

function* declineEvent(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}declineEvent`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: DECLINE_EVENT_SUCCESS, data });
    yield put(setAlert('Event Declined', 'success'));

  } catch (error) {
    yield put({ type: DECLINE_EVENT_FAIL, error });
    yield put(setAlert('An Error Occured', 'error'));
  }
}

function* sendConfirmationEmail(action) {
    try {
        const authtoken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}sendConfirmationEmail`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authtoken}`
            }
        });
        const data = yield response.json();

        if (!data.success) {
            yield put(setAlert(data.msg, 'error'));
            return
        }

        yield put({ type: SEND_CONFIRMATION_EMAIL_SUCCESS, data });
        yield put(setAlert('Confirmation email sent.', 'success'));

    } catch (error) {
        yield put({ type: SEND_CONFIRMATION_EMAIL_FAIL, error });
        yield put(setAlert('Confirmation email send failed.', 'error'));
    }
}

function* addTask(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}addTask`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: ADD_TASK_SUCCESS, data });
            yield put(setAlert("Task added successfully.", 'success'));
        } else {
            yield put({ type: ADD_TASK_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: ADD_TASK_FAIL, error });
        yield put(setAlert('Failed to add task.', 'error'));
    }
}

function* updateTask(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}updateTask`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: UPDATE_TASK_SUCCESS, data });
            yield put(setAlert(`Task ${action.payload.status ? "status" : ""} updated successfully`, 'success'));
        } else {
            yield put({ type: UPDATE_TASK_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: UPDATE_TASK_FAIL, error });
        yield put(setAlert('Task update failed.', 'error'));
    }
}

function* deleteTask(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}updateTask`, {
            method: 'POST',
            body: JSON.stringify({
                id: action.payload.id,
                deleted: 1,
            }),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: DELETE_TASK_SUCCESS, data });
            yield put(setAlert('Task deleted successfully.', 'success'));
        } else {
            yield put({ type: DELETE_TASK_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: DELETE_TASK_FAIL, error });
        yield put(setAlert('Task deletion failed.', 'error'));
    }
}

function* calendarReset(action) {
  yield put({ type: CALENDAR_RESET_SUCCESS,  }); 
}

function* sendVerificationEmail(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}sendVerificationEmail`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    if(!data.success){
      yield console.log(setAlert(data.msg, 'error'));
      return;
    }
    if (!action.payload.toOther) {
        yield put(setAlert('An email verification link has been sent to your email inbox.', 'success'));
    }
  } catch (error) {
    yield put(setAlert('An error occurred. Please try again.', 'error'));
  }
}

function* forgotPwd(action) {
  try {
    const response = yield call(fetch, `${DEFAULT_URL}forgotPwd`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = yield response.json();
    if(!data.success){
      yield console.log(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: FORGOT_PWD_SUCCESS , data});
    yield put(setAlert('A password recovery link has been sent to your email if a user account exists under your email.', 'success'));
    
  } catch (error) {
    yield put({ type: FORGOT_PWD_FAIL, error });
    yield put(setAlert('An error occurred. Please try again.', 'error'));
  }
}

function* authResetPwd(action) {
  try {
    const response = yield call(fetch, `${DEFAULT_URL}authResetPwd?id=${action.payload.id}&link=${action?.payload?.link}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = yield response.json();
    if(!data.success){
      yield console.log(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: AUTH_RESET_SUCCESS , data});
    
  } catch (error) {
    yield put({ type: AUTH_RESET_FAIL, error });
  }
}

function* resetPwd(action) {
  try {
    const response = yield call(fetch, `${DEFAULT_URL}resetPwd`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = yield response.json();
    if(!data.success){
      yield console.log(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: RESET_PWD_SUCCESS , data});
    // yield put(setAlert('A password recovery link has been sent to your email if a user account exists under your email.', 'success'));
    
  } catch (error) {
    yield put({ type: RESET_PWD_FAIL, error });
    yield put(setAlert('An error occurred. Please try again.', 'error'));
  }
}

function* setResetPwdFalse(action) {
  try {
    yield put({ type: SET_RESET_PWD_FALSE_SUCCESS });
    
  } catch (error) {
    yield put({ type: SET_RESET_PWD_FALSE_FAIL, error });
    yield put(setAlert('An error occurred. Please try again.', 'error'));
  }
}

function* GetCurrentBookings(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const queryParams = new URLSearchParams(action.payload)
    const response = yield call(fetch, `${DEFAULT_URL}currentBookings?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: CURRENT_BOOKINGS_SUCCESS, data });

  } catch (error) {
    yield put({ type: CURRENT_BOOKINGS_FAIL, error });
  }
}

function* GetCustomerList(action) {
  try {
    //const authtoken = localStorage.getItem("token");
    const queryParams = new URLSearchParams(action.payload)
    console.log("---------" + queryParams)
    const response = yield call(fetch, `${DEFAULT_URL}getCustomerList?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        //"Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: CUSTOMER_LIST_SUCCESS, data });

  } catch (error) {
    yield put({ type: CUSTOMER_LIST_FAIL, error });
  }
}

function* GetCalenderData(action){
  try {
    const authtoken = localStorage.getItem("token");
    const queryParams = new URLSearchParams(action.payload)
    const response = yield call(fetch, `${DEFAULT_URL}currentBookingProvider?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: CALENDAR_DATA_SUCCESS, data });

  } catch (error) {
    yield put({ type: CALENDAR_DATA_FAIL, error });
  }
}

function* GetPublicCalendarData(action){
    try {
        const queryParams = new URLSearchParams(action.payload);
        const response = yield call(fetch, `${DEFAULT_URL}publicCalendarData?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = yield response.json();

        if (!data.success) {
            yield put(setAlert(data.msg, 'error'));
            return
        }

        yield put({ type: CALENDAR_DATA_SUCCESS, data });
    } catch (error) {
        yield put({ type: CALENDAR_DATA_FAIL, error });
    }
}

function* GetReservationReports(action) {
    try {
        const authtoken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}getReservationReports`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authtoken}`
            }
        });
        const data = yield response.json();
        if (!data.success) {
            yield put(setAlert(data.msg, 'error'));
            return;
        }
        yield put({ type: RESERVATION_REPORTS_SUCCESS, data });

    } catch (error) {
        yield put({ type: RESERVATION_REPORTS_FAIL, error });
    }
}

function* addReservationReport(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}addReservationReport`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: ADD_RESERVATION_REPORT_SUCCESS, data });
            yield put(setAlert('Event report added successfully.', 'success'));
        } else {
            yield put({ type: ADD_RESERVATION_REPORT_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: ADD_RESERVATION_REPORT_FAIL, error });
        yield put(setAlert('Failed to add event report.', 'error'));
    }
}

function* updateReservationReport(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}updateReservationReport`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: UPDATE_RESERVATION_REPORT_SUCCESS, data });
            yield put(setAlert('Event report updated successfully.', 'success'));
        } else {
            yield put({ type: UPDATE_RESERVATION_REPORT_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: UPDATE_RESERVATION_REPORT_FAIL, error });
        yield put(setAlert('Failed to update Event report.', 'error'));
    }
}

function* deleteReservationReport(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}deleteReservationReport`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: DELETE_RESERVATION_REPORT_SUCCESS, data });
            yield put(setAlert('Event report deleted successfully.', 'success'));
        } else {
            yield put({ type: DELETE_RESERVATION_REPORT_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: DELETE_RESERVATION_REPORT_FAIL, error });
        yield put(setAlert('Failed to delete event report.', 'error'));
    }
}

function* getMembershipTypes(action){
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}membershipTypes`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (!data.success) {
            yield put(setAlert(data.msg, 'error'));
            return;
        }
        yield put({ type: MEMBERSHIP_TYPES_SUCCESS, data });
    } catch (error) {
        yield put({ type: MEMBERSHIP_TYPES_FAIL, error });
    }
}

function* getMembershipType(action){
    try {
        const queryParams = new URLSearchParams(action.payload);
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}membershipType?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (!data.success) {
            yield put({ type: MEMBERSHIP_TYPE_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
            return;
        }
        yield put({ type: MEMBERSHIP_TYPE_SUCCESS, data });
    } catch (error) {
        yield put({ type: MEMBERSHIP_TYPE_FAIL, error });
        yield put(setAlert("Failed to find membership type.", 'error'));
    }
}

function* addMembershipType(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}addMembershipType`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: ADD_MEMBERSHIP_TYPE_SUCCESS, data });
            yield put(setAlert('Membership type added successfully.', 'success'));
        } else {
            yield put({ type: ADD_MEMBERSHIP_TYPE_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: ADD_MEMBERSHIP_TYPE_FAIL, error });
        yield put(setAlert('Failed to add membership type.', 'error'));
    }
}

function* updateMembershipType(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}updateMembershipType`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: UPDATE_MEMBERSHIP_TYPE_SUCCESS, data });
            yield put(setAlert('Membership type updated successfully.', 'success'));
        } else {
            yield put({ type: UPDATE_MEMBERSHIP_TYPE_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: UPDATE_MEMBERSHIP_TYPE_FAIL, error });
        yield put(setAlert('Failed to update membership type.', 'error'));
    }
}

function* deleteMembershipType(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}deleteMembershipType`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: DELETE_MEMBERSHIP_TYPE_SUCCESS, data });
            yield put(setAlert('Membership type deleted successfully.', 'success'));
        } else {
            yield put({ type: DELETE_MEMBERSHIP_TYPE_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: DELETE_MEMBERSHIP_TYPE_FAIL, error });
        yield put(setAlert('Failed to delete membership type.', 'error'));
    }
}

function* getMemberships(action){
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}memberships`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (!data.success) {
            yield put(setAlert(data.msg, 'error'));
            return;
        }
        yield put({ type: MEMBERSHIPS_SUCCESS, data });
    } catch (error) {
        yield put({ type: MEMBERSHIPS_FAIL, error });
    }
}

function* addMembershipEntries(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}addMembershipEntries`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: ADD_MEMBERSHIPS_SUCCESS, data });
            yield put(setAlert('Membership entries added successfully.', 'success'));
        } else {
            yield put({ type: ADD_MEMBERSHIPS_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: ADD_MEMBERSHIPS_FAIL, error });
        yield put(setAlert('Failed to add membership entries.', 'error'));
    }
}

function* importMembershipEntries(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}importMembershipEntries`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: IMPORT_MEMBERSHIPS_SUCCESS, data });
            yield put(setAlert('Membership entries imported successfully.', 'success'));
        } else {
            yield put({ type: IMPORT_MEMBERSHIPS_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: IMPORT_MEMBERSHIPS_FAIL, error });
        yield put(setAlert('Failed to import membership entries.', 'error'));
    }
}

function* updateMembershipEntry(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}updateMembershipEntry`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: UPDATE_MEMBERSHIP_SUCCESS, data });
            yield put(setAlert('Membership entry updated successfully.', 'success'));
        } else {
            yield put({ type: UPDATE_MEMBERSHIP_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: UPDATE_MEMBERSHIP_FAIL, error });
        yield put(setAlert('Failed to update membership entry.', 'error'));
    }
}

function* deleteMembershipEntry(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}deleteMembershipEntry`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: DELETE_MEMBERSHIP_SUCCESS, data });
            yield put(setAlert('Membership entry deleted successfully.', 'success'));
        } else {
            yield put({ type: DELETE_MEMBERSHIP_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: DELETE_MEMBERSHIP_FAIL, error });
        yield put(setAlert('Failed to delete membership entry.', 'error'));
    }
}

function* addMemberPrices(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}addMemberPrices`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: ADD_MEMBER_PRICES_SUCCESS, data });
            yield put(setAlert('Member pricing added successfully.', 'success'));
        } else {
            yield put({ type: ADD_MEMBER_PRICES_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: ADD_MEMBER_PRICES_FAIL, error });
        yield put(setAlert('Failed to add member pricing.', 'error'));
    }
}

function* updateMemberPrice(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}updateMemberPrice`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: UPDATE_MEMBER_PRICE_SUCCESS, data });
            yield put(setAlert(`Member pricing ${action.payload.deleted ? "deleted" : "updated"} successfully.`, 'success'));
        } else {
            yield put({ type: UPDATE_MEMBER_PRICE_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: UPDATE_MEMBER_PRICE_FAIL, error });
        yield put(setAlert(`Failed to ${action.payload.deleted ? "delete" : "update"} member pricing.`, 'error'));
    }
}

function* getEmailRestriction(action){
    try {
        const queryParams = new URLSearchParams(action.payload);
        //console.log(queryParams);
        const response = yield call(fetch, `${DEFAULT_URL}emailRestriction?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = yield response.json();

        //console.log(data);

        if (!data.success) {
            yield put(setAlert(data.msg, 'error'));
            return
        }
        if (action.payload.type === email_restrictions.TYPE_DOMAIN_RESTRICTION) {
          yield put({ type: DOMAIN_SUCCESS, data });
        }
        else {
          yield put({ type: WHITELIST_SUCCESS, data });
        }
        
    } catch (error) {
        if (action.payload.type === email_restrictions.TYPE_DOMAIN_RESTRICTION) {
          yield put({ type: DOMAIN_FAIL, error });
        }
        else {
          yield put({ type: WHITELIST_FAIL, error });
        }
    }
}

function* addEmailRestrictionEntry(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}addEmailRestrictionEntry`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: ADD_WHITELIST_ENTRY_SUCCESS, data });
            yield put(setAlert('Entry added successfully', 'success'));
        } else {
            yield put({ type: ADD_WHITELIST_ENTRY_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: ADD_EVENT_TYPE_FAIL, error });
        yield put(setAlert('Failed to add Email Restriction entry.', 'error'));
    }
}

function* deleteEmailRestrictionEntry(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}deleteEmailRestrictionEntry`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: DELETE_WHITELIST_ENTRY_SUCCESS, data });
            yield put(setAlert('Email Restriction entry deleted successfully', 'success'));
        } else {
            yield put({ type: DELETE_WHITELIST_ENTRY_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: DELETE_WHITELIST_ENTRY_FAIL, error });
        yield put(setAlert('Failed to delete Email Restriction entry.', 'error'));
    }
}

function* GetEventTypes(action){
    try {
        const queryParams = new URLSearchParams(action.payload);
        const response = yield call(fetch, `${DEFAULT_URL}eventTypes?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const data = yield response.json();

        if (!data.success) {
            yield put(setAlert(data.msg, 'error'));
            return
        }

        yield put({ type: EVENT_TYPES_SUCCESS, data });
    } catch (error) {
        yield put({ type: EVENT_TYPES_FAIL, error });
    }
}

function* updateEventTypeDetail(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}updateEventTypeDetail`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: UPDATE_EVENT_TYPE_SUCCESS, data });
            yield put(setAlert('Event type updated successfully', 'success'));
        } else {
            yield put({ type: UPDATE_EVENT_TYPE_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: UPDATE_EVENT_TYPE_FAIL, error });
        yield put(setAlert('Event type update failed.', 'error'));
    }
}

function* addEventType(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}addEventType`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: ADD_EVENT_TYPE_SUCCESS, data });
            yield put(setAlert('Event type added successfully', 'success'));
        } else {
            yield put({ type: ADD_EVENT_TYPE_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: ADD_EVENT_TYPE_FAIL, error });
        yield put(setAlert('Failed to add event type.', 'error'));
    }
}

function* deleteEventType(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}deleteEventType`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: ADD_EVENT_TYPE_SUCCESS, data });
            yield put(setAlert('Event type deleted successfully', 'success'));
        } else {
            yield put({ type: ADD_EVENT_TYPE_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: ADD_EVENT_TYPE_FAIL, error });
        yield put(setAlert('Failed to delete event type.', 'error'));
    }
}

function* GetEventTags(action){
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}eventTags`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (!data.success) {
            yield put(setAlert(data.msg, 'error'));
            return
        }

        yield put({ type: EVENT_TAGS_SUCCESS, data });
    } catch (error) {
        yield put({ type: EVENT_TAGS_FAIL, error });
    }
}

function* updateEventTagDetail(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}updateEventTagDetail`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: UPDATE_EVENT_TAG_SUCCESS, data });
            yield put(setAlert('Event tag updated successfully', 'success'));
        } else {
            yield put({ type: UPDATE_EVENT_TAG_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: UPDATE_EVENT_TAG_FAIL, error });
        yield put(setAlert('Event tag update failed.', 'error'));
    }
}

function* addEventTag(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}addEventTag`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: ADD_EVENT_TAG_SUCCESS, data });
            yield put(setAlert('Event tag added successfully', 'success'));
        } else {
            yield put({ type: ADD_EVENT_TAG_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: ADD_EVENT_TAG_FAIL, error });
        yield put(setAlert('Failed to add event tag.', 'error'));
    }
}

function* deleteEventTag(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}deleteEventTag`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: ADD_EVENT_TAG_SUCCESS, data });
            yield put(setAlert('Event tag deleted successfully', 'success'));
        } else {
            yield put({ type: ADD_EVENT_TAG_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: ADD_EVENT_TAG_FAIL, error });
        yield put(setAlert('Failed to delete event tag.', 'error'));
    }
}

function* GetForms(action){
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}forms`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (!data.success) {
            yield put(setAlert(data.msg, 'error'));
            return
        }

        yield put({ type: FORMS_SUCCESS, data });
    } catch (error) {
        yield put({ type: FORMS_FAIL, error });
    }
}

function* GetForm(action){
    try {
        const authtoken = localStorage.getItem("token");
        const queryParams = new URLSearchParams(action.payload);
        const response = yield call(fetch, `${DEFAULT_URL}form?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authtoken}`
            },
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: FORM_SUCCESS, data });
        } else {
            yield put({ type: FORM_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: FORM_FAIL, error });
        yield put(setAlert("Failed to get form information.", 'error'));
    }
}

function* addForm(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}addForm`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: ADD_FORM_SUCCESS, data });
            yield put(setAlert('Form added successfully', 'success'));
        } else {
            yield put({ type: ADD_FORM_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: ADD_FORM_FAIL, error });
        yield put(setAlert('Failed to add form.', 'error'));
    }
}

function* updateForm(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}updateForm`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: UPDATE_FORM_SUCCESS, data });
            yield put(setAlert('Form updated successfully', 'success'));
        } else {
            yield put({ type: UPDATE_FORM_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: UPDATE_FORM_FAIL, error });
        yield put(setAlert('Failed to update form.', 'error'));
    }
}

function* deleteForm(action) {
    try {
        const authToken = localStorage.getItem("token");
        const response = yield call(fetch, `${DEFAULT_URL}deleteForm`, {
            method: 'POST',
            body: JSON.stringify(action.payload),
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authToken}`
            }
        });
        const data = yield response.json();

        if (data.success) {
            yield put({ type: DELETE_FORM_SUCCESS, data });
            yield put(setAlert('Form deleted successfully', 'success'));
        } else {
            yield put({ type: DELETE_FORM_FAIL, data });
            yield put(setAlert(data.msg, 'error'));
        }
    } catch (error) {
        yield put({ type: DELETE_FORM_FAIL, error });
        yield put(setAlert('Failed to delete form.', 'error'));
    }
}

function* GetSeachDataForVenue(action){
  try {
    const queryParams = new URLSearchParams(action.payload)
    const response = yield call(fetch, `${DEFAULT_URL}searchVenueByName?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const data = yield response.json();

    yield put({ type: SEARCH_DATA_SUCCESS, data });

  } catch (error) {
    yield put({ type: SEARCH_DATA_FAIL, error });
  }
}

function* BookingDone(action){
  try {
    yield put({ type: MANAGE_STATUS_BOOKING , payload :{}});

  } catch (error) {
    yield put({ type: MANAGE_STATUS_BOOKING_FAIL, error });
  }
}

function* SaveRating(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}addRating`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: SAVE_RATING_SUCCESS, data });
    yield put(setAlert('Rated Successfully.', 'success'));

  } catch (error) {
    yield put({ type: SAVE_RATING_ERROR, error });
  }
}



function* SaveToWhislist(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}addToWishlists`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: ADD_WISHLIST_SUCCESS, data });
    // yield put(setAlert('Added to whislist.', 'success'));

  } catch (error) {
    yield put({ type: ADD_WISHLIST_ERROR, error });
  }
}

function* GetwishList(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const queryParams = new URLSearchParams(action.payload)
    const response = yield call(fetch, `${DEFAULT_URL}getWishList?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();

    yield console.log("apI IS WORKING...================>>>>, ", data)
    yield put({ type: GET_WISHLIST_SUCCESS, data });

  } catch (error) {
    yield put({ type: GET_WISHLIST_ERROR, error });
  }
}


function* GetLocationDetail(action) {
  try {
    const queryParams = new URLSearchParams(action.payload)
    const response = yield call(fetch, `${DEFAULT_URL}getLocation?${queryParams}`, {
      method: 'GET',
      mode : 'cors',
    });
    // console.log("CHECK THE LOCATION success DSAta =================>>>>" , response)
   return response
  } catch (error) {
    yield put({ type: GET_LOCATION_ERROR, error });
  }
}


function* RemoveFromWishList(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}removeFromWishlist/${action.payload.id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }
    yield put({ type: REMOVE_FROM_WISHLIST_SUCCESS, data });
    yield put(setAlert("Removed Successfully", 'success'));

  } catch (error) {
    yield put({ type: REMOVE_FROM_WISHLIST_FAIL, error });
  }
}

// GetProviderListing
function* GetProviderListing(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}singleUserVenues`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }
    yield put({ type: PROVIDER_VENUE_LISTING_SUCCESS, data });

  } catch (error) {
    yield put({ type: PROVIDER_VENUE_LISTING_FAIL, error });
  }
}

// GetProviderListing
function* GetPublicProviderListing(action) {
    try {
        const authtoken = localStorage.getItem("token");
        const queryParams = new URLSearchParams(action.payload);
        const response = yield call(fetch, `${DEFAULT_URL}publicProviderListing?${queryParams}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${authtoken}`
            }
        });
        const data = yield response.json();
        if (!data.success) {
            yield put(setAlert(data.msg, 'error'));
            return
        }
        yield put({ type: PROVIDER_VENUE_LISTING_SUCCESS, data });

    } catch (error) {
        yield put({ type: PROVIDER_VENUE_LISTING_FAIL, error });
    }
}

function* ChatNotes(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}chat_notes`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });

    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: CHAT_NOTES_SUCCESS, data });
    yield put(setAlert('Notes Added Successfully.', 'success'));

  } catch (error) {
    yield put({ type: CHAT_NOTES_ERROR, error });
    yield put(setAlert('error.', 'error'));
  }
}

// OverAllStatus
function* OverAllStatus(action){
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}overAlldetail`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();

    yield put({ type: OVERALLSTATUS_SUCCESS, data });

  } catch (error) {
    yield put({ type: OVERALLSTATUS_FAIL, error });
  }
}

// CheckAvailability of venues
function* CheckAvailability(action) {
  try {
    const queryParams = action.payload
    const response = yield call(fetch, `${DEFAULT_URL}checkTimeSlot?venueIds=${queryParams.venueIds}&date=${queryParams.date}&request=${queryParams.request}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const data = yield response.json();

    yield put({ type: SLOT_ALREADY_EXIST_SUCCESS, data });

  } catch (error) {
    yield put({ type: SLOT_ALREADY_EXIST_FAIL, error });
  }
}


//Get single event reviews
function* GetSingleVenueReview(action){
  try {
    const queryParams = new URLSearchParams(action.payload)
    const response = yield call(fetch, `${DEFAULT_URL}singleVenueReviews?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const data = yield response.json();

    yield put({ type: SINGLE_VENUE_REVIEW_SUCCESS, data });

  } catch (error) {
    yield put({ type: SINGLE_VENUE_REVIEW_FAIL, error });
  }
}
//Get single event reviews
function* EachVenueDetail(action){
  try {
    const authtoken = localStorage.getItem("token");
    const queryParams = new URLSearchParams(action.payload)
    const response = yield call(fetch, `${DEFAULT_URL}eachVenueDetail?${queryParams}`, {
      method: 'GET',
      headers:{
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();

    yield put({ type: EACH_VENUE_DETAIL_SUCCESS, data });

  } catch (error) {
    yield put({ type: EACH_VENUE_DETAIL_FAIL, error });
  }
}

//add event provider side
function* DraftEvent(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}add_draft`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });

    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: DRAFT_EVENT_SUCCESS, data });
    yield put(setAlert('Event Added Successfully.', 'success'));

  } catch (error) {
    yield put({ type: DRAFT_EVENT_ERROR, error });
    yield put(setAlert('error.', 'error'));
  }
}

//Get draft event reviews
function* GetDraft(action){
  try {
    const authtoken = localStorage.getItem("token");
    const queryParams = new URLSearchParams(action.payload)
    const response = yield call(fetch, `${DEFAULT_URL}get_draft?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    // yield put(console.log("check the data in saga ==============>>>>" , data))

    yield put({ type: GET_DRAFT_EVENT_SUCCESS, data });
    return data;

  } catch (error) {
    yield put({ type: GET_DRAFT_EVENT_ERROR, error });
  }
}


//Apply coupon 
function* GetApplyCouponValue(action){
  try {
    
    const response = yield call(fetch, `${DEFAULT_URL}applyCoupon/${action.payload}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const data = yield response.json();

    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }
    yield put({ type: APPLYCOUPON_SUCCESS, data });

  } catch (error) {
    yield put({ type: APPLYCOUPON_FAIL, error });
  }
}

//Get GetWallet
function* GetWallet(action){
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}getWallet`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    
    const data = yield response.json();
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }
    yield put({ type: WALLET_SUCCESS, data });

  } catch (error) {
    yield put({ type: WALLET_FAIL, error });
  }
}

//Get single event reviews
function* GetpaymentSucceeded(action){
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}paymentSucceeded/${action.payload}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }
    yield put({ type: PAYMENTSUCCEED_SUCCESS, data });

  } catch (error) {
    yield put({ type: PAYMENTSUCCEED_FAIL, error });
  }
}

//Get single event reviews
function* addBank(action){
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}add/bank`, {
      method: 'post',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();

    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }
    
    yield put({ type: BANK_SUCCESS, data });
    yield put(setAlert(data.msg, 'success'));
  } catch (error) {
    yield put({ type: BANK_FAIL, error });
  }
}

//Get single event reviews
function* getWalletHistory(action){
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}getWalletHistory`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }
    yield put({ type: WALLET_HISTORY_SUCCESS, data });

  } catch (error) {
    yield put({ type: WALLET_HISTORY_FAIL, error });
  }
}

//Get single event reviews
function* GetNotification(action){
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}getAllnotification`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });
    const data = yield response.json();
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }
    yield put({ type: GET_NOTIFICATION_SUCCESS, data });

  } catch (error) {
    yield put({ type: GET_NOTIFICATION_FAIL, error });
  }
}

function* SaveNotification(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const response = yield call(fetch, `${DEFAULT_URL}saveNotification?bookingId=${action.payload.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });

    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: SAVE_NOTIFICATION_SUCCESS, data });

  } catch (error) {
    yield put({ type: SAVE_NOTIFICATION_FAIL, error });
  }
}

function* GetAllDraftVenues(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const queryParams = new URLSearchParams(action.payload)
    const response = yield call(fetch, `${DEFAULT_URL}getAllDraftBooking?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });

    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: GET_ALL_DRAFT_VENUES_SUCCESS, data });

  } catch (error) {
    yield put({ type: GET_ALL_DRAFT_VENUES_FAIL, error });
  }
}


function* GetNotificationCount(action) {
  try {
    const authtoken = localStorage.getItem("token");
    const queryParams = new URLSearchParams(action.payload)
    const response = yield call(fetch, `${DEFAULT_URL}getAllDraftBooking?${queryParams}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${authtoken}`
      }
    });

    const data = yield response.json();
    
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: GET_ALL_DRAFT_VENUES_SUCCESS, data });

  } catch (error) {
    yield put({ type: GET_ALL_DRAFT_VENUES_FAIL, error });
  }
}


function* Apisaga() {
    yield takeEvery(LIST_VENUES, RecomendedVenues);
    yield takeEvery(PROVIDER_PROFILE, GetProviderProfile);
    yield takeEvery(PUBLIC_PROVIDER_PROFILE, GetPublicProviderProfile);
    yield takeEvery(USER_PROFILE, GetUserProfiles);
    yield takeEvery(SINGLE_VENUE_DETAIL, SingleVenueDetail);
    yield takeEvery(GET_PACKAGES_SUCCESS, SingleVenuePackages);
    yield takeEvery(UPDATE_USER_DETAIL, updateUserProfile);
    yield takeEvery(UPDATE_BUSINESS_PROFILE, updateBusinessProfile);
    yield takeEvery(BUSINESS_PLAN, GetBusinessPlan);
    yield takeEvery(MANAGERS, GetManagers);
    yield takeEvery(ADD_MANAGER, addManager);
    yield takeEvery(UPDATE_MANAGER_ACCESS, updateManagerAccess);
    yield takeEvery(UPDATE_MANAGER_SETTINGS, updateManagerSettings);
    yield takeEvery(UPDATE_VENUE_DETAIL, updateVenueDetail);
    yield takeEvery(ALL_AMENITIES, GetAllAmenities);
    yield takeEvery(BOOK_VENUE, BookVenue);
    yield takeEvery(CANCEL_BOOKING, CancelBooking);
    yield takeEvery(BOOKING_PAYMENT_PROCESSED, BookingPaymentProcessed);
    yield takeEvery(EVENT_DETAIL, getEventDetail);
    yield takeEvery(UPDATE_EVENT_DETAIL, updateEventDetail);
    yield takeEvery(BIZ_DELETE_EVENT, bizDeleteEvent);
    yield takeEvery(CONFIRM_EVENT, confirmEvent);
    yield takeEvery(DECLINE_EVENT, declineEvent);
    yield takeEvery(SEND_CONFIRMATION_EMAIL, sendConfirmationEmail);
    yield takeEvery(ADD_TASK, addTask);
    yield takeEvery(UPDATE_TASK, updateTask);
    yield takeEvery(DELETE_TASK, deleteTask);
    yield takeEvery(CALENDAR_RESET, calendarReset);
    yield takeEvery(SEND_VERIFICATION_EMAIL, sendVerificationEmail);
    yield takeEvery(FORGOT_PWD, forgotPwd);
    yield takeEvery(AUTH_RESET_PWD, authResetPwd);
    yield takeEvery(RESET_PWD, resetPwd);
    yield takeEvery(SET_RESET_PWD_FALSE, setResetPwdFalse);
    yield takeEvery(CURRENT_BOOKINGS, GetCurrentBookings);
    yield takeEvery(CUSTOMER_LIST, GetCustomerList);

    yield takeEvery(CALENDAR_DATA, GetCalenderData);
    yield takeEvery(PUBLIC_CALENDAR_DATA, GetPublicCalendarData);
    yield takeEvery(RESERVATION_REPORTS, GetReservationReports);
    yield takeEvery(ADD_RESERVATION_REPORT, addReservationReport);
    yield takeEvery(UPDATE_RESERVATION_REPORT, updateReservationReport);
    yield takeEvery(DELETE_RESERVATION_REPORT, deleteReservationReport);
    yield takeEvery(MEMBERSHIP_TYPES, getMembershipTypes);
    yield takeEvery(MEMBERSHIP_TYPE, getMembershipType);
    yield takeEvery(ADD_MEMBERSHIP_TYPE, addMembershipType);
    yield takeEvery(UPDATE_MEMBERSHIP_TYPE, updateMembershipType);
    yield takeEvery(DELETE_MEMBERSHIP_TYPE, deleteMembershipType);
    yield takeEvery(MEMBERSHIPS, getMemberships);
    yield takeEvery(ADD_MEMBERSHIPS, addMembershipEntries);
    yield takeEvery(IMPORT_MEMBERSHIPS, importMembershipEntries);
    yield takeEvery(UPDATE_MEMBERSHIP, updateMembershipEntry);
    yield takeEvery(DELETE_MEMBERSHIP, deleteMembershipEntry);
    yield takeEvery(ADD_MEMBER_PRICES, addMemberPrices);
    yield takeEvery(UPDATE_MEMBER_PRICE, updateMemberPrice);
    yield takeEvery(EMAIL_RESTRICTION, getEmailRestriction);
    yield takeEvery(ADD_WHITELIST_ENTRY, addEmailRestrictionEntry);
    yield takeEvery(DELETE_WHITELIST_ENTRY, deleteEmailRestrictionEntry);
    yield takeEvery(EVENT_TYPES, GetEventTypes);
    yield takeEvery(UPDATE_EVENT_TYPE, updateEventTypeDetail);
    yield takeEvery(ADD_EVENT_TYPE, addEventType);
    yield takeEvery(DELETE_EVENT_TYPE, deleteEventType);
    yield takeEvery(EVENT_TAGS, GetEventTags);
    yield takeEvery(UPDATE_EVENT_TAG, updateEventTagDetail);
    yield takeEvery(ADD_EVENT_TAG, addEventTag);
    yield takeEvery(DELETE_EVENT_TAG, deleteEventTag);
    yield takeEvery(FORMS, GetForms);
    yield takeEvery(FORM, GetForm);
    yield takeEvery(ADD_FORM, addForm);
    yield takeEvery(UPDATE_FORM, updateForm);
    yield takeEvery(DELETE_FORM, deleteForm);
    yield takeEvery(GET_SEARCH_DATA , GetSeachDataForVenue);
    yield takeEvery(BOOKING_SUCCESS_FALSE , BookingDone);
    yield takeEvery(SAVE_RATING , SaveRating);
    yield takeEvery(ADD_WISHLIST , SaveToWhislist);
    yield takeEvery(GET_WISHLIST , GetwishList);
    yield takeEvery(GET_LOCATION , GetLocationDetail);
    yield takeEvery(REMOVE_FROM_WISHLIST , RemoveFromWishList);
    yield takeEvery(PROVIDER_VENUE_LISTING , GetProviderListing);
    yield takeEvery(PUBLIC_PROVIDER_VENUE_LISTING, GetPublicProviderListing);
    yield takeEvery(CHAT_NOTES , ChatNotes);
    yield takeEvery(OVERALLSTATUS , OverAllStatus);
    yield takeEvery(SLOT_ALREADY_EXIST , CheckAvailability);
    yield takeEvery(SINGLE_VENUE_REVIEW , GetSingleVenueReview);
    yield takeEvery(EACH_VENUE_DETAIL , EachVenueDetail);
    yield takeEvery(DRAFT_EVENT , DraftEvent);
    yield takeEvery(GET_DRAFT_EVENT , GetDraft);
    yield takeEvery(APPLYCOUPON_STATUS , GetApplyCouponValue);
    yield takeEvery(PAYMENTSUCCEED_STATUS , GetpaymentSucceeded);
    yield takeEvery(GET_NOTIFICATION ,GetNotification);
    yield takeEvery(SAVE_NOTIFICATION ,SaveNotification);
    yield takeEvery(GET_ALL_DRAFT_VENUES ,GetAllDraftVenues);
    yield takeEvery(WALLET_STATUS , GetWallet);
    yield takeEvery(WALLET_HISTORY_STATUS , getWalletHistory);
    yield takeEvery(BANK_STATUS , addBank);
    yield takeEvery(GET_NOTIFICAITON_LISTING , GetNotificationCount);
}

export default Apisaga;