import { setAlert, bookVenue } from "../../redux/action/action";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import React from 'react';
import CheckoutFormBase from "./CheckoutFormBase";
import { bookvenues } from "constants";


const CheckoutFormNoPayment = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const singleVenueDetails = useSelector(state => state.Apis.singleVenueDetail);
	const userDetail = useSelector(state => state.Apis.userDetail);
	const bookingSuccess = useSelector(state => state.Apis.bookingSuccess);

	const [checkoutData, setCheckoutData] = useState(JSON.parse(localStorage.getItem("bookingData")))

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (localStorage.getItem('token') == null) {
			return dispatch(setAlert('Please Login First', 'error'))
		}

		if (!userDetail.verify_account) {
			return dispatch(setAlert('Please Verify Your Account Email', 'error'))
		}

		const updatedCheckoutData = {
			...checkoutData,
			bussness_id: singleVenueDetails.business_id,
			selfBook:  false,
			status: bookvenues.STATUS_PENDING,
		}

		dispatch(bookVenue(updatedCheckoutData))
	};

	useEffect(() => {
		if (bookingSuccess) {
			localStorage.removeItem("bookingData");
			navigate('/booking');
		} else if (bookingSuccess === false) {
			localStorage.removeItem("bookingData");
			navigate(`/venue/${checkoutData.venueId}`);
		}
	}, [bookingSuccess]);


	return <CheckoutFormBase
		checkoutData={checkoutData}
		setCheckoutData={setCheckoutData}
		handleSubmit={handleSubmit}
	/>
};

export default CheckoutFormNoPayment;
