import {
    CButton,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea, CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import TimePicker from "../../../components/TimePicker";
import {dateRange, dateRangeByLength, hasConflict, weekDays} from "../../../../Utils/DateTimeUtils";
import {useState} from "react";
import {bookVenue, setAlert} from "../../../../redux/action/action";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import {bookvenues} from "constants";

const BlockTimeModal = ({ visible, onClose, blockTimeFormData, setBlockTimeFormData, dispatch, bookings, eventTypes, venues, eventTags, setEventTags, editEventAccess }) => {
    const blockTimeRepeatDaysDefault = {};
    weekDays.map(weekDay => {
        blockTimeRepeatDaysDefault[`repeat${weekDay}`] = false;
    });

    const [blockTimeRepeatDays, setBlockTimeRepeatDays] = useState(blockTimeRepeatDaysDefault);

    const updateBlockTimeForm = (event) => {
        let newValue = event.target.value;

        if (event.target.name === "repeat") {
            newValue = event.target.checked;
            if (event.target.checked) {
                if (blockTimeFormData.date) {
                    const day = (new Date(blockTimeFormData.date)).getUTCDay();
                    setBlockTimeRepeatDays({...blockTimeRepeatDaysDefault, [`repeat${weekDays[day]}`]: true})
                }
            } else {
                setBlockTimeRepeatDays(blockTimeRepeatDaysDefault);
            }
        } else if (event.target.name === "date") {
            const day = (new Date(event.target.value)).getUTCDay();
            setBlockTimeRepeatDays({...blockTimeRepeatDaysDefault, [`repeat${weekDays[day]}`]: true})
        }
        setBlockTimeFormData({...blockTimeFormData, [event.target.name]: newValue});
    }

    const updateBlockTimeFormMultiselect = (selectedOptions, actionMeta) => {
        setBlockTimeFormData({...blockTimeFormData, [actionMeta.name]: selectedOptions});
    }

    const updateBlockTimeFormTimePicker = (selectedOption, actionMeta) => {
        setBlockTimeFormData({...blockTimeFormData, [actionMeta.name]: selectedOption.value});
    }

    const handleAddBlockTime = (e) => {
        e.preventDefault();
        if (!editEventAccess) {
            dispatch(setAlert("You do not have the permission to add a new blocked time.", "error"));
            return;
        }
        if (!blockTimeFormData.start_time || !blockTimeFormData.end_time) {
            dispatch(setAlert("At least one of start time or end time is missing.", "error"));
            return;
        }
        const totalVenues = blockTimeFormData.venues.length;
        let totalEvents = 0;
        let totalConflicts = 0;
        let startDateRange;
        let endDateRange;
        let repeatDays;
        if (blockTimeFormData.repeat) {
            endDateRange = dateRange(new Date(blockTimeFormData.end_date), new Date(blockTimeFormData.repeat_end));
            startDateRange = dateRangeByLength(new Date(blockTimeFormData.date), endDateRange.length);
            repeatDays = weekDays.map(weekday => blockTimeRepeatDays[`repeat${weekday}`]);
        } else {
            endDateRange = [new Date(blockTimeFormData.end_date)];
            startDateRange = [new Date(blockTimeFormData.date)];
            repeatDays = weekDays.map(_ => true);
        }
        for (const {value} of blockTimeFormData.venues) {
            const eventsToAdd = startDateRange
                .map((startDate, index) => ({
                    startDate,
                    venueId: value,
                    start_time: blockTimeFormData?.start_time,
                    end_time: blockTimeFormData?.end_time,
                    date: startDate.toISOString().split("T")[0],
                    end_date: endDateRange[index].toISOString().split("T")[0],
                    status: bookvenues.STATUS_INTERNAL
                }))
                .filter(event => repeatDays[event.startDate.getUTCDay()]);
            const conflictingEvents = eventsToAdd.filter(event => hasConflict(event, bookings));
            totalEvents += eventsToAdd.length;
            totalConflicts += conflictingEvents.length;
        }
        if (totalEvents > 1 && prompt(
            `You are about to add a total of ${totalEvents} events${totalVenues > 1 ? ` across ${totalVenues} venue spaces` : ""}. ` +
            "If you are sure to continue, please type the total number of events to confirm.") !== totalEvents.toString()) {
            dispatch(setAlert("Please acknowledge the number of events before adding them.", "error"));
            return;
        }
        if (totalConflicts && prompt(
            `${totalEvents > 1 ? totalConflicts : "This"} event${totalConflicts > 1 ? "s" : ""} will have conflicts with existing ones. ` +
            "If you are sure to continue, please type \"DOUBLEBOOK\" to confirm.") !== "DOUBLEBOOK") {
            dispatch(setAlert("Please acknowledge the time conflicts before adding the event.", "error"));
            return;
        }
        const bookVenueData = {
            ...blockTimeFormData,
            ...blockTimeRepeatDays,
            selfBook: true,
            venueIds: blockTimeFormData.venues.map(venue => venue.value),
            tags: blockTimeFormData.tags ? blockTimeFormData.tags.map(tag => ({
                id: tag.value,
                name: tag.label,
            })) : [],
        };
        dispatch(bookVenue(bookVenueData));
    }

    const onCreateOption = (name) => {
        setEventTags([...eventTags, {
            id: name,
            name,
        }]);

        const newTag = {
            label: name,
            value: name,
        }
        setBlockTimeFormData({
            ...blockTimeFormData,
            tags: blockTimeFormData.tags ? [...blockTimeFormData.tags, newTag] : [newTag]
        });
    }

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <div>
                    <CModalTitle>Add Event</CModalTitle>
                    <p className="modal-subtitle mb-0">The event's time slot will no longer be available to customers.</p>
                </div>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <CForm onSubmit={handleAddBlockTime}>
                    <CFormLabel htmlFor="title">Title</CFormLabel>
                    <div className="pb-3">
                        <CFormInput required type="text" name="title" onChange={updateBlockTimeForm}/>
                    </div>
                    <div className="pb-3">
                        <CFormLabel htmlFor="venues">Venue</CFormLabel>
                        <MultiSelectCheckbox
                            name="venues"
                            onChange={updateBlockTimeFormMultiselect}
                            value={blockTimeFormData.venues}
                            options={venues.map(venue => ({
                                label: venue.name,
                                value: venue.id,
                            }))}
                        />
                    </div>
                    <div className="pb-3">
                        <CFormLabel htmlFor="eventtype_id">Event Type</CFormLabel>
                        <CFormSelect name="eventtype_id" onChange={updateBlockTimeForm} defaultValue={0}>
                            {
                                eventTypes.map(eventType => <option
                                    value={eventType.id}>{eventType.name}</option>)
                            }
                        </CFormSelect>
                    </div>
                    <div class="pb-3">
                        <CFormLabel htmlFor="tags">Tags</CFormLabel>
                        <MultiSelectCheckbox
                            name="tags"
                            onChange={updateBlockTimeFormMultiselect}
                            options={eventTags.map(
                                tag => ({
                                    label: tag.name,
                                    value: tag.id,
                                })
                            )}
                            value={blockTimeFormData?.tags}
                            creatable
                            onCreateOption={onCreateOption}
                        />
                    </div>
                    <div class="flex-row pb-3">
                        <div class="col-6 pe-2">
                            <CFormLabel htmlFor="date">Start Date</CFormLabel>
                            <CFormInput required type="date" name="date" value={blockTimeFormData.date}
                                        onChange={updateBlockTimeForm}/>
                        </div>
                        <div class="col-6 ps-2">
                            <CFormLabel htmlFor="start_time">Start Time</CFormLabel>
                            <TimePicker name="start_time" value={blockTimeFormData.start_time}
                                        onChange={updateBlockTimeFormTimePicker}/>
                        </div>
                    </div>
                    <div class="flex-row pb-3">
                        <div class="col-6 pe-2">
                            <CFormLabel htmlFor="end_date">End Date</CFormLabel>
                            <CFormInput required type="date" name="end_date" value={blockTimeFormData.end_date}
                                        onChange={updateBlockTimeForm}/>
                        </div>
                        <div class="col-6 ps-2">
                            <CFormLabel htmlFor="start_time">End Time</CFormLabel>
                            <TimePicker name="end_time" value={blockTimeFormData.end_time}
                                        onChange={updateBlockTimeFormTimePicker}/>
                        </div>
                    </div>
                    <div className="form-check pb-2">
                        <input type="checkbox" checked={blockTimeFormData.repeat}
                               className="form-check-input primary-color"
                               name="repeat"
                               onChange={updateBlockTimeForm}/>
                        <label className="check-form-label"
                               htmlFor="repeat">Repeat</label>
                    </div>
                    {
                        blockTimeFormData.repeat ?
                            <>
                                <div className="flex-row align-items-center pb-3">
                                    <div>Starts every</div>
                                    <div class="px-1">
                                        {
                                            weekDays.map((weekDay) =>
                                                <div className="day-of-the-week-circle-container" onClick={
                                                    () => setBlockTimeRepeatDays({
                                                        ...blockTimeRepeatDays,
                                                        [`repeat${weekDay}`]: !blockTimeRepeatDays[`repeat${weekDay}`]
                                                    })
                                                }>
                                                    <div
                                                        className={`d-flex day-of-the-week-circle ${blockTimeRepeatDays[`repeat${weekDay}`] ? "active" : "inactive"}`}>
                                                        {weekDay[0]}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                                <div className="flex-row align-items-center pb-3">
                                    <div>Until</div>
                                    <div className="px-2">
                                        <CFormInput required type="date" name="repeat_end"
                                                    onChange={updateBlockTimeForm}/>
                                    </div>
                                </div>
                            </> :
                            null
                    }
                    <CFormLabel htmlFor="attendees">Attendees</CFormLabel>
                    <div className="flex-row align-items-center pb-3">
                        <div className="pe-2">
                            <CFormInput type="number" required min="0" name="attendees"
                                        onChange={updateBlockTimeForm} style={{width: "100px"}}/>
                        </div>
                        <span>people</span>
                    </div>
                    <CFormLabel htmlFor="finalPrice">Revenue</CFormLabel>
                    <div className="flex-row align-items-center pb-3">
                        <span>$</span>
                        <div className="px-2">
                            <CFormInput type="number" required min="0" name="finalPrice"
                                        onChange={updateBlockTimeForm} style={{width: "100px"}}/>
                        </div>
                    </div>
                    <CFormLabel>Customer Name</CFormLabel>
                    <div class="flex-row pb-3">
                        <div class="col-6 pe-2">
                            <CFormLabel htmlFor="firstName">First Name</CFormLabel>
                            <CFormInput type="text" name="firstName" onChange={updateBlockTimeForm}/>
                        </div>
                        <div class="col-6 ps-2">
                            <CFormLabel htmlFor="lastName">Last Name</CFormLabel>
                            <CFormInput type="text" name="lastName" onChange={updateBlockTimeForm}/>
                        </div>
                    </div>
                    <CFormLabel htmlFor="customer_number">Customer Phone</CFormLabel>
                    <div className="pb-3">
                        <CFormInput type="text" name="customer_number" onChange={updateBlockTimeForm}/>
                    </div>
                    <CFormLabel htmlFor="customer_email">Customer Email</CFormLabel>
                    <div className="pb-3">
                        <CFormInput type="email" name="customer_email" onChange={updateBlockTimeForm}/>
                    </div>
                    <CFormLabel htmlFor="description">Description</CFormLabel>
                    <div className="pb-4">
                        <CFormTextarea name="description" onChange={updateBlockTimeForm}/>
                    </div>
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    );
};

export default BlockTimeModal;
