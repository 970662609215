import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { DEFAULT_URL, setAlert, bookVenue, cancelBooking } from "../../redux/action/action";
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import React from 'react';
import CheckoutFormBase from "./CheckoutFormBase";
import {bookvenues} from "constants";


const CheckoutForm = ({ payment_intent, client_secret }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const stripe = useStripe();
	const elements = useElements();
	const singleVenueDetails = useSelector(state => state.Apis.singleVenueDetail);
	const userDetail = useSelector(state => state.Apis.userDetail);
	const bookingSuccess = useSelector(state => state.Apis.bookingSuccess);
	const bookingDetail = useSelector(state => state.Apis.bookingDetail);

	const [checkoutData, setCheckoutData] = useState(JSON.parse(localStorage.getItem("bookingData")))

	const handleSubmit = async (event) => {
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		if (!stripe || !elements) {
			// Stripe.js hasn't yet loaded.
			// Make sure to disable form submission until Stripe.js has loaded.
			return;
		}

		if (localStorage.getItem('token') == null) {
			return dispatch(setAlert('Please Login First', 'error'))
		}

		if (!userDetail.verify_account) {
			return dispatch(setAlert('Please Verify Your Account Email', 'error'))
		}

		const updatedCheckoutData = {
			...checkoutData,
			bussness_id: singleVenueDetails.business_id,
			selfBook: false,
			status: bookvenues.STATUS_PROCESSING,
			payment_intent_id: payment_intent
		};
		setCheckoutData(updatedCheckoutData);
		dispatch(bookVenue(updatedCheckoutData));
	};

	const pay = async () => {
		try {
			const paymentMethod = await stripe.createPaymentMethod({
				type: 'card',
				card: elements.getElement(CardElement),
			});
			if (paymentMethod.error) {
				return dispatch(setAlert(paymentMethod.error.message, 'error'));
			}
			await fetch(DEFAULT_URL + 'confirmPayment',
				{
					method: 'GET',
					headers: {
						'payment_intent': payment_intent,
						'payment_method': paymentMethod.paymentMethod.id,
						'client_secret': client_secret,
						'booking_id': bookingDetail.savebookVenue?.id
					}
				}
			).then((res) => {
				return(res.json())
			}).then((data) => {
				if (data.success) {
					window.location = data.url
				} else {
					dispatch(setAlert(data.msg, 'error'));
					dispatch(cancelBooking({id: bookingDetail.savebookVenue?.id}));
				}
			});
		} catch (error) {
			dispatch(cancelBooking(bookingDetail.savebookVenue?.id));
		}
		
	};

	useEffect(() => {
		if (bookingSuccess) {
			pay();
		} else if (bookingSuccess === false) {
			localStorage.removeItem("bookingData");
			navigate(`/venue/${checkoutData.venueId}`);
		}
	}, [bookingSuccess]);

	const CARD_ELEMENT_OPTIONS = {
		style: {
			base: {
				color: "#32325d",
				fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
				fontSmoothing: "antialiased",
				fontSize: "16px",
				"::placeholder": {
					color: "#aab7c4",
				},
			},
			invalid: {
				color: "#fa755a",
				iconColor: "#fa755a",
			},
		},
	};

	const paymentSection = <div className="payment_info p-5 border-top">
		<CardElement options={CARD_ELEMENT_OPTIONS}/>
	</div>;

	return <CheckoutFormBase
		checkoutData={checkoutData}
		setCheckoutData={setCheckoutData}
		paymentSection={paymentSection}
		handleSubmit={handleSubmit}
		submitDisabled={!stripe}
	/>
};

export default CheckoutForm;
