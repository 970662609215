import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    CButton,
    CCard,
    CCardBody,
    CFormInput,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilPlus} from "@coreui/icons";
import {useNavigate} from "react-router-dom";
import DataTable from "react-data-table-component";
import {columns} from "./FilterUtils";
import {applyFilters} from "../../EventTags/FilterUtils";
import {deleteForm, getForms, setAlert} from "../../../../../redux/action/action";
import {users} from "constants";

const Forms = () => {
    document.title = "Forms | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View and manage your forms with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(state => state.Apis.userDetail);
    const forms = useSelector(state => state.Apis.forms);
    const updatedForm = useSelector(state => state.Apis.updatedForm);

    const [filters, setFilters] = useState({});
    const [displayedForms, setDisplayedForms] = useState([]);

    const updateFilters = (event) => {
        setFilters({...filters, [event.target.name]: event.target.value});
    };

    const onDeleteButtonClick = (id) => {
        if (window.confirm("Are you sure to delete this form?")) {
            dispatch(deleteForm({ id }));
        }
    };

    useEffect(() => {
        if (userDetail?.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.editVenueAccess) {
                dispatch(setAlert('You do not have the permissions to view forms.','error'));
                navigate("/business");
            }
        }
    }, [userDetail]);

    useEffect(() => {
        dispatch(getForms());
    }, [updatedForm]);

    useEffect(() => {
        if (forms) {
            setDisplayedForms(applyFilters(forms, filters));
        }
    }, [forms, filters]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <div>
                            <h2>Request Forms</h2>
                        </div>
                        <div>
                            <CButton color="light" href={`/business/forms/new`}>
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Form
                            </CButton>
                        </div>
                    </div>
                    <hr/>
                    <div class="flex-row flex-wrap align-items-center">
                        <div class="pe-2 pb-3">
                            <CFormInput name="search" placeholder="Search..." onChange={updateFilters}/>
                        </div>
                    </div>
                    <DataTable
                        columns={columns(onDeleteButtonClick)}
                        data={displayedForms}
                        pagination
                    />
                </CCardBody>
            </CCard>
        </div>
    );
}

export default Forms;
