import {
    CAvatar,
    CBadge,
    CButton,
    CContainer,
    CImage,
    CNavGroup,
    CNavItem,
    CNavTitle,
    CSidebar,
    CSidebarBrand,
    CSidebarFooter,
    CSidebarHeader,
    CSidebarNav,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
    cilAccountLogout,
    cilCalendarCheck,
    cilChartLine,
    cilEqualizer,
    cilMenu,
    cilSpeech,
    cilSitemap,
    cibStripe,
    cilTennis,
    cilUser,
    cilPeople,
} from "@coreui/icons";
import {getProfilePictureUri, hrefNav} from "../../Utils/UriUtils";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    DEFAULT_URL,
    getUserProfile,
    logout,
} from "../../redux/action/action";
import {useNavigate} from "react-router-dom";
import {users} from "constants";

const BusinessSidebar = ({setSidebarNarrowed}) => {
    // Some of CoreUI's default styles only apply for window width of 992px and above.
    const coreUIWideScreenPixels = 992;
    const demoAccountIds = [192, 268, 373];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const hrefOnClick = hrefNav(navigate);

    const userDetail = useSelector(state => state.Apis.userDetail);

    const [userData, setUserData] = useState({});
    const [business, setBusiness] = useState({});
    const [windowSize, setWindowSize] = useState([window.innerWidth, window.innerHeight]);
    const [narrowed, setNarrowed] = useState(false);

    const performLogout = () => {
        localStorage.clear();
        navigate('/business/login');
        dispatch(logout());
    }

    const getStripeUrl = async () => {
        await fetch(DEFAULT_URL + 'stripeLogin/' + business?.stripeid).then((res) => {
            return(res.json())
        }).then((data) => {
            window.location.href = data.body.url
        })
    }

    const hasAccess = (accessName) => {
        return userData.role === users.ROLE_VENUE_OWNER || (userData.manager && userData.manager[accessName]);
    }

    useEffect(() => {
        dispatch(getUserProfile())
    }, []);

    useEffect(() => {
        setUserData(userDetail);
        setBusiness(userDetail.role === users.ROLE_VENUE_OWNER ? userDetail : userDetail.manager?.business);
    }, [userDetail]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    return (
        <CSidebar className="border-end show" colorScheme="dark" position="fixed" narrow={narrowed} style={(narrowed) ? {width:"4rem"} : null}>
                <CSidebarHeader className={`pb-0 ${narrowed ? "" : "ps-2"}`} style={windowSize[0] < coreUIWideScreenPixels && narrowed ? {paddingLeft: "0.4rem"} : {}}>
                    <CButton onClick={() => {
                        setSidebarNarrowed(!narrowed);
                        setNarrowed(!narrowed);
                    }}>
                        <CIcon icon={cilMenu} className="primary-color" size={'xl'}/>
                    </CButton>
                    {!narrowed ?
                        <CSidebarBrand>
                            <CImage src={'../../../assets/images/logo.png'} width={'100%'} height={'100%'} className="object-fit-contain px-2 py-1"/>
                        </CSidebarBrand> :
                        null
                    }
                </CSidebarHeader>
                <CSidebarHeader className="py-0" style={windowSize[0] < coreUIWideScreenPixels && narrowed ? {paddingLeft: "0.48rem"} : {}}>
                    <CContainer className="flex-column justify-content-center">
                        <CContainer className="flex-row justify-content-center pt-2 pb-3">
                            <CAvatar size={narrowed ? "md" : "xl"} shape="rounded-circle" src={getProfilePictureUri(userData?.profile_image, "../../../assets")}/>
                        </CContainer>
                        {!narrowed ? <h5 class="text-center">{userData?.name}</h5> : null}
                    </CContainer>
                </CSidebarHeader>
                <CSidebarNav className="pt-0" style={windowSize[0] < coreUIWideScreenPixels && narrowed ? {paddingLeft: "0.38rem"} : {}}>
                        {!narrowed ? <CNavTitle>Business Operations</CNavTitle> : null}
                        {hasAccess("calendarAccess") && !narrowed ?
                            <CNavGroup toggler={<><CIcon customClassName="nav-icon" icon={cilCalendarCheck} style={{color: "#ff7e67"}}/> Events</>}>
                                <CNavItem href="/business/reservations/calendar" onClick={hrefOnClick} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Calendar View</CNavItem>
                                <CNavItem href="/business/reservations/table" onClick={hrefOnClick} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> List View</CNavItem>
                                {hasAccess("editReservationReportsAccess") ?
                                    <CNavItem href="/business/reservations/scheduled-reports" onClick={hrefOnClick} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Scheduled Reports</CNavItem>
                                    : null
                                }
                            </CNavGroup> :
                            <>
                                {(hasAccess("calendarAccess") && narrowed) ? 
                                    <CNavItem href="/business/reservations/calendar" onClick={hrefOnClick}><CIcon customClassName="nav-icon" icon={cilCalendarCheck} style={{color: "#ff7e67"}}/></CNavItem> :
                                    null
                                }
                            </>
                        }
                        {
                            hasAccess("programAccess") ?
                                narrowed ?
                                    <CNavItem href="#" onClick={() => navigate("/business/programs/list")}><CIcon customClassName="nav-icon" icon={cilTennis} style={{color: "#ff7e67"}}/></CNavItem> :
                                    <CNavGroup toggler={<><CIcon customClassName="nav-icon" icon={cilTennis} style={{color: "#ff7e67"}}/> Programs</>}>
                                        <CNavItem href="#" onClick={() => navigate("/business/programs/list")} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> List View</CNavItem>
                                    </CNavGroup> : null
                        }
                        {(hasAccess("editVenueAccess") || hasAccess("editBusinessAccess") || hasAccess("editTeamAccess")) && !narrowed ?
                            <CNavGroup toggler={<><CIcon customClassName="nav-icon" icon={cilSitemap} style={{color: "#ff7e67"}}/> Organization</>}>
                                {hasAccess("editVenueAccess") ?
                                    <CNavItem href="/business/venues" onClick={hrefOnClick} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Venues</CNavItem> :
                                    null
                                }
                                {hasAccess("editTeamAccess") ?
                                    <CNavItem href="/business/team" onClick={hrefOnClick} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Team</CNavItem> :
                                    null
                                }
                                {hasAccess("editBusinessAccess") ?
                                    <>
                                        <CNavItem href="/business/organization-profile" onClick={hrefOnClick} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Profile</CNavItem>
                                        <CNavItem href="/business/subscription" onClick={hrefOnClick} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Subscription Plan</CNavItem>
                                    </> :
                                    null
                                }
                            </CNavGroup> :
                            null
                        }
                        {hasAccess("editCustomerAccess") && !narrowed ?
                            <CNavGroup toggler={<><CIcon customClassName="nav-icon" icon={cilPeople} style={{color: "#ff7e67"}}/> Customers</>}>
                                <CNavItem href="/business/customers/members" onClick={hrefOnClick} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Memberships</CNavItem>
                                <CNavItem href="/business/customers/booking-restrictions" onClick={hrefOnClick} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Booking Restrictions</CNavItem>
                            </CNavGroup> : 
                            null
                        }
                        {hasAccess("editEventAccess") || hasAccess("editVenueAccess") && !narrowed ?
                            <CNavGroup toggler={<><CIcon customClassName="nav-icon" icon={cilEqualizer} style={{color: "#ff7e67"}}/> Manage</>}>
                                {hasAccess("editEventAccess") ? <>
                                    <CNavItem href="/business/event-types" onClick={hrefOnClick} className="py-1"><span
                                    className="nav-icon"><span className="nav-icon-bullet"/></span> Event
                                    Types</CNavItem>
                                    <CNavItem href="/business/event-tags" onClick={hrefOnClick} className="py-1"><span
                                className="nav-icon"><span className="nav-icon-bullet"/></span> Event Tags</CNavItem>
                                </> : null}
                                {hasAccess("editVenueAccess") ? <CNavItem href="/business/forms" onClick={hrefOnClick} className="py-1"><span
                                    className="nav-icon"><span className="nav-icon-bullet"/></span> Forms</CNavItem> : null}
                            </CNavGroup>
                            : null
                        }
                        {(business?.business_plan?.chatbotId && hasAccess("aiAccess")) && !narrowed ?
                            <CNavGroup toggler={<><CIcon customClassName="nav-icon" icon={cilSpeech} style={{color: "#ff7e67"}}/> DoubleSpot AI</>}>
                                <CNavItem href="/business/ai/conversations" onClick={hrefOnClick} className="py-1">
                                    <span className="nav-icon"><span className="nav-icon-bullet"/></span>
                                    {/*This is for demo purposes so that the DoubleSpot Bridge chatbot is shown for the demo business*/}
                                    {
                                        (userDetail.role === users.ROLE_VENUE_MANAGER && demoAccountIds.includes(userDetail.bussnessId)) ||
                                        (userDetail.role === users.ROLE_VENUE_OWNER && demoAccountIds.includes(userDetail.id))
                                            ? "DoubleSpot Bridge" : "Conversations"
                                    }
                                </CNavItem>
                                <CNavItem href="/business/ai/chatbot-preview" onClick={hrefOnClick} className="py-1">
                                    <span className="nav-icon"><span className="nav-icon-bullet"/></span>
                                    {/*This is for demo purposes so that the DoubleSpot Find chatbot is shown for the demo business*/}
                                    {
                                        (userDetail.role === users.ROLE_VENUE_MANAGER && demoAccountIds.includes(userDetail.bussnessId)) ||
                                        (userDetail.role === users.ROLE_VENUE_OWNER && demoAccountIds.includes(userDetail.id))
                                            ? "DoubleSpot Find" : "Chatbot Preview"
                                    }
                                </CNavItem>
                            </CNavGroup> :
                            <>
                                {((business?.business_plan?.chatbotId && hasAccess("aiAccess")) && narrowed) ?
                                    <CNavItem href="/business/ai/conversations" onClick={hrefOnClick}><CIcon customClassName="nav-icon" icon={cilSpeech} style={{color: "#ff7e67"}}/></CNavItem> :
                                    null
                                }
                            </>
                        }
                        {hasAccess("analyticsAccess") ? 
                            <CNavItem href="/business/analytic-reports" onClick={hrefOnClick}><CIcon customClassName="nav-icon" icon={cilChartLine} style={{color: "#ff7e67"}}/>
                                {narrowed ? null : <>Analytic Reports &nbsp; <CBadge className="black" color="light">Demo</CBadge></>}
                            </CNavItem> :
                            null
                        }
                        {narrowed && <hr/>}
                        {!narrowed ? <CNavTitle>Account Actions</CNavTitle> : null}
                        {userDetail.role === users.ROLE_VENUE_MANAGER && !narrowed ?
                            <CNavGroup toggler={<><CIcon customClassName="nav-icon" icon={cilUser} style={{color: "#ff7e67"}}/> Account Settings</>}>
                                <CNavItem href="/business/account/personal-info" onClick={hrefOnClick} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Personal Information</CNavItem>
                                <CNavItem href="/business/account/user-preference" onClick={hrefOnClick} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> User Preference</CNavItem>
                            </CNavGroup> :
                            <>
                                {(userDetail.role === users.ROLE_VENUE_MANAGER && narrowed) ? 
                                    <CNavItem href="/business/account/personal-info" onClick={hrefOnClick}><CIcon customClassName="nav-icon" icon={cilUser} style={{color: "#ff7e67"}}/></CNavItem> :
                                    null
                                }
                            </>
                        }
                        {business?.stripeid && hasAccess("editBusinessAccess") ?
                            <CNavItem href="#" onClick={getStripeUrl}><CIcon customClassName="nav-icon" icon={cibStripe} style={{color: "#ff7e67"}}/> {narrowed ? null : "Stripe Account"}</CNavItem> : null
                        }
                        <CNavItem href="#" onClick={performLogout}><CIcon customClassName="nav-icon" icon={cilAccountLogout} style={{color: "#ff7e67"}}/> {narrowed ? null : "Logout"}</CNavItem>
                    </CSidebarNav>
            {narrowed ?
                <CSidebarFooter style={(narrowed) ? {marginTop:"auto"} : null}>
                    <CSidebarBrand>
                        <CImage src={'../../../assets/images/fav_icon.png'} width={'33rem'} height={'100%'} className="object-fit-contain"/>
                    </CSidebarBrand>
                </CSidebarFooter> :
                null
            }
        </CSidebar>
    );
};

export default BusinessSidebar;
