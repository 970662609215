import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    CButton,
    CCard,
    CCardBody,
    CFormInput,
    CFormLabel,
} from "@coreui/react";
import {useNavigate, useParams} from "react-router-dom";
import SortableQuestions from "./SortableQuestions";
import {addForm, getForm, setAlert, updateForm} from "../../../../../redux/action/action";
import {users} from "constants";

const Form = () => {
    document.title = "Request Forms | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View and manage your request forms with DoubleSpot's business dashboard."
    );

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(state => state.Apis.userDetail);
    const singleFormDetail = useSelector(state => state.Apis.form);
    const updateFormSuccess = useSelector(state => state.Apis.updateFormSuccess);

    const [form, setForm] = useState({});
    const [name, setName] = useState("");
    const [questionOrder, setQuestionOrder] = useState([]);
    const [questions, setQuestions] = useState([]);

    const submitForm = () => {
        const submissionData = {
            name,
            questions: questions.map(question => ({
                ...question,
                type: parseInt(question.type),
            })),
            questionOrder,
        };
        if (id === "new") {
            dispatch(addForm(submissionData));
        } else {
            submissionData.id = id;
            dispatch(updateForm(submissionData));
        }
    };

    useEffect(() => {
        if (userDetail?.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.editVenueAccess) {
                dispatch(setAlert('You do not have the permissions to view forms.','error'));
                navigate("/business");
            }
        }
    }, [userDetail]);

    useEffect(() => {
        if (id !== "new") {
            dispatch(getForm({
                id,
            }));
        }
    }, []);

    useEffect(() => {
        if (singleFormDetail === null) {
            navigate("/business/forms");
        }
        setForm(singleFormDetail);
        setName(singleFormDetail?.name);
        const newQuestions = singleFormDetail?.form_questions || [];
        setQuestions(newQuestions);
        setQuestionOrder(newQuestions.map((_, index) => index + 1));
    }, [singleFormDetail]);

    useEffect(() => {
        if (updateFormSuccess) {
            navigate("/business/forms");
        }
    }, [updateFormSuccess]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <div>
                            <h2 className="mb-0">{form?.name ? `Edit Form: ${form?.name}` : "Create Form"}</h2>
                        </div>
                    </div>
                    <hr/>
                    <div className="pb-3 col-4">
                        <CFormLabel htmlFor="name">
                            <div className="edit-venue-form-label">Name</div>
                        </CFormLabel>
                        <CFormInput type="text" name="name" defaultValue={form?.name} onChange={(event) => setName(event.target.value)}/>
                    </div>
                    <SortableQuestions
                        questionOrder={questionOrder}
                        setQuestionOrder={setQuestionOrder}
                        questions={questions}
                        setQuestions={setQuestions}
                    />
                    <div className="flex-row pt-3">
                        <div style={{width: "70px"}}>
                            <CButton type="submit" style={{backgroundColor: "#ff7e67", color: "white"}}
                                     onClick={submitForm}>Save</CButton>
                        </div>
                        <div className="flex-column">
                            <CButton color="secondary" type="button" variant="outline"
                                     role="button" onClick={() => navigate("/business/forms")}>Cancel</CButton>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        </div>
    );
}

export default Form;
