import {
    CBadge,
    CButton,
    CCard,
    CCardBody,
    CDropdown,
    CDropdownDivider,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
} from "@coreui/react";
import {formatDate} from "../../../../Utils/DateTimeUtils";
import {bookvenues, users} from "constants";
import DataTable from "react-data-table-component";
import React, {useEffect, useState} from "react";
import CIcon from "@coreui/icons-react";
import {cilEnvelopeClosed, cilPencil, cilPlus, cilTrash} from "@coreui/icons";
import EditEventModal from "./EditEventModal";
import {useDispatch, useSelector} from "react-redux";
import {
    bizDeleteEvent,
    confirmEvent,
    declineEvent,
    deleteTask,
    getEventDetail,
    getEventTags,
    getEventTypes,
    getProviderVenueListing,
    sendConfirmationEmail,
    updateTask,
    setAlert,
    calenderData
} from "../../../../redux/action/action";
import {useNavigate} from "react-router-dom";
import {columns} from "../../../../Utils/TaskUtils";
import EditTaskModal from "./EditTaskModal";
import moment from "moment-timezone";
import CustomerInformationModal from "./CustomerInformationModal";
import CustomerInformation from "./CustomerInformation";

const Event = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const eventDetail = useSelector(state => state.Apis.singleEventDetail);
    const updatedEvent = useSelector(state => state.Apis.updatedEvent);
    const confirmedEvent = useSelector(state => state.Apis.confirmedEvent);
    const declinedEvent = useSelector(state => state.Apis.declinedEvent);
    const deletedEvent = useSelector(state => state.Apis.deletedEvent);
    const userDetail = useSelector(state => state.Apis.userDetail);
    const businessEventTypes = useSelector(state => state.Apis.eventTypes);
    const venueListing = useSelector(state => state.Apis.providerVenuesListing);
    const eventTags = useSelector(state => state.Apis.eventTags);
    const updatedTask = useSelector(state => state.Apis.updatedTask);
    const currentBookings = useSelector(state => state.Apis.currentBooking);

    const [user, setUser] = useState({});
    const [event, setEvent] = useState({});
    const [editEventFormData, setEditEventFormData] = useState({});
    const [editTaskFormData , setEditTaskFormData] = useState({});
    const [editEventModalVisible, setEditEventModalVisible] = useState(false);
    const [editTaskModalVisible, setEditTaskModalVisible] = useState(false);
    const [customerInformationModalVisible, setCustomerInformationModalVisible] = useState(false);
    const [eventTypes, setEventTypes] = useState([]);
    const [venues, setVenues] = useState([]);
    const [allEventTags, setAllEventTags] = useState([]);

    const openEditEventModal = () => {
        setEditEventFormData({
            start_time: event.start_time,
            end_time: event.end_time,
            tags: event.tags ? event.tags.map(tag => ({
                label: tag.name,
                value: tag.id,
            })) : [],
        });
        setEditEventModalVisible(true);
    };

    const handleDeleteEvent = () => {
        if (window.confirm("You are about to delete this event. Are you sure you want to continue?")) {
            const additionalInformation = event.status === bookvenues.STATUS_INTERNAL ?
                null : prompt("Add additional information about the reason of cancellation for customers.");
            dispatch(bizDeleteEvent({
                id: event?.id,
                payment_intent_id: event?.payment_intent_id,
                additionalInformation: additionalInformation
            }));
        }
    }

    const handleConfirmEvent = () => {
        if (window.confirm("Are you sure you want to accept this booking request? If you proceed, the customer will receive an email with the confirmation details.")) {
            const additionalInformation = prompt("Feel free to add additional information to your customer's confirmation email.");
            dispatch(confirmEvent({
                id: event?.id,
                payment_intent_id: event?.payment_intent_id,
                additionalInformation: additionalInformation,
            }));
        }
    }

    const handleDeclineEvent = () => {
        if (window.confirm("Are you sure you want to decline this booking request? Once declined, this reservation will be canceled and the customer will be notified.")) {
            const additionalInformation = prompt("Add additional information about the decline to encourage customers to rebook.");
            dispatch(declineEvent({
                id: event?.id,
                payment_intent_id: event?.payment_intent_id,
                additionalInformation: additionalInformation
            }));
        }
    }

    const handleSendConfirmationEmailButtonClick = () => {
        if (window.confirm("You are about to send a new confirmation to the customer's email inbox. Are you sure you want to continue?")) {
            const additionalInformation = prompt("Feel free to add additional information to your customer's confirmation email.");
            dispatch(sendConfirmationEmail({
                eventId: event?.id,
                additionalInformation: additionalInformation,
            }));
        }
    }

    const handleAddTaskButtonClick = () => {
        setEditTaskModalVisible(true);
        setEditTaskFormData({
            id: null,
            name: "",
            event_id: event?.id,
            due_time: null,
        });
    }

    const handleDeleteTask = (id) => {
        if (window.confirm("Are you sure you want to delete this task?")) {
            dispatch(deleteTask({id}));
        }
    }

    const onTaskStatusChange = (taskId, newStatus) => {
        dispatch(updateTask({
            id: taskId,
            status: newStatus,
        }));
    }

    const hasAccess = (accessName) => {
        return user.role === users.ROLE_VENUE_OWNER || (user.manager?.[accessName]);
    }

    useEffect(() => {
        setUser(userDetail);
    }, [userDetail]);

    useEffect(() => {
        dispatch(getProviderVenueListing());
    }, []);

    useEffect(() => {
        const eventId = parseInt(document.location.pathname.split('/')[3]);
        dispatch(calenderData({page: 1, venueId: false}));
        dispatch(getEventDetail({id: eventId}));
        dispatch(getEventTags());
        setEditEventModalVisible(false);
        setEditTaskModalVisible(false);
    }, [updatedEvent, confirmedEvent, updatedTask]);

    useEffect(() => {
        if (eventDetail === null) {
            navigate("/business/reservations");
            return;
        }
        setEvent(eventDetail);
        document.title = `${eventDetail.title} | DoubleSpot Business`;
        document.querySelector('meta[name="description"]').setAttribute(
            "content",
            `View event "${eventDetail.title}" with DoubleSpot's business dashboard.`
        );
    }, [eventDetail]);

    useEffect(() => {
        if (userDetail?.role) {
            let businessId;
            if (userDetail?.role === users.ROLE_VENUE_OWNER) {
                businessId = userDetail?.id;
            } else if (userDetail.role === users.ROLE_VENUE_MANAGER) {
                businessId = userDetail?.bussnessId;
            }
            dispatch(getEventTypes({
                businessId
            }));
        }
    }, [userDetail?.id]);

    useEffect(() => {
        setEventTypes([
            ...businessEventTypes,
            {
                id: 0,
                name: "Other",
                color: 0,
            }
        ]);
    }, [businessEventTypes]);

    useEffect(() => {
        setVenues(venueListing);
    }, [venueListing]);

    useEffect(() => {
        setAllEventTags(eventTags);
    }, [eventTags]);

    useEffect(() => {
        // Even reservations without payment will have an paymentIntent, and yes, JavaScript arrays are always truthy.
        if (deletedEvent.paymentIntent || declinedEvent.paymentIntent) navigate("/business");
    }, [deletedEvent, declinedEvent]);

    useEffect(() => {
        if (userDetail?.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.calendarAccess) {
                dispatch(setAlert('You do not have the permissions to events.','error'));
                navigate("/business");
            }
        }
    }, [userDetail]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <h2 className="mb-0">{event?.title}</h2>
                        {
                            event?.status === bookvenues.STATUS_PENDING ?
                                (
                                    hasAccess("acceptBookingAccess") ?
                                        <div className="flex-row">
                                            <CButton color="success" className="white"
                                                     onClick={handleConfirmEvent}>Accept</CButton>
                                            <CButton color="danger" className="white ms-2"
                                                     onClick={handleDeclineEvent}>Decline</CButton>
                                        </div> : null
                                ) :
                                <CDropdown>
                                    <CDropdownToggle color="light">Actions</CDropdownToggle>
                                    <CDropdownMenu>
                                        <CDropdownItem role="button" onClick={handleSendConfirmationEmailButtonClick}>
                                            <CIcon icon={cilEnvelopeClosed} className="primary-color"/> &nbsp; Send Confirmation Email
                                        </CDropdownItem>
                                        {
                                            event?.status === bookvenues.STATUS_INTERNAL || (event?.status === bookvenues.STATUS_CONFIRMED && !(event?.payment_intent_id)) ?
                                                (
                                                    hasAccess("editEventAccess") ?
                                                        <>
                                                            <CDropdownItem role="button" onClick={openEditEventModal}>
                                                                <CIcon icon={cilPencil} className="primary-color"/> &nbsp; Edit
                                                            </CDropdownItem>
                                                            <CDropdownDivider/>
                                                            <CDropdownItem role="button" onClick={handleDeleteEvent}>
                                                                <CIcon icon={cilTrash} className="primary-color"/> &nbsp; Delete
                                                            </CDropdownItem>
                                                        </> : null
                                                ) :
                                            event?.status === bookvenues.STATUS_CONFIRMED ?
                                                (
                                                    hasAccess("editEventAccess") ?
                                                        <CDropdownItem role="button" onClick={openEditEventModal}>
                                                            <CIcon icon={cilPencil} className="primary-color"/> &nbsp; Edit
                                                        </CDropdownItem> : null
                                                ) : null
                                        }
                                    </CDropdownMenu>
                                </CDropdown>
                        }
                    </div>
                    <hr/>
                    <div className="flex-row">
                        <div className="pe-2 d-flex flex-column w-50">
                            <CCard className="shadow-3 mb-3 flex-fill">
                                <CCardBody className="p-4">
                                    <h5>Logistics Information</h5>
                                    <div>
                                        <p>
                                            <b className="event-modal-information-title">Venue</b>
                                            {event?.venue?.name}
                                        </p>
                                        <p>
                                            <b className="event-modal-information-title">Start Time</b>
                                            {formatDate(event?.date, event?.start_time)}
                                        </p>
                                        <p className="mb-0">
                                            <b className="event-modal-information-title">End Time</b>
                                            {formatDate(event?.end_date, event?.end_time)}
                                        </p>
                                    </div>
                                </CCardBody>
                            </CCard>
                            <CCard className="shadow-3 flex-fill">
                                <CCardBody className="p-4">
                                    <h5>Event Information</h5>
                                    <div>
                                        <p>
                                            <b className="event-modal-information-title">Time Added</b>
                                            {
                                                formatDate(...(moment(new Date(event.createdAt)).format("YYYY-MM-DDTHH:mm:ss").split('T')))
                                            }
                                        </p>
                                        <p>
                                            <b className="event-modal-information-title">Status</b>
                                            <div className="pt-2">
                                                {
                                                    event.status === bookvenues.STATUS_PENDING ?
                                                        <CBadge color="danger">Pending</CBadge> :
                                                        event.status === bookvenues.STATUS_CONFIRMED ?
                                                            <CBadge color="success">Confirmed</CBadge> :
                                                            event.status === bookvenues.STATUS_INTERNAL ?
                                                                <CBadge textBgColor="light">Internal</CBadge> : null
                                                }
                                            </div>
                                        </p>
                                        <p>
                                            <b className="event-modal-information-title">Type and Tags</b>
                                            <div className="flex-row flex-wrap pt-2 row-gap-1">
                                                <div>
                                                    <CBadge
                                                        class={`badge calendar-event-color-${event?.eventtype?.color || "gray"}`}>
                                                        {event.eventtype?.name || "Other"}
                                                    </CBadge>
                                                </div>
                                                {
                                                    event?.tags?.map(tag => (
                                                        <div className="ps-2">
                                                            <CBadge className="black" color="light">{tag.name}</CBadge>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </p>
                                        <p><b className="event-modal-information-title">Attendees</b>
                                            {event?.attendees}{event?.attendees === 1 ? " person" : " people"}
                                        </p>
                                        <p className={event?.confirmation_no ? "" : "mb-0"}><b className="event-modal-information-title">{
                                            event?.status === bookvenues.STATUS_INTERNAL || !(event?.payment_intent_id) ? "Revenue" : "Booked Price"
                                        }</b>
                                            ${event?.booked_price}</p>
                                        {
                                            event?.confirmation_no &&
                                            <p className="mb-0"><b className="event-modal-information-title">Confirmation
                                                Number: </b>{event?.confirmation_no}</p>
                                        }
                                    </div>
                                </CCardBody>
                            </CCard>
                        </div>
                        <div className="ps-2 d-flex flex-column w-50">
                            <CCard className="shadow-3 mb-3 flex-fill">
                                <CCardBody className="p-4">
                                    <div className="flex-row justify-content-between">
                                        <h5>Customer Information</h5>
                                        <a
                                            className="hover-pointer"
                                            onClick={() => setCustomerInformationModalVisible(true)}>
                                            <u>Details</u>
                                        </a>
                                    </div>
                                    <CustomerInformation event={event} trim/>
                                </CCardBody>
                            </CCard>
                            <CCard className="shadow-3 flex-fill">
                                <CCardBody className="p-4">
                                    <h5>Description</h5>
                                    <p className="fw-normal display-line-break mb-0">{event?.description ? event?.description : "No description provided."}</p>
                                </CCardBody>
                            </CCard>
                        </div>
                    </div>
                    <div className="flex-row justify-content-between align-items-end mt-4">
                        <div>
                            <h4>Task Management</h4>
                        </div>
                        <div>
                            {
                                hasAccess("editEventAccess") ?
                                    <CButton color="light" onClick={handleAddTaskButtonClick}>
                                        <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Task
                                    </CButton> : null
                            }
                        </div>
                    </div>
                    <DataTable
                        columns={[
                            ...columns(onTaskStatusChange),
                            ...(hasAccess("editEventAccess") ? [{
                                name: '',
                                cell: row => (
                                    <div class="py-2">
                                        <CButton color="dark" variant="outline" onClick={() => {
                                            setEditTaskFormData({
                                                id: row.id,
                                                name: row.name,
                                                due_date: row.due_date,
                                                due_time: row.due_time,
                                            });
                                            setEditTaskModalVisible(true);
                                        }}>
                                            Edit
                                        </CButton>
                                    </div>
                                ),
                                minWidth: "100px",
                                right: true,
                            },
                                {
                                    name: '',
                                    cell: row => (
                                        <div class="py-2">
                                            <CButton color="danger" className="white"
                                                     onClick={() => handleDeleteTask(row.id)}>
                                                Delete
                                            </CButton>
                                        </div>
                                    ),
                                    width: "105px",
                                    right: true,
                                }] : [])
                        ]}
                        data={event?.tasks || []}
                    />
                </CCardBody>
            </CCard>
            <EditEventModal
                visible={editEventModalVisible}
                onClose={() => setEditEventModalVisible(false)}
                editEventFormData={editEventFormData}
                setEditEventFormData={setEditEventFormData}
                dispatch={dispatch}
                eventTypes={eventTypes}
                venues={venues}
                eventTags={allEventTags}
                setEventTags={setAllEventTags}
                event={event}
                bookings={currentBookings}
            />
            <EditTaskModal
                visible={editTaskModalVisible}
                onClose={() => setEditTaskModalVisible(false)}
                editFormData={editTaskFormData}
                setEditFormData={setEditTaskFormData}
                dispatch={dispatch}
            />
            <CustomerInformationModal
                visible={customerInformationModalVisible}
                onClose={() => setCustomerInformationModalVisible(false)}
                event={event}
            />
        </div>
    );
};

export default Event;
