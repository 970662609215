import {CBadge} from "@coreui/react";
import {bookvenues} from "constants";
import {TYPE_SELECTABLE_OPTIONS} from "constants/form_questions";
import React from "react";

const CustomerInformation = ({ event, trim }) => {
    return (
        <>
            <p className="mb-0">Name: {
                event?.customer_first_name || event?.customer_last_name ?
                    `${event?.customer_first_name} ${event?.customer_last_name}` :
                    "N/A"
            }</p>
            <p className="mb-0">Phone: {event?.customer_number || "N/A"}</p>
            <p>Email: {event?.customer_email || "N/A"}</p>
            {event?.status === bookvenues.STATUS_INTERNAL ?
                null :
                event?.customer_message &&
                <>
                    <p className="mb-0 display-line-break"><b
                        className="event-modal-information-title">Message
                        For Owner</b>
                        {event?.customer_message}</p>
                </>
            }
            {
                event?.form_responses?.map((response, index) => {
                    const tempElement = document.createElement("div");
                    tempElement.innerHTML = response.prompt;
                    const plainPrompt = tempElement.textContent || tempElement.innerText || "";

                    return (
                        <p className={index === event?.form_responses.length - 1 ? "mb-0" : ""}>
                            <b className={`event-modal-information-title ${trim ? "trim-1" : ""}`}>{plainPrompt}</b>
                            {
                                response.type === TYPE_SELECTABLE_OPTIONS ?
                                    <div className="flex-row flex-wrap pt-2 row-gap-1">
                                        {
                                            response.response.split('\n').map(option => (
                                                <div className="pe-2">
                                                    <CBadge className="black"
                                                            color="light">{option}</CBadge>
                                                </div>
                                            ))
                                        }
                                    </div> :
                                    <div className={`event-modal-information-content display-line-break ${trim ? "trim-3" : ""}`}>
                                        {response.response || "N/A"}
                                    </div>
                            }
                        </p>
                    );
                })
            }
        </>
    );
}

export default CustomerInformation
