import {
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import React from "react";
import CustomerInformation from "./CustomerInformation";

const CustomerInformationModal = ({ event, visible, onClose }) => {
    return (
        <CModal scrollable size="lg" backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Customer Information</CModalTitle>
            </CModalHeader>
            <CModalBody className="p-4 business-modal-body">
                <CustomerInformation event={event}/>
            </CModalBody>
        </CModal>
    );
};

export default CustomerInformationModal;
