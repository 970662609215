import {CButton} from "@coreui/react";
import React from "react";

export const columns = (onDeleteButtonClick) => [
    {
        name: "Name",
        selector: row => row.name,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="dark" variant="outline" href={`/business/forms/${row.id}`} target="_blank">
                    Edit
                </CButton>
            </div>
        ),
        minWidth: "100px",
        right: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="danger" className="white" onClick={() => onDeleteButtonClick(row.id)}>
                    Delete
                </CButton>
            </div>
        ),
        width: "105px",
        right: true,
    },
];

export const applyFilters = (forms, filters) => {
    return forms.filter(entry => {
        if (filters.search) {
            const searchTerm = filters.search.toLowerCase();
            if (!(entry.name.toLowerCase().includes(searchTerm))) {
                return false;
            }
        }

        return true;
    });
};
