import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    SITE_URL,
    saveRating, 
    userCurrentBookings 
} from "../../redux/action/action";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import ENV from '../../env.json';
import { Modal } from 'reactstrap';
import ReactStars from "react-rating-stars-component";
import {getTimeString} from "../../Utils/DateTimeUtils";
import {bookvenues} from "constants";

function Bookings() {
    document.title = "Bookings | DoubleSpot";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Access your current and past event venue bookings on DoubleSpot effortlessly."
    );

    const navigate = useNavigate()
    const [data, setData] = useState([])
    const [page, setPage] = useState(1)
    const [showTab, setShowTab] = useState(false)
    const [socket, setSocket] = useState(null);
    const [venueId, setVenueId] = useState(null)
    const [ratingData, setRatingData] = useState({})
    const [businessId , setBussinessId] = useState(null)
    // For rating modal
    const [rating, setRating] = useState(false);
    const toggleRating = () => setRating(!rating);
    const changeTab = () => setShowTab(!showTab)

    const myBookings = useSelector(state => state.Apis.myBookings)
    const bookingDetail = useSelector(state => state.Apis.bookingDetail)
    const confirmedEvent = useSelector(state => state.Apis.confirmedEvent)
    const dispatch = useDispatch();

    useEffect(() => {
        setData(myBookings)
    }, [myBookings])

    const SOCKET_SERVER_URL = ENV.SERVER_URL;
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const newSocket = io(SOCKET_SERVER_URL);
            setSocket(newSocket);
        }
    }, []);

    useEffect(() => {
        const searchData = {
            page: page,
            past: showTab
        }
        dispatch(userCurrentBookings(searchData))
    }, [showTab, bookingDetail, confirmedEvent])

    const ratingChanged = (newRating) => {
        setRatingData({ ...ratingData, ["rating"]: newRating })
    };

    const handleRatingValue = (e) => setRatingData({ ...ratingData, [e.target.name]: e.target.value })

    function handleSubmit(e) {
        e.preventDefault();
        ratingData.venueId = venueId
        
        const dataa = {
            experience :ratingData?.experience,
            rating :ratingData?.rating,
            venueId :ratingData?.venueId,
            business_id :businessId
        }
        
        dispatch(saveRating(dataa))
        const searchData = {
            page: page,
            past: showTab
        }
        dispatch(userCurrentBookings(searchData))
    }

    return (<>
        <section className="booking py-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10">

                        <ul className="nav nav-pills mb-3 justify-content-center gap-4" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className={showTab ? "nav-link" : "nav-link   active"} onClick={() => changeTab()} id="pills-home-tab" type="button" role="tab" >Current Bookings</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className={showTab ? "nav-link active" : "nav-link "} onClick={() => changeTab()} id="pills-profile-tab" type="button" role="tab" >Past Bookings</button>
                            </li>
                        </ul>
                        <div className="tab-content bookings_tabs" id="pills-tabContent">
                            <div className={showTab ? "tab-pane fade show active" : "tab-pane fade "} id="pills-home" role="tabpanel"
                                aria-labelledby="pills-home-tab" tabindex="0">
                                {data.length > 0 ?
                                    data.map((val, key) => {
                                        return <>
                                            <h2 key={key}>{new Date(new Date(val?.date).toLocaleString("en-US", {timeZone: "Europe/London"})).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</h2>
                                            <div className="card rounded-4 shadow-lg border-0 mb-5">
                                                <div className="card_body  p-4 ">
                                                    <div className="row g-4">
                                                        <div className="col-md-6">
                                                            <h4><a href={'https://doublespot.com/venue/' + val.venueId}>{val.venue.name}</a></h4>
                                                            <p>
                                                                <b> Time: </b>
                                                                {getTimeString(new Date(`${val.date} ${val.start_time}`), true)}
                                                                {" - "}
                                                                {getTimeString(new Date(`${val.date} ${val.end_time}`), true)}
                                                            </p>
                                                            <p className="fw-semibold">Address:</p>
                                                            <p className="d-flex align-items-center"><i class="fa-solid fa-location-dot me-2"></i>{val.venue?.location}</p>
                                                            <p><b>Contact Email:</b>&nbsp;{val.venue?.business?.email}</p>
                                                            <p><b>Contact Number:</b>&nbsp;{val.venue?.phone_no}</p>
                                                        </div>
                                                            <div className="col-md-6" id="map">
                                                            <p>Confirmation Number: &nbsp;{val.confirmation_no ? val.confirmation_no : "N/A"}</p>
                                                            <img src={SITE_URL + 'images/' + val.venue.images.split(",")[0]} className="big_img" width="100%" alt={SITE_URL + "images/noImageFound.png"} />
                                                        </div>
                                                        <div className="col-12">
                                                            {val.venue?.reviews?.length > 0 ? 
                                                                <div className="d-flex justify-content-between">
                                                                    <div>
                                                                        <h4>Thank you for leaving a review</h4>
                                                                        <p>Your review is important for {val.venue?.name} to continue to improve and provide the best service possible.</p>
                                                                    </div>
                                                                </div> :
                                                                <div className="d-flex justify-content-between">
                                                                    <div>
                                                                        <h4>How was your event?</h4>
                                                                        <p>Please tell us about your experience at {val.venue?.name}.</p>
                                                                    </div>
                                                                    <button className="next_btn rounded-3 fs-md-5 fw-normal py-md-2 host_btn"
                                                                        onClick={(e) => {
                                                                            setVenueId(val.venueId)
                                                                            setBussinessId(val.bussness_id)
                                                                            toggleRating()
                                                                        }} 
                                                                    >
                                                                        Rate and Review 
                                                                    </button>
                                                                </div>
                                                            }
                                                            
                                                        </div>
                                                        <div className="col-12 d-none">
                                                            <a href="#">
                                                                Modify reservation
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    })
                                    :
                                    <>
                                        <div style={{paddingTop: "1rem"}}>
                                            <h4 className="noBookingFound">No past bookings found.</h4>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className={showTab ? "tab-pane fade " : "tab-pane fade show active"} id="pills-profile" role="tabpanel"
                                aria-labelledby="pills-profile-tab" tabindex="0">

                                {data.length > 0 ?
                                    data.map((val, key) => {
                                        return <>
                                            <h2 key={key}>{new Date(new Date(val?.date).toLocaleString("en-US", {timeZone: "Europe/London"})).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</h2>
                                            <div className="card rounded-4 shadow-lg border-0 mb-5">
                                                <div className="card_body  p-4 ">
                                                    <div className="row g-4">
                                                    <div className="col-md-6">
                                                            <h4><a href={'https://doublespot.com/venue/' + val.venueId}>{val.venue?.name}</a></h4>
                                                            <p>
                                                                <b> Time: </b>
                                                                {getTimeString(new Date(`${val.date} ${val.start_time}`), true)}
                                                                {" - "}
                                                                {getTimeString(new Date(`${val.date} ${val.end_time}`), true)}
                                                            </p>
                                                            <p className="fw-semibold">Address:</p>
                                                            <p className="d-flex align-items-center"><i class="fa-solid fa-location-dot me-2"></i>{val.venue?.location}</p>
                                                            <p><b>Contact Email:</b>&nbsp;{val.venue?.business?.email}</p>
                                                            <p><b>Contact Number:</b>&nbsp;{val.venue?.phone_no}</p>
                                                            <p>
                                                                <b>Status:</b>&nbsp;
                                                                {val.status === bookvenues.STATUS_CONFIRMED && <><b className="g_color">Confirmed</b></>}
                                                                {val.status === bookvenues.STATUS_PENDING && <><b className="r_color">Awaiting approval</b></>}
                                                            </p>
                                                        </div>
                                                        <div className="col-md-6" id="map">
                                                            {val.confirmation_no ?
                                                                <p>Confirmation Number: &nbsp;{val.confirmation_no}</p> :
                                                                null
                                                            }
                                                            <img src={SITE_URL + 'images/' + val.venue?.images?.split(",")[0]} className="big_img" width="100%" alt={SITE_URL + "images/noImageFound.png"} />
                                                        </div>
                                                        <div className="col-12">
                                                        </div>
                                                        <div className="col-12">
                                                            {val.status === bookvenues.STATUS_CONFIRMED ? 
                                                                <a>Please contact the host to modify your reservation.</a> :
                                                                <a>Please contact the host to modify your request.</a>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                    })

                                    :
                                    <>
                                        <div style={{paddingTop: "1rem"}}>
                                            <h4 className="noBookingFound">No bookings found. If you book a venue it will show up here!</h4>
                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <seciton className="join_us">
            <div className="container">
                <p className="my-4 text-center">Favourite restaurant not yet on Fento? <a href="#"> Suggest a restaurant. </a>
                </p>

            </div>
        </seciton>

        {/* modal for rating  */}
        <Modal className="singInModal w-100 modal-sm" isOpen={rating} toggle={() => toggleRating()}>
            <div className="modal-header border-0">
                <button type="button" className="btn-close" onClick={toggleRating} aria-label="Close"/>
            </div>
            <div className="modal-body px-lg-3 px-2 ">
                <div className="row">
                    <form onSubmit={handleSubmit}>
                        <label>Rating</label>
                        <ReactStars
                            count={5}
                            onChange={ratingChanged}
                            size={50}
                            isHalf={true}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                            activeColor="#ffd700"
                            name="rating"
                        />

                        <div className="form-group">
                            <label>Experience</label>
                            <textarea
                                placeholder="describe your experience"
                                name="experience"
                                className="form-control"
                                rows={"4"}
                                onChange={(e) => handleRatingValue(e)}
                            />
                        </div>

                        <button onClick={() => toggleRating()} className="saveRating next_btn rounded-2 mt-3" type="submit">Submit</button>
                    </form>
                </div>
            </div>
        </Modal>
    </>)
}


export default Bookings