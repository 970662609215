import {
    CButton,
    CForm,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CFormTextarea,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle
} from "@coreui/react";
import TimePicker from "../../../components/TimePicker";
import {setAlert, updateEventDetail} from "../../../../redux/action/action";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import {bookvenues} from "constants";
import {hasConflict} from "../../../../Utils/DateTimeUtils";

const EditEventModal = ({ visible, onClose, editEventFormData, setEditEventFormData, dispatch, bookings, eventTypes, eventTags, setEventTags, venues, event }) => {
    const modified = (column) => {
        return editEventFormData[column] && editEventFormData[column] !== event[column];
    }

    const handleUpdateEventDetail = (e) => {
        const sendEmail = (event?.status === bookvenues.STATUS_CONFIRMED && (modified("venueId") ||
            modified("date") || modified("end_date") || modified("start_time") || modified("end_time")))
        e.preventDefault();
        const updatedEventTime = {
            id: event?.id,
            venueId: editEventFormData?.venueId || event?.venueId,
            date: editEventFormData?.date || event?.date,
            end_date: editEventFormData?.end_date || event?.end_date,
            start_time: editEventFormData?.start_time || event?.start_time,
            end_time: editEventFormData?.end_time || event?.end_time,
        };
        if (hasConflict(updatedEventTime, bookings)) {
            if (prompt("This event will have conflicts with existing ones. If you are sure to continue, please type \"DOUBLEBOOK\" to confirm.") !== "DOUBLEBOOK") {
                dispatch(setAlert("Please acknowledge the time conflict before updating the event.", "error"));
                return;
            }
        }
        if (!sendEmail || window.confirm("Are you sure you want to update this event? A notification email will be sent to your customer regarding the update.")) {
            dispatch(updateEventDetail({
                ...editEventFormData,
                ids: [event?.id],
                sendEmail: sendEmail,
                tags: editEventFormData.tags ? editEventFormData.tags.map(tag => ({
                    id: tag.value,
                    name: tag.label,
                })) : [],
            }));
        }
    }

    const updateEditEventForm = (event) => {
        setEditEventFormData({...editEventFormData, [event.target.name]: event.target.value});
    }

    const updateEditEventFormMultiselect = (selectedOptions, actionMeta) => {
        setEditEventFormData({...editEventFormData, [actionMeta.name]: selectedOptions});
    }

    const updateEditEventFormTimePicker = (selectedOption, actionMeta) => {
        setEditEventFormData({...editEventFormData, [actionMeta.name]: selectedOption.value});
    }

    const onCreateOption = (name) => {
        setEventTags([...eventTags, {
            id: name,
            name,
        }]);

        const newTag = {
            label: name,
            value: name,
        }
        setEditEventFormData({
            ...editEventFormData,
            tags: editEventFormData.tags ? [...editEventFormData.tags, newTag] : [newTag]
        });
    }

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Edit Event</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <CForm onSubmit={handleUpdateEventDetail}>
                    <CFormLabel htmlFor="title">Title</CFormLabel>
                    <div className="pb-3">
                        <CFormInput required type="text" name="title" onChange={updateEditEventForm}
                                    defaultValue={event.title}/>
                    </div>
                    <div className="pb-3">
                        <CFormLabel htmlFor="eventtype_id">Event Type</CFormLabel>
                        <CFormSelect name="eventtype_id" onChange={updateEditEventForm}
                                     defaultValue={event.eventtype_id ? event.eventtype_id : 0}>
                            {
                                eventTypes.map(eventType => <option value={eventType.id}>{eventType.name}</option>)
                            }
                        </CFormSelect>
                    </div>
                    <div class="pb-3">
                        <CFormLabel htmlFor="tags">Tags</CFormLabel>
                        <MultiSelectCheckbox
                            name="tags"
                            onChange={updateEditEventFormMultiselect}
                            options={eventTags.map(
                                tag => ({
                                    label: tag.name,
                                    value: tag.id,
                                })
                            )}
                            value={editEventFormData?.tags}
                            creatable
                            onCreateOption={onCreateOption}
                        />
                    </div>
                    {
                        (event?.status === bookvenues.STATUS_INTERNAL || (event?.status === bookvenues.STATUS_CONFIRMED && !(event?.payment_intent_id)))?
                            <>
                                <div className="pb-3">
                                    <CFormLabel htmlFor="venueId">Venue</CFormLabel>
                                    <CFormSelect name="venueId" onChange={updateEditEventForm}
                                                 defaultValue={event.venueId}>
                                        {
                                            venues.map(listing => <option
                                                value={listing.id}>{listing.name}</option>)
                                        }
                                    </CFormSelect>
                                </div>
                                <div class="flex-row pb-3">
                                    <div class="col-6 pe-2">
                                        <CFormLabel htmlFor="date">Start Date</CFormLabel>
                                        <CFormInput required type="date" name="date"
                                                    onChange={updateEditEventForm}
                                                    defaultValue={event.date}/>
                                    </div>
                                    <div class="col-6 ps-2">
                                        <CFormLabel htmlFor="start_time">Start Time</CFormLabel>
                                        <TimePicker name="start_time" onChange={updateEditEventFormTimePicker}
                                                    value={editEventFormData.start_time}/>
                                    </div>
                                </div>
                                <div class="flex-row pb-3">
                                    <div class="col-6 pe-2">
                                        <CFormLabel htmlFor="end_date">End Date</CFormLabel>
                                        <CFormInput required type="date" name="end_date"
                                                    onChange={updateEditEventForm}
                                                    defaultValue={event.end_date}/>
                                    </div>
                                    <div class="col-6 ps-2">
                                        <CFormLabel htmlFor="end_time">End Time</CFormLabel>
                                        <TimePicker name="end_time" onChange={updateEditEventFormTimePicker}
                                                    value={editEventFormData.end_time}/>
                                    </div>
                                </div>
                                <CFormLabel htmlFor="attendees">Attendees</CFormLabel>
                                <div className="flex-row align-items-center pb-3">
                                    <div className="pe-2">
                                        <CFormInput type="number" required min="0" name="attendees"
                                                    onChange={updateEditEventForm}
                                                    defaultValue={event.attendees} style={{width: "100px"}}/>
                                    </div>
                                    <span>people</span>
                                </div>
                                <div className="pb-3">
                                    <CFormLabel htmlFor="finalPrice">Revenue</CFormLabel>
                                    <div className="flex-row align-items-center">
                                        <span>$</span>
                                        <div className="px-2">
                                            <CFormInput type="number" required min="0" name="booked_price"
                                                        onChange={updateEditEventForm}
                                                        defaultValue={event.booked_price}
                                                        style={{width: "100px"}}/>
                                        </div>
                                    </div>
                                </div>
                                <CFormLabel>Customer Name</CFormLabel>
                                <div class="flex-row pb-3">
                                    <div class="col-6 pe-2">
                                        <CFormLabel htmlFor="customer_first_name">First Name</CFormLabel>
                                        <CFormInput type="text" name="customer_first_name"
                                                    onChange={updateEditEventForm}
                                                    defaultValue={event.customer_first_name}/>
                                    </div>
                                    <div class="col-6 ps-2">
                                        <CFormLabel htmlFor="customer_last_name">Last Name</CFormLabel>
                                        <CFormInput type="text" name="customer_last_name"
                                                    onChange={updateEditEventForm}
                                                    defaultValue={event.customer_last_name}/>
                                    </div>
                                </div>
                                <CFormLabel htmlFor="customer_number">Customer Phone</CFormLabel>
                                <div className="pb-3">
                                    <CFormInput type="text" name="customer_number" onChange={updateEditEventForm}
                                                defaultValue={event.customer_number}/>
                                </div>
                                <CFormLabel htmlFor="customer_email">Customer Email</CFormLabel>
                                <div className="pb-3">
                                    <CFormInput type="text" name="customer_email" onChange={updateEditEventForm}
                                                defaultValue={event.customer_email}/>
                                </div>
                            </> :
                            null
                    }
                    <div>
                        <CFormLabel htmlFor="description">Description</CFormLabel>
                        <div className="pb-4">
                            <CFormTextarea name="description" onChange={updateEditEventForm}
                                           defaultValue={event.description}/>
                        </div>
                    </div>
                    <CButton color="primary" type="submit">Submit</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    );
};

export default EditEventModal;
